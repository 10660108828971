import { Helmet } from 'react-helmet-async'
import { TableContainer, Table, TableBody, Typography, Stack } from '@mui/material'
import { useLocales } from '../../../locales'
import useNodes from '../../../services/Nodes/useNodes'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import TableLoader from '../../../components/TableLoader/TableLoader'
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table'
import TABLE_HEADER from './NodesTableHeader'
import NodeInfoModel from '../../../services/Nodes/model/NodeInfoModel'
import style from './NodesStyle'
import NodesTableRow from '../../../sections/dashboard/nodes/table/NodesTableRow'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import { useNavigate } from 'react-router'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import GridContainer from '../../../components/grid/GridContainer/GridContainer'
import TableCard from '../../../components/card/TableCard/TableCard'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'

function mapNodes(nodes: NodeInfoModel[] | undefined) {
  if (nodes === undefined) {
    return []
  }

  const result = []

  for (const node of nodes) {
    const row = {
      foreignID: node.foreignID,
      organization: node.organization?.name,
      location: node.location?.name,
      zone: node.zone?.name,
      status: node.status,
      uuid: node.uuid,
    }

    result.push(row)
  }

  return result
}

export default function Nodes() {
  const navigate = useNavigate()
  const { translate } = useLocales()

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable({
    defaultOrderBy: 'organization',
    defaultOrder: 'asc',
    defaultRowsPerPage: 25
  })

  const { data, loading } = useNodes(order, orderBy)
  const nodes = mapNodes(data)

  const navigateToEditNodeForm = (nodeUUID: string): void => {
    navigate(PATH_ACCOUNTS.editNode(nodeUUID))
  }

  const nodesTableRow = (row: any): JSX.Element => {
    return (
      <NodesTableRow
        key={row.uuid}
        row={row}
        selected={selected.includes(row.uuid)}
        onClickEdit={() => navigateToEditNodeForm(row.uuid)}
      />
    )
  }

  const nodesTable = (): JSX.Element => {
    return (
      <Table sx={style.table}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEADER}
          rowCount={nodes.length}
          numSelected={selected.length}
          onSort={onSort}
        />
        <TableBody>
          {loading ? (
            <TableLoader />
          ) : (
            <>
              {nodes.map(row => nodesTableRow(row))}
              <TableNoData
                isNotFound={nodes.length === 0}
                title={`${translate('nodes:no:data')}`}
              />
            </>
          )}
        </TableBody>
      </Table>
    )
  }

  const getTableContent = (): JSX.Element => {
    if (loading && nodes.length === 0) {
      return <></>
    }

    if (nodes.length === 0) {
      return (
        <NoItemsCard
          title={`${translate('nodes:empty-content:title')}`}
          description={`${translate('nodes:empty-content:description')}`}
        />
      )
    }

    return (
      <TableCard>
        <TableContainer>
          <Scrollbar>{nodesTable()}</Scrollbar>
        </TableContainer>
        {loading ? null : (
          <TablePaginationCustom
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            count={nodes.length}
          />
        )}
      </TableCard>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('nodes:title')}`} | So69</title>
      </Helmet>
      <ViewContainer>
        <GridContainer>
          <Stack sx={style.titleContainer}>
            <Typography variant="h4">{`${translate('nodes:title')}`}</Typography>
          </Stack>
          {getTableContent()}
        </GridContainer>
        <SnackbarAlert
          isOpen={loading}
          message={`${translate('loading')}`}
          type={SnackBarAlertTypes.INFO}
        />
      </ViewContainer>
    </>
  )
}
