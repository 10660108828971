import { useEffect } from 'react'
import { useParams } from 'react-router'

import useCampaignForm from '../../hooks/useCampaignForm'
import CampaignForm from '../../sections/dashboard/campaigns/CampaignForm'
import useCampaign from '../../services/campaigns/useCampaign'
import useCampaignMutation from '../../services/campaigns/useCampaignMutation'
import CreateCampaignTransfomer from '../../services/campaigns/adapter/CampaignsAdapter'
import SnackbarAlert from '../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { getErrorMessage } from '../../utils/manageCampaignError'

export default function EditCampaign() {
  const { organizationId, groupId, campaignId } = useParams()

  const mutation = useCampaignMutation()
  const campaign = useCampaign(organizationId!, campaignId!) as any

  const { changeFields, ...form } = useCampaignForm({
    successCallback: (payload: any) =>
      mutation.update(organizationId!, groupId!, campaignId!, payload),
  })

  useEffect(() => {
    if (campaign.data !== undefined) {
      changeFields(CreateCampaignTransfomer.transformToEdit(campaign.data.data))
    }
  }, [campaign.data, changeFields])

  return (
    <>
      <CampaignForm
        editing
        completed={campaign.data?.status === 'completed'}
        form={form}
        organizationId={organizationId}
        groupId={groupId}
      />

      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={getErrorMessage(mutation.error)}
        onClose={mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </>
  )
}
