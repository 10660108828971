import i18n from "../../locales/i18n"
import { calculatePercentage, roundKpisProperties } from "../../utils/mathHelper"
import AttractionRateModel from "../common/model/AttractionRateModel"
import DetectionsModel from "../common/model/DetectionsModel"
import DwellTimeModel from "../common/model/DwellTimeModel"
import TrafficZonesModel from "../common/model/TrafficZonesModel"
import RegisteredAttractionRateApiInterface from "./interfaces/RegisteredAttractionRateApiInterface"
import RegisteredAverageVisitsApiInterface from "./interfaces/RegisteredAverageVisitsApiInterface"
import { RegisteredDetectionsApiInterface } from "./interfaces/RegisteredDetectionsApiInterface"
import { RegisteredDwellTimeApiInterface } from "./interfaces/RegisteredDwellTimeApiInterface"
import RegisteredFrequencyApiInterface from "./interfaces/RegisteredFrequencyApiInterface"
import RegisteredRegistrationsApiInterface from "./interfaces/RegisteredRegistrationsApiInterface"
import { RegisteredTrafficZonesApiInterface } from "./interfaces/RegisteredTrafficZonesApiInterface"
import AverageVisitsModel from "./model/AverageVisitModel"
import RegisteredDetectionsWeekApiModel from "./model/RegisteredDetectionsWeekApiModel"
import RegisteredFrequencyModel from "./model/RegisteredFrequencyModel"
import RegisteredKPIsModel from "./model/RegisteredKPIsModel"
import RegistrationsModel from "./model/RegistrationsModel"

export function adaptRegisteredKPIsResponse(response: any): RegisteredKPIsModel {
  return new RegisteredKPIsModel(
    roundKpisProperties(response?.unique_detection_sum) || {},
    roundKpisProperties(response?.detection_sum) || {},
    roundKpisProperties(response?.detection_average) || {},
    roundKpisProperties(response?.average_time_stay) || {},
    roundKpisProperties(response?.new_vs_loyal) || {},
    roundKpisProperties(response?.average_time_comeback) || {},
  )
}

export function adaptRegisteredFrequencyResponse(
  response: RegisteredFrequencyApiInterface,
): RegisteredFrequencyModel {
  return new RegisteredFrequencyModel(
    response.one,
    response.two,
    response.three_plus,
  )
}

export function adaptRegisteredTrafficZonesResponse(
  response: RegisteredTrafficZonesApiInterface[],
): TrafficZonesModel[] {
  return response.map((trafficZone: RegisteredTrafficZonesApiInterface) => {
    return new TrafficZonesModel(
      trafficZone.zoneIndex,
      trafficZone.zoneName,
      trafficZone.currentZoneVisits,
      trafficZone.previousZoneVisits,
    )
  })
}

export function adaptRegisteredAttractionRateResponse(
  response: RegisteredAttractionRateApiInterface,
): AttractionRateModel {
  return new AttractionRateModel(
    {
      external: calculatePercentage(response.current.external, 2),
      internal: calculatePercentage(response.current.internal, 2),
    },
    {
      external: calculatePercentage(response.previous.external, 2),
      internal: calculatePercentage(response.previous.internal, 2),
    },
    calculatePercentage(response.variation, 2),
  )
}

export function adaptRegisteredDwellTimeResponse(
  response: RegisteredDwellTimeApiInterface,
): DwellTimeModel {
  return new DwellTimeModel(
    calculatePercentage(response.dwellTimeRate ?? 0, 2),
    calculatePercentage(response.dwellTimeDifferenceRate ?? 0, 2)
  )
}

export function adaptRegisteredDetectionsResponse(
  response: RegisteredDetectionsApiInterface[],
): DetectionsModel[] {
  return response.map((detection: RegisteredDetectionsApiInterface) => {
    return new DetectionsModel(
      detection.hour,
      detection.data.map((data: RegisteredDetectionsWeekApiModel) => {
        return {
          x: i18n.t(`date:calendar:${data.weekday.toLowerCase()}`),
          y: data.value,
        }
      }),
    )
  })
}

export function adaptRegisteredAverageVisits(response: RegisteredAverageVisitsApiInterface): AverageVisitsModel {
  return new AverageVisitsModel(
    Math.round(response.currentPeriod),
    Math.round(response.previousPeriod),
    calculatePercentage(response.variation, 2)
  )
}

export function adaptRegistrations(response: RegisteredRegistrationsApiInterface): RegistrationsModel {
  return new RegistrationsModel(
    {
      value: response.totalRegistrations.value,
      rate: calculatePercentage(response.totalRegistrations.rate, 2),
    },
    {
      value: response.registrations.value,
      rate: calculatePercentage(response.registrations.rate, 2),
    },
  )
}
