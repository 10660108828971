export class ClientDoesNotExist extends Error {
  constructor() {
    super('Client does not exist')
  }
}

export class ForbiddenOrganization extends Error {
  constructor() {
    super('Forbidden Access')
  }
}

export class Forbidden extends Error {
  constructor() {
    super('Forbidden')
  }
}

export class PermissionsError extends Error {
  constructor(error: Error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'PermissionsError'
  }
}

export const HTTPFailureStatusCodes = {
  forbidden: 403,
  notFound: 404,
  badRequest: 400,
  conflict: 409,
}
