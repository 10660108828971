import { useTheme } from '@emotion/react'
import { FormControl } from '@mui/material'
import { Stack } from '@mui/system'
import { useState } from 'react'
import SuccessInfo from '../../../components/successInfo/SuccessInfo'
import useQueryParams from '../../../hooks/useQueryParams'
import { useLocales } from '../../../locales'
import {
  CustomerAlreadyUnsubscribedError,
  CustomerNotFoundInOrganization,
  InvalidUuidError,
} from '../../../services/campaigns/Error'
import PublicCampaignInfoModel from '../../../services/campaigns/model/publicCampaignInfoModel'
import useCampaignMutation from '../../../services/campaigns/useCampaignMutation'
import usePublicCampaign from '../../../services/campaigns/usePublicCampaign'
import UseStateType from '../../../services/common/types/UseStateType'
import { CustomerNotFound } from '../../../services/organizations/Error'
import UnsubscribeForm from '../UnsubscribeForm/UnsubscribeForm'
import useStyle from '../UnsubscribeForm/UnsubscribeFormStyle'

export default function Unsubscribe(): JSX.Element {
  const queryParams = useQueryParams()
  const campaignId = queryParams.get('campaignId')
  const organizationId = queryParams.get('organizationId')
  const customerId = queryParams.get('customerId')

  const { data, loading } = usePublicCampaign(
    organizationId!,
    campaignId!,
  ) as UseStateType<PublicCampaignInfoModel>

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const theme = useTheme() as any
  const { translate } = useLocales()
  const mutation = useCampaignMutation()

  const style = useStyle(theme)

  const submit = async () => {
    try {
      await mutation.unsubscribe(organizationId!, customerId!)
      setIsSuccess(true)
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        setError(`${translate('unsubscribe:campaign:error:invalid:uuid')}`)
        return
      }

      if (error instanceof CustomerNotFoundInOrganization) {
        setError(
          `${translate(
            'unsubscribe:campaign:error:invalid:customer:organization',
          )}`,
        )
        return
      }

      if (error instanceof CustomerNotFound) {
        setError(`${translate('unsubscribe:campaign:error:invalid:customer')}`)
        return
      }

      if (error instanceof CustomerAlreadyUnsubscribedError) {
        setError(
          `${translate('unsubscribe:campaign:error:already:unsubscribed')}`,
        )
        return
      }

      setError(`${translate('unsubscribe:campaign:error:generic')}`)
    }
  }

  const clearError = () => {
    setError('')
  }

  const getContent = (): JSX.Element => {
    if (!isSuccess) {
      return (
        <UnsubscribeForm
          isButtonDisabled={!Boolean(campaignId) || !Boolean(customerId)}
          unsubscribeName={data?.organizationName}
          logo={data?.campaignImageUrl}
          clearError={clearError}
          error={error}
          loading={loading}
          submit={submit}
          isSuccess={isSuccess}
        />
      )
    }

    return (
      <SuccessInfo
      title={`${translate('unsubscribe:success:title')}`}
      subtitle={`${translate('unsubscribe:success:text')}`}
    />
    )
  }

  return (
    <Stack sx={style.container}>
      <FormControl sx={style.form}>{getContent()}</FormControl>
    </Stack>
  )
}
