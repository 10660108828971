import { useLocales } from "../../locales"
import SnackbarAlert from "../alert/SnackbarAlert/SnackbarAlert"
import SnackBarAlertTypes from "../alert/SnackbarAlert/SnackBarAlertTypes"
import ModalConfirmAction from "../modal/ModalConfirmAction"
import removeStatusType from "./ViewInfoActionsType"

export default function ViewInfoActions({
  confirmTitle,
  confirmText = '',
  removeStatus,
  snackbarMessage,
  autoHideDuration,
  requestMessage = null,
  handleConfirm,
  closeSnackbarMessage,
}: {
  confirmTitle: string,
  confirmText?: string,
  removeStatus: removeStatusType,
  snackbarMessage: string | null,
  autoHideDuration?: number,
  requestMessage?: string | null,
  handleConfirm: () => void,
  closeSnackbarMessage: () => void,
}) {
  const { translate } = useLocales()

  return (
    <>
      <ModalConfirmAction
        title={confirmTitle}
        text={confirmText}
        actionText={translate('delete')}
        opened={removeStatus.showConfirmation}
        onConfirm={handleConfirm}
        onCancel={removeStatus.cancel}
      />
      <SnackbarAlert
        isOpen={removeStatus.show}
        message={removeStatus.message}
        onClose={removeStatus.closeFeedback}
        type={removeStatus.type as SnackBarAlertTypes}
        autoHideDuration={autoHideDuration}
      />
      <SnackbarAlert
        isOpen={snackbarMessage !== null}
        message={snackbarMessage as string}
        onClose={closeSnackbarMessage}
        type={SnackBarAlertTypes.SUCCESS}
        autoHideDuration={autoHideDuration}
      />
      <SnackbarAlert
        isOpen={requestMessage !== null}
        message={requestMessage as string}
        onClose={closeSnackbarMessage}
        type={SnackBarAlertTypes.INFO}
      />
    </>
  )
}
