import campaignsStatusEnum from "../services/campaigns/enum/campaignsStatusEnum"

export const applyCampaignsTableFilter = ({
  inputData,
  comparator,
  filterStatus,
}: {
  inputData: any[]
  comparator: any
  filterStatus: string
}): any[] => {
  const indexedData = inputData?.map(
    (el, index) => [el, index] as [any, number],
  )

  indexedData?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })
  inputData = indexedData?.map(el => el[0])

  if (filterStatus !== campaignsStatusEnum.ALL) {
    inputData =
      filterStatus === campaignsStatusEnum.PAUSED
        ? inputData.filter(row => row.paused)
        : inputData.filter(row => row.status === filterStatus && !row.paused)
  }

  return inputData
}
