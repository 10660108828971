export class RegisteredGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredGenericError'
  }
}

export class RegisteredKpisGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredKpisGenericError'
  }
}

export class RegisteredFrequencyGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredFrequencyGenericError'
  }
}

export class RegisteredTrafficZonesGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredTrafficZonesGenericError'
  }
}

export class RegisteredAttractionRateGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredAttractionRateGenericError'
  }
}

// From here
export class RegisteredDwellTimeGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredDwellTimeGenericError'
  }
}

export class RegisteredDetectionsGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredDetectionsGenericError'
  }
}

export class RegisteredAverageVisitsGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredAverageVisitsGenericError'
  }
}

export class RegisteredAverageFrequencyVisitsGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredAverageFrequencyVisitsGenericError'
  }
}

export class RegisteredRegistrationsGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'RegisteredRegistrationsGenericError'
  }
}
