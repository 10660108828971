const campaignsStyle = {
  titleContainer: {
    mb: 5
  },
  tabContainer: {
    px: 2,
  },
  tabElement: {
    mr: 1
  },
  tableContainer: {
    minWidth: 800
  }
}

export default campaignsStyle
