import { useCallback, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router'

import { useLocales } from '../locales'
import CampaignsPermissionsValues from '../services/common/enum/CampaignsPermissionsValues'
import genders from '../services/common/data/genders'
import { base64Conversor } from '../utils/imageHelper'

const MAX_NAME_LENGTH = 50
const MAX_DESCRIPTION_LENGTH = 50

const ERRORS = {
  MISSING_FIELD: 'new-campaign:form-error-missing-field',
  MISSING_IMAGE: 'new-campaign:form-error-missing-image',
  IMAGE_FAILED: 'new-campaign:form-error-image-failed',
  INVALID_PAST_DATE: 'new-campaign:form-error-invalid-past-date',
  INVALID_DATE_RANGE: 'new-campaign:form-error-invalid-date-range',
  NAME_TOO_LONG: 'new-campaign:form-error-name-too-long',
  DESCRIPTION_TOO_LONG: 'new-campaign:form-error-description-too-long',
}

const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'change':
      return { ...state, [action.field]: action.payload }
    case 'changeFields':
      return { ...state, ...action.payload }
    case 'changeGenderOptions':
      const index = state.genderOptions.indexOf(action.field)
      if (index !== -1) {
        return {
          ...state,
          genderOptions: [
            ...state.genderOptions.slice(0, index),
            ...state.genderOptions.slice(index + 1),
          ],
        }
      } else {
        return {
          ...state,
          genderOptions: [...state.genderOptions, action.field],
        }
      }
    case 'changePermissions':
      return {
        ...state,
        ...{
          ...state,
          permissions: {
            ...state.permissions,
            [action.payload.value]: action.payload.checked,
          },
        },
      }
    default:
      return state
  }
}

const INITIAL_STATE = {
  name: '',
  description: '',
  start: '',
  end: '',
  text: '',
  image: '',
  headerImage: '',
  permissions: {
    [CampaignsPermissionsValues.PHONE]: true,
    [CampaignsPermissionsValues.NAME]: false,
    [CampaignsPermissionsValues.EMAIL]: false,
    [CampaignsPermissionsValues.GENDER]: false,
    [CampaignsPermissionsValues.ZIPCODE]: false,
    [CampaignsPermissionsValues.BIRTHDATE]: false,
  },
  textLegalConditions: '',
  urlLegalConditions: '',
  confirmationMessage: '',
  genderOptions: genders.map(gender => gender.value),
  nodes: [],
  wifiNetwork: '',
}

export default function useCampaignForm ({ successCallback }: { successCallback: (payload: any) => void }) {
  const [shouldValidate, setShouldValidate] = useState(false)
  const { groupId, organizationId } = useParams()
  const { translate } = useLocales()

  const [values, valuesDispatch] = useReducer(formReducer, INITIAL_STATE)
  const [errors, errorsDispatch] = useReducer(formReducer, INITIAL_STATE)
  const [openAlert, setOpenAlert] = useState(false)

  const validateForm = useCallback(() => {
    const nameError = !Boolean(values.name)
      ? translate(ERRORS.MISSING_FIELD)
      : values.name.length > MAX_NAME_LENGTH
        ? translate(ERRORS.NAME_TOO_LONG)
        : ''

    errorsDispatch({
      type: 'change',
      field: 'name',
      payload: nameError,
    })

    const descriptionError = !Boolean(values.description)
      ? translate(ERRORS.MISSING_FIELD)
      : values.description.length > MAX_DESCRIPTION_LENGTH
        ? translate(ERRORS.DESCRIPTION_TOO_LONG)
        : ''

    errorsDispatch({
      type: 'change',
      field: 'description',
      payload: descriptionError,
    })

    let startError = ''
    let endError = ''

    if (!Boolean(values.start)) {
      startError = `${translate(ERRORS.MISSING_FIELD)}`
    }

    if (!Boolean(values.end)) {
      endError = `${translate(ERRORS.MISSING_FIELD)}`
    }

    if (
      Boolean(values.start) &&
      Boolean(values.end) &&
      values.start >= values.end
    ) {
      startError = `${translate(ERRORS.INVALID_DATE_RANGE)}`
      endError = `${translate(ERRORS.INVALID_DATE_RANGE)}`
    }

    errorsDispatch({
      type: 'change',
      field: 'start',
      payload: startError,
    })

    errorsDispatch({
      type: 'change',
      field: 'end',
      payload: endError,
    })

    const textError = !Boolean(values.text)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'text',
      payload: textError,
    })

    const imageError = !Boolean(values.image)
      ? translate(ERRORS.MISSING_IMAGE)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'image',
      payload: imageError,
    })

    const headerImageError = !Boolean(values.headerImage)
      ? translate(ERRORS.MISSING_IMAGE)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'headerImage',
      payload: headerImageError,
    })

    const textLegalConditionsError = !Boolean(values.textLegalConditions)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'textLegalConditions',
      payload: textLegalConditionsError,
    })

    const urlLegalConditionsError = !Boolean(values.urlLegalConditions)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'urlLegalConditions',
      payload: urlLegalConditionsError,
    })

    const confirmationMessageError = !Boolean(values.confirmationMessage)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'confirmationMessage',
      payload: confirmationMessageError,
    })

    const genderError = !values.permissions.gender
      ? false
      : values.permissions.gender && 0 === values.genderOptions.length

    errorsDispatch({
      type: 'change',
      field: 'genderOptions',
      payload: genderError,
    })

    const wifiNetworkError = !Boolean(values.wifiNetwork)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'confirmationMessage',
      payload: confirmationMessageError,
    })

    return (
      nameError === '' &&
      descriptionError === '' &&
      startError === '' &&
      endError === '' &&
      textError === '' &&
      imageError === '' &&
      headerImageError === '' &&
      textLegalConditionsError === '' &&
      urlLegalConditionsError === '' &&
      confirmationMessageError === '' &&
      genderError === false &&
      wifiNetworkError === ''
    )
  }, [
    translate,
    values.name,
    values.description,
    values.start,
    values.end,
    values.text,
    values.image,
    values.textLegalConditions,
    values.urlLegalConditions,
    values.headerImage,
    values.confirmationMessage,
    values.permissions.gender,
    values.genderOptions,
    values.wifiNetwork,
  ])

  useEffect(() => {
    if (shouldValidate) {
      validateForm()
    }
  }, [shouldValidate, validateForm])

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    valuesDispatch({
      type: 'change',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  const changeFields = useCallback((values: string) => {
    valuesDispatch({
      type: 'changeFields',
      payload: values,
    })
  }, [])

  const changePermissions = (permission: string) => {
    valuesDispatch({
      type: 'changePermissions',
      field: 'permissions',
      payload: permission,
    })
  }

  const handleDateChange = (field: string, value: string) => {
    valuesDispatch({
      type: 'change',
      field,
      payload: value,
    })
  }

  const handleGenderOptions = (field: string, value: string) => {
    valuesDispatch({
      type: 'changeGenderOptions',
      field,
      payload: value,
    })
  }

  const handleImageChange = async (file: File) => {
    if (file === null) {
      valuesDispatch({
        type: 'change',
        field: 'image',
        payload: null,
      })

      return
    }

    try {
      const base64 = (await base64Conversor(file)).split(';base64,')[1]
      valuesDispatch({
        type: 'change',
        field: 'image',
        payload: {
          fileName: file.name,
          mimeType: file.type,
          base64,
        },
      })

      errorsDispatch({
        type: 'change',
        field: 'image',
        payload: '',
      })
    } catch (e) {
      errorsDispatch({
        type: 'change',
        field: 'image',
        payload: translate(ERRORS.IMAGE_FAILED),
      })
    }
  }

  const handleHeaderImageChange = async (file: File) => {
    if (file === null) {
      valuesDispatch({
        type: 'change',
        field: 'headerImage',
        payload: null,
      })

      return
    }

    try {
      const base64 = (await base64Conversor(file)).split(';base64,')[1]

      valuesDispatch({
        type: 'change',
        field: 'headerImage',
        payload: {
          fileName: file.name,
          mimeType: file.type,
          base64,
        },
      })

      errorsDispatch({
        type: 'change',
        field: 'headerImage',
        payload: '',
      })
    } catch (_) {
      errorsDispatch({
        type: 'change',
        field: 'headerImage',
        payload: translate(ERRORS.IMAGE_FAILED),
      })
    }
  }

  const handleImageError = () => {
    errorsDispatch({
      type: 'change',
      field: 'image',
      payload: translate(ERRORS.IMAGE_FAILED),
    })
  }

  const handleHeaderImageError = () => {
    errorsDispatch({
      type: 'change',
      field: 'headerImage',
      payload: translate(ERRORS.IMAGE_FAILED),
    })
  }

  const handleSubmit = async () => {
    if (!shouldValidate) {
      setShouldValidate(true)
    }

    const isFormValid = validateForm()
    if (isFormValid) {
      successCallback({
        ...values,
        clientId: organizationId,
        groupId,
      })
    }
  }

  return {
    values,
    errors,
    handleFieldChange,
    changeFields,
    changePermissions,
    handleDateChange,
    handleGenderOptions,
    handleImageChange,
    handleHeaderImageChange,
    handleImageError,
    handleHeaderImageError,
    handleSubmit,
    openAlert,
    setOpenAlert,
  }
}
