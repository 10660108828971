import { generateUUID } from '../../../utils/uuidGenerator'
import CampaignsPermissionsValues from '../../common/enum/CampaignsPermissionsValues'
import { CreateCampaignsModel } from '../model/createCampaignsModel'
import { UpdateCampaignsModel } from '../model/UpdateCampaignsModel'

export default class CampaignsAdapter {
  static adaptCreateCampaignToPayload(data: any): CreateCampaignsModel {
    if (!data.permissions.gender) {
      delete data.genderOptions
    }

    const campaignModelData: CreateCampaignsModel = {
      uuid: generateUUID(),
      nodes: data.nodes.map((nodeUUID: string) => ({
        uuid: nodeUUID,
        network: Number(data.wifiNetwork),
      })),
      name: data.name,
      description: data.description,
      start: data.start,
      end: data.end,
      text: data.text,
      image: {
        fileName: data.image.fileName,
        mimeType: data.image.mimeType,
        base64: data.image.base64,
      },
      form: {
        phone: true,
        name: data.permissions.name,
        email: data.permissions.email,
        gender: data.permissions.gender,
        zipcode: data.permissions.zipcode,
        birthdate: data.permissions.birthdate,
        legalText: data.textLegalConditions,
        legalUrl: data.urlLegalConditions,
        image: {
          fileName: data.headerImage.fileName,
          mimeType: data.headerImage.fileName,
          base64: data.headerImage.base64,
        },
        confirmMessage: data.confirmationMessage,
      },
    }

    if (data.genderOptions) {
      campaignModelData.form.genderOptions = data.genderOptions
    }

    return campaignModelData
  }

  static transformToEdit(data: any): any {
    return {
      name: data.name,
      description: data.description,
      start: data.start,
      end: data.end,
      text: data.text,
      image: data.imageUrl,
      headerImage: data.form.imageUrl,
      permissions: {
        [CampaignsPermissionsValues.PHONE]: data.form.phone,
        [CampaignsPermissionsValues.NAME]: data.form.name,
        [CampaignsPermissionsValues.EMAIL]: data.form.email,
        [CampaignsPermissionsValues.GENDER]: data.form.gender,
        [CampaignsPermissionsValues.ZIPCODE]: data.form.zipcode,
        [CampaignsPermissionsValues.BIRTHDATE]: data.form.birthdate,
      },
      textLegalConditions: data.form.legalText,
      urlLegalConditions: data.form.legalUrl,
      confirmationMessage: data.form.confirmMessage,
      genderOptions: data.form.genderOptions ?? [],
      nodes: data.nodes ?? [],
      wifiNetwork: String(data.nodes[0]?.network),
    }
  }

  static transformToUpdate(data: any): UpdateCampaignsModel {
    const campaignModelData: UpdateCampaignsModel = {
      nodes: data.nodes.map((node: any) => ({
        uuid: node.uuid,
        network: Number(data.wifiNetwork),
      })),
      name: data.name,
      description: data.description,
      start: data.start,
      end: data.end,
      text: data.text,
      form: {
        phone: true,
        name: data.permissions.name,
        email: data.permissions.email,
        gender: data.permissions.gender,
        genderOptions: data.genderOptions ?? [],
        zipcode: data.permissions.zipcode,
        birthdate: data.permissions.birthdate,
        legalText: data.textLegalConditions,
        legalUrl: data.urlLegalConditions,
        confirmMessage: data.confirmationMessage,
      },
    }

    if (!data.permissions.gender) {
      delete campaignModelData.form.genderOptions
    }

    if (data.image) {
      campaignModelData.image = {
        fileName: data.image.fileName,
        mimeType: data.image.fileName,
        base64: data.image.base64,
      }
    }

    if (data.headerImage) {
      campaignModelData.form.image = {
        fileName: data.headerImage.fileName,
        mimeType: data.headerImage.fileName,
        base64: data.headerImage.base64,
      }
    }

    return campaignModelData
  }
}
