import {v4 as uuidv4} from 'uuid';
import {v5 as uuidv5} from 'uuid';

const UUID_NAMESPACE = '88314ceb-d8db-4899-906f-3b74c4e3877f'

export const generateUUID = (): string => {
  return uuidv4()
}

export const generateUUIDV5 = (value: string): string => {
  return uuidv5(value, UUID_NAMESPACE)
}
