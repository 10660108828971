import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import useOrganization from '../../../services/organizations/useOrganization'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import { useAuthContext } from '../../../auth/useAuthContext'
import { ROLES } from '../../../utils/rolesAndPermissions'
import useTagGroup from '../../../services/tagGroups/useTagGroup'
import { useLocales } from '../../../locales'
import style from './FluxBreadcrumbsStyle'

export default function FluxBreadcrumbs({
  currentPath,
  hasPadding,
}: {
  currentPath: string
  hasPadding?: boolean,
}): JSX.Element {
  const { translate } = useLocales()
  const { organizationId, groupId } = useParams()
  const { user: authUser } = useAuthContext() as any
  const { loading: isOrganizationLoading, data: organizationData } =
    useOrganization(organizationId!) as any
  const tagGroup = useTagGroup(organizationId!, groupId!) as any

  if (!organizationId) {
    return <></>
  }

  const getLink = (linkPath: string, text: string): JSX.Element => {
    return (
      <Link underline="hover" color="inherit" href={linkPath}>
        {text}
      </Link>
    )
  }

  const getLoadText = (): JSX.Element => {
    return (
      <Typography sx={style.loadText}>{`${translate('loading')}`}</Typography>
    )
  }

  const getOrganizationLink = (): JSX.Element | null => {
    if (authUser.role !== ROLES.SUPER_ADMIN) {
      return null
    }

    if (isOrganizationLoading) {
      return getLoadText()
    }

    if (!organizationData) {
      return getLink(
        PATH_ACCOUNTS.organizations,
        `${translate('breadcrumbs:backoffice')}`,
      )
    }

    return getLink(PATH_ACCOUNTS.organizations, organizationData?.name)
  }

  const getTagGroupLink = (): JSX.Element | null => {
    if (!organizationId || !tagGroup?.data?.name) {
      return null
    }

    if (authUser?.tagGroups && authUser?.tagGroups?.length <= 1) {
      return null
    }

    return getLink(
      PATH_ACCOUNTS.tagGroups(organizationId),
      tagGroup?.data?.name,
    )
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ px: hasPadding ? 3 : 0 }}>
      {getOrganizationLink()}
      {getTagGroupLink()}
      <Typography sx={style.pathText}>
        {currentPath}
      </Typography>
    </Breadcrumbs>
  )
}
