import { useLocales } from '../../locales'
import { useTheme } from '@mui/material/styles'
import { ZoneDataInterface, ZonesInterface } from '../../services/common/model/AnalyticsZonesInterface'
import HorizontalBarChartCard from '../card/Chart/HorizontalBarChartCard/HorizontalBarChartCard'

export default function AnonymousTimeComebackChart({
  averageTimeComeback,
}: {
  averageTimeComeback: ZonesInterface | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const title = `${translate('analytics:kpis:chart:average-visits-comeback')}`
  const tooltip = `${translate('analytics:kpis:chart:average-visits-comeback:tooltip')}`
  const colors = [theme.palette.chart.light.cashRegisters, theme.palette.chart.light.firstZone]

  const formatData = (value: string): string => {
    return `${value} ${translate(
      'analytics:kpis:chart:average-visits-comeback:yAxisDays',
    )}`
  }

  const zones = Object.keys(averageTimeComeback! || {}).map(
    key => averageTimeComeback![parseInt(key)],
  )

  const validZones = zones.filter(
    (zone): zone is ZoneDataInterface =>
      zone !== undefined && zone.current !== undefined && zone.current !== null,
  )

  const availableZones = validZones.map((zone, index) => ({
    name: zone.name || '',
    data: zone.current,
    color: colors[index],
  })).reverse()

  return (
    <HorizontalBarChartCard
      title={title}
      tooltip={tooltip}
      series={availableZones}
      isDataEmpty={!availableZones.length}
      axisYTooltipFormatter={formatData}
    />
  )
}
