import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import {
  Typography,
  Card,
  TableContainer,
  Table,
  TableBody,
  Stack,
} from '@mui/material'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table'
import TableLoader from '../../../components/TableLoader/TableLoader'
import useLocales from '../../../locales/useLocales'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import DashboardError from '../../../components/dashboard-error/DashboardError'
import { useLocationsByOrganizationId } from '../../../services/location/useLocations'
import useRemoveStatus from '../../../hooks/useRemoveStatus'
import { TOAST_HIDE_DURATION } from '../../../utils/consts'
import { useAuthContext } from '../../../auth/useAuthContext'
import useSnackbarMessageByQueryParams from '../../../hooks/useSnackbarMessageByQueryParams'
import LocationsTableRow from '../../../sections/dashboard/locations/table/LocationsTableRow'
import LocationPayloadModel from '../../../services/location/model/LocationPayloadModel'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import TABLE_HEADER from './LocationsTableHeader'
import ViewInfoActions from '../../../components/ViewInfoActions/ViewInfoActions'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'

function mapLocations(locations: LocationPayloadModel[] | undefined) {
  if (locations === undefined) {
    return []
  }

  const result = []

  for (const location of locations) {
    const row = {
      name: location.name,
      address: location.address,
      uuid: location.uuid,
    }

    result.push(row)
  }

  return result
}

export default function Locations() {
  const navigate = useNavigate()
  const { user } = useAuthContext() as any
  const { organizationId } = useParams()
  const { translate } = useLocales()
  const removeStatus = useRemoveStatus()

  const [snackbarMessage, closeSnackbarMessage] =
    useSnackbarMessageByQueryParams({
      created: 'locations:created',
      updated: 'locations:updated',
    }) as any

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable({ defaultOrderBy: 'name', defaultOrder: 'asc' })

  const { loading, data, error, remove, refetch } =
    useLocationsByOrganizationId(organizationId ?? '', order, orderBy)
  const locations = mapLocations(data)

  const handleEdit = (locationId: string) => {
    navigate(PATH_ACCOUNTS.editLocation(organizationId, locationId))
  }

  if (error) {
    return (
      <DashboardError
        title='Locations'
      />
    )
  }

  async function handleLocationDelete() {
    try {
      await remove(removeStatus.id)
      await refetch()
      removeStatus.activateFeedback(
        'success',
        translate('location:remove:success'),
      )
    } catch (error) {
      console.error(error)
      removeStatus.activateFeedback('error', translate('location:remove:error'))
    }
  }

  const locationsTableRow = (row: any): JSX.Element => {
    return (
      <LocationsTableRow
        key={row.uuid}
        row={row}
        role={user.role}
        selected={selected.includes(row.uuid)}
        handleDelete={() => removeStatus.activateConfirmation(row.uuid)}
        handleEdit={() => handleEdit(row.uuid)}
      />
    )
  }

  const locationsTable = () => {
    return (
      <Table sx={{ minWidth: 800 }}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEADER}
          rowCount={locations?.length}
          numSelected={selected.length}
          onSort={onSort}
        />

        <TableBody>
          {loading ? (
            <TableLoader />
          ) : (
            <>
              {locations.map(row => locationsTableRow(row))}

              <TableNoData
                isNotFound={locations.length === 0}
                title="There are no locations"
              />
            </>
          )}
        </TableBody>
      </Table>
    )
  }

  const title = `${translate('locations:title')}`

  return (
    <>
      <Helmet>
        <title>{title} | So69</title>
      </Helmet>
      <ViewContainer>
        <FluxBreadcrumbs currentPath={title} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 5 }}
        >
          <Typography variant="h4">{title}</Typography>
          <PrimaryButton
            icon={'eva:plus-fill'}
            onClick={() => navigate(PATH_ACCOUNTS.newLocation(organizationId))}
          >
            {`${translate('locations:create')}`}
          </PrimaryButton>
        </Stack>

        {!loading && locations.length === 0 ? (
          <NoItemsCard
            title={`${translate('no-locations:title')}`}
            description={`${translate('no-locations:description')}`}
            ctaLabel={`${translate('locations:create')}`}
            handleClick={() => navigate(PATH_ACCOUNTS.newLocation(organizationId))}
          />
        ) : null}

        {!loading && locations.length !== 0 ? (
          <Card>
            <TableContainer>
              <Scrollbar>{locationsTable()}</Scrollbar>
            </TableContainer>
            {loading ? null : (
              <TablePaginationCustom
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                count={locations?.length || 0}
              />
            )}
          </Card>
        ) : null}
      </ViewContainer>

      <ViewInfoActions
        confirmTitle={`${translate('location:remove:confirm:title')}`}
        confirmText={`${translate('location:remove:confirm')}`}
        removeStatus={removeStatus}
        snackbarMessage={snackbarMessage}
        handleConfirm={handleLocationDelete}
        closeSnackbarMessage={closeSnackbarMessage}
        autoHideDuration={TOAST_HIDE_DURATION}
      />
    </>
  )
}
