import { Grid, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import { Helmet } from 'react-helmet-async'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import RegisterCampaignForm from '../../../components/RegisterCampaignForm/RegisterCampaignForm'
import RegisterCampaignInfo from '../../../components/RegisterCampaignInfo/RegisterCampaignInfo'
import useQueryParams from '../../../hooks/useQueryParams'
import useRegisterCampaignForm from '../../../hooks/useRegisterCampaignForm'
import { useLocales } from '../../../locales'
import RegisterCampaignModel from '../../../services/campaigns/model/RegisterCampaignModel'
import useRegisterCampaign from '../../../services/campaigns/useRegisterCampaign'
import { useRegisterCampaignMutation } from '../../../services/campaigns/useRegisterCampaignMutation'
import UseStateType from '../../../services/common/types/UseStateType'
import useStyle from '../../Unsubscribe/UnsubscribeForm/UnsubscribeFormStyle'

export default function CampaignRegister(): JSX.Element {
  const queryParams = useQueryParams()

  const nodeMac = queryParams.get('called')
  const network = queryParams.get('network')
  const foreignId = queryParams.get('sessionid')

  const { data, error: checkCampaignError } = useRegisterCampaign(
    nodeMac!,
    Number(network!),
  ) as UseStateType<RegisterCampaignModel>

  const theme = useTheme()
  const style = useStyle(theme)
  const { translate } = useLocales()

  const mutation = useRegisterCampaignMutation()
  const { form, changeField, submit, validateField } = useRegisterCampaignForm({
    campaignId: data?.uuid,
    organizationId: data?.organizationUUID,
    foreignId: foreignId!,
    formFields: data?.form,
    successCallback: mutation.register,
  })

  const getFormContainer = () => {
    if (!data && checkCampaignError) {
      return (
        <Typography variant="h3">{`${translate(
          'register:not:active:campaign',
        )}`}</Typography>
      )
    }

    if (mutation.success) {
      return <Typography variant="h3">{data.form.confirmMessage}</Typography>
    }

    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign={'center'}>
            {data?.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RegisterCampaignInfo campaignInfo={data} />
        </Grid>
        <Grid item xs={12}>
          <RegisterCampaignForm
            form={form}
            campaignInfo={data}
            changeField={changeField}
            submit={submit}
            validateField={validateField}
          />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('register:campaign:page:title')}`}</title>
      </Helmet>
      <Stack sx={style.container}>
        <Stack sx={style.form}>{getFormContainer()}</Stack>
      </Stack>
      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={`${translate('register:campaign:error:message')}`}
        onClose={() => mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
      />
      <SnackbarAlert
        isOpen={checkCampaignError !== null}
        message={`${checkCampaignError?.message}`}
        onClose={() => mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </>
  )
}
