import { useLocales } from '../../locales'
import { useTheme } from '@mui/material/styles'
import {
  ZoneDataInterface,
  ZonesInterface,
} from '../../services/common/model/AnalyticsZonesInterface'
import HorizontalBarChartCard from '../card/Chart/HorizontalBarChartCard/HorizontalBarChartCard'

export default function RegisteredTimeComebackChart({
  registeredAverageTimeComeback,
  anonymousAverageTimeComeback,
}: {
  registeredAverageTimeComeback: ZonesInterface | undefined
  anonymousAverageTimeComeback: ZonesInterface | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const title = `${translate('analytics:kpis:chart:average-visits-comeback')}`
  const tooltip = `${translate(
    'analytics:kpis:chart:average-visits-comeback:tooltip',
  )}`
  const registeredColors = [
    theme.palette.chart.light.cashRegisters,
    theme.palette.chart.light.firstZone,
  ]
  const anonymousColors = [
    theme.palette.chart.dark.cashRegisters,
    theme.palette.chart.dark.firstZone,
  ]

  const formatData = (value: string): string => {
    return `${value} ${translate(
      'analytics:kpis:chart:average-visits-comeback:yAxisDays',
    )}`
  }

  const registeredZones = Object.keys(registeredAverageTimeComeback! || {}).map(
    key => registeredAverageTimeComeback![parseInt(key)],
  )

  const anonymousZones = Object.keys(anonymousAverageTimeComeback! || {}).map(
    key => anonymousAverageTimeComeback![parseInt(key)],
  )

  const validRegisteredZones = registeredZones.filter(
    (validRegisteredZones): validRegisteredZones is ZoneDataInterface =>
      validRegisteredZones !== undefined &&
      validRegisteredZones.current !== undefined &&
      validRegisteredZones.current !== null,
  )

  const availableRegisteredZones = validRegisteredZones
    .map((zone, index) => ({
      name: `R. ${zone.name}` || '',
      data: zone.current,
      color: registeredColors[index],
    })).reverse()

  const availableAnonymousZones = anonymousZones
    .map((zone, index) => ({
      name: `A. ${zone.name}` || '',
      data: zone.current,
      color: anonymousColors[index],
    })).reverse()

  const availableZones = availableRegisteredZones.concat(
    availableAnonymousZones,
  )

  return (
    <HorizontalBarChartCard
      title={title}
      tooltip={tooltip}
      series={availableZones}
      isDataEmpty={!availableZones.length}
      axisYTooltipFormatter={formatData}
    />
  )
}
