import {
  CampaignAlreadyExistError,
  CampaignEndedError,
  CampaignNotBelongOrganizationError,
  CampaignNotExistError,
  InvalidFieldError,
  NodeNotFoundError,
  OrganizationNotFoundError,
} from '../services/campaigns/Error'
import i18n from '../locales/i18n'

export const getErrorMessage = (error: Error | null): string => {
  if (!error) {
    return ''
  }

  if (error instanceof CampaignAlreadyExistError) {
    return `${i18n.t('new-campaign:error-dates-already-active')}`
  }

  if (error instanceof InvalidFieldError) {
    return `${i18n.t('new-campaign:form-error-description-too-long')}`
  }

  if (error instanceof OrganizationNotFoundError) {
    return `${i18n.t('campaign:error:organization:not:found')}`
  }

  if (error instanceof CampaignNotExistError) {
    return `${i18n.t('campaign:error:campaign:not:exist')}`
  }

  if (error instanceof NodeNotFoundError) {
    return `${i18n.t('campaign:error:node:not:found')}`
  }

  if (error instanceof CampaignNotBelongOrganizationError) {
    return `${i18n.t('campaign:error:campaign:not:belong:organization')}`
  }

  if (error instanceof CampaignEndedError) {
    return `${i18n.t('campaigns:ended')}`
  }

  return `${i18n.t('campaign:generic:error')}`
}
