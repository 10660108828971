import { TaxonomyModelInterface } from "../interfaces/TaxonomyModelInterface";
import TaxonomyTagModel from "./TaxonomyTagModel";

export default class TaxonomyModel implements TaxonomyModelInterface {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly tags: TaxonomyTagModel[],
    readonly isUsed: boolean,
  ) {}
}
