import { ZonesInterface } from "./AnalyticsZonesInterface";

export class KPIsModel {
  constructor(
    readonly label: string,
    readonly shortLabel: string,
    readonly zones: ZonesInterface,
    readonly isTimeKpi: boolean,
  ) {}
}
