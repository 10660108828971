import { useTheme } from '@mui/material'
import Chart from 'react-apexcharts'
import { GroupedBarChartSeriesType } from '../../../services/common/types/GroupedBarChartSeriesType'

export default function GroupedBarChart({
  series,
  labels,
  height,
}: {
  series: GroupedBarChartSeriesType[],
  labels: string[],
  height?: number | null,
}) {
  const theme = useTheme()

  const options = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
        borderRadius: 4,
        borderRadiusApplication: 'end',
        barHeight: 12,
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      fontSize: 16,
      itemMargin: {
        horizontal: 12,
      },
      itemWidth: 120,
      markers: {
        size: 5,
        shape: 'circle',
      },
    },
    colors: [theme.palette.primary.dark, theme.palette.primary.light],
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.2,
        },
      },
    },
    xaxis: {
      categories: labels,
    },
  }

  return (
    <>
      {/* @ts-ignore */}
      <Chart
        type="bar"
        options={options}
        series={series}
        height={height}
      />
    </>
  )
}
