import {
  unsubscribe as unsubscribeService,
  updateCampaign,
  switchCampaignState as switchCampaignStateService
} from './campaignsService'
import { useCallback, useState } from 'react'
import { createCampaign } from './campaignsService'
import { PATH_DASHBOARD } from '../../routes/paths'
import { useNavigate } from 'react-router'
import { CampaignEndedError } from './Error'
import CreateCampaignTransfomer from './adapter/CampaignsAdapter'

export default function useCampaignMutation() {
  const navigate = useNavigate()

  type UseCampaignCampaignStateType = {
    success: boolean
    loading: boolean
    error: Error | null
  }

  const [state, setState] = useState<UseCampaignCampaignStateType>({
    success: false,
    loading: false,
    error: null,
  })

  const create = async (campaignData: any) => {
    try {
      await createCampaign(campaignData, campaignData.clientId)

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(
        `${PATH_DASHBOARD.campaigns(
          campaignData.clientId,
          campaignData.groupId,
        )}?created=true`,
      )

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const update = async (
    organizationId: string,
    groupId: string,
    campaignId: string,
    campaignData: any,
  ) => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await updateCampaign(
        organizationId,
        campaignId,
        CreateCampaignTransfomer.transformToUpdate(campaignData),
      )

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(
        `${PATH_DASHBOARD.campaigns(organizationId, groupId)}?updated=true`,
      )

      return Promise.resolve()
    } catch (error) {
      if (error instanceof CampaignEndedError) {
        navigate(
          `${PATH_DASHBOARD.campaigns(
            organizationId,
            groupId,
          )}?ended-error=true`,
        )
        return
      }

      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const unsubscribe = useCallback(
    async (organizationId: string, customerId: string) => {
      try {
        await unsubscribeService(organizationId, customerId)

        setState({
          success: true,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        setState({
          success: false,
          loading: false,
          error,
        })

        throw error
      }
    },
    [],
  )

  const switchCampaignState = useCallback(
    async (organizationId: string, campaignId: string, paused: boolean) => {
      try {
        await switchCampaignStateService(organizationId, campaignId, paused)

        setState({
          success: true,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        setState({
          success: false,
          loading: false,
          error,
        })

        throw error
      }
    },
    [],
  )

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    unsubscribe,
    switchCampaignState,
    clearError,
  }
}
