import { useEffect, useState } from 'react'
import { useLocales } from '../../../locales'
import { untrack as untrackService } from '../../../services/organizations/service'
import useQueryParams from '../../../hooks/useQueryParams'
import usePublicOrganization from '../../../services/organizations/usePublicOrganization'
import {
  CustomerAlreadyUnsubscribedError,
  CustomerNotFound,
} from '../../../services/organizations/Error'
import UnsubscribeForm from '../UnsubscribeForm/UnsubscribeForm'
import UseStateType from '../../../services/common/types/UseStateType'
import PublicOrganizationModel from '../../../services/organizations/model/PublicOrganizationModel'
import useStyle from '../UnsubscribeForm/UnsubscribeFormStyle'
import { useTheme } from '@mui/system'
import { FormControl, Stack } from '@mui/material'
import SuccessInfo from '../../../components/successInfo/SuccessInfo'

export default function Untrack() {
  const { translate } = useLocales()
  const queryParams = useQueryParams()
  const theme = useTheme() as any

  const style = useStyle(theme)

  const organizationId = queryParams.get('organizationId')
  const customerId = queryParams.get('customerId')
  const {
    data: organizationData,
    error: organizationRequestError,
    loading,
  } = usePublicOrganization(
    organizationId!,
  ) as UseStateType<PublicOrganizationModel>
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const submit = async (): Promise<void> => {
    try {
      await untrackService(organizationId!, customerId!)

      setIsSuccess(true)
    } catch (error) {
      if (error instanceof CustomerAlreadyUnsubscribedError) {
        setError(
          `${translate(
            'organization:unsubscribe:customer:already:exist:error',
          )}`,
        )
        return
      }

      if (error instanceof CustomerNotFound) {
        setError(
          `${translate('organization:unsubscribe:customer:not:found:error')}`,
        )
        return
      }

      setError(`${translate('organization:unsubscribe:generic:error')}`)
    }
  }

  const clearError = () => {
    setError('')
  }

  useEffect(() => {
    if (organizationRequestError) {
      setError(`${translate('organization:fetch:generic:error')}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationRequestError])

  const getContent = (): JSX.Element => {
    if (!isSuccess) {
      return (
        <UnsubscribeForm
          isButtonDisabled={!Boolean(organizationId) || !Boolean(customerId)}
          submit={submit}
          logo={organizationData?.logo}
          unsubscribeName={organizationData?.name || ''}
          error={error}
          loading={loading}
          clearError={clearError}
          isSuccess={isSuccess}
        />
      )
    }

    return (
      <SuccessInfo
        title={`${translate('unsubscribe:success:title')}`}
        subtitle={`${translate('unsubscribe:success:text')}`}
      />
    )
  }

  return (
    <Stack sx={style.container}>
      <FormControl sx={style.form}>{getContent()}</FormControl>
    </Stack>
  )
}
