import { useEffect, useState, useCallback } from 'react'
import { fetchTaxonomy } from './taxonomyService'
import TaxonomyModel from './model/TaxonomyModel'

type UseTaxonomyStateType = {
  data: TaxonomyModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useTaxonomy(organizationUUID: string, taxonomyUUID: string): object {
  const [state, setState] = useState<UseTaxonomyStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchTaxonomy(organizationUUID, taxonomyUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID, taxonomyUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useTaxonomy
