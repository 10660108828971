import { TableRow, TableCell, Typography, Stack } from '@mui/material'
import { default as CustomAvatar } from '../../../../../components/custom-avatar/CustomAvatar'
import ActionPopover from '../ActionsPopover'
import { useAuthContext } from '../../../../../auth/useAuthContext'
import { ROLES } from '../../../../../utils/rolesAndPermissions'
import style from './UsersTableRowStyle'
import UserModel from '../../../../../services/users/model/UserModel'

const ROLE_LABELS = {
  [ROLES.SUPER_ADMIN]: 'Superadmin',
  [ROLES.ORGANIZATION_ADMIN]: 'Organization admin',
  [ROLES.BASE]: 'Employee',
}

export default function UsersTableRow({ row, selected, handleDelete, edit}: {
  row: UserModel,
  selected: boolean,
  handleDelete: () => void,
  edit: () => void,
}) {
  const { email, lastname, name, role } = row

  const organizationName = row.organization ? row.organization.name : '-'
  const { user: currentUser } = useAuthContext()

  const isActionsVisible = () => {
    return currentUser?.email !== row.email
  }

  const canDeleteUser = () => {
    return (
      currentUser!.role === ROLES.ORGANIZATION_ADMIN ||
      currentUser!.role === ROLES.SUPER_ADMIN
    )
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left" sx={style.nameCell}>
          <CustomAvatar name={name} />
          <Stack>
            <Typography>
              {name} {lastname}
            </Typography>
            <Typography color="text.disabled">
              {email}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{ROLE_LABELS[role]}</TableCell>
        <TableCell align="left">{organizationName}</TableCell>
        <TableCell align="right">
          {isActionsVisible() ? (
            <ActionPopover
              handleDelete={
                canDeleteUser()
                  ? handleDelete
                  : undefined
              }
              handleEdit={edit}
            />
          ) : null}
        </TableCell>
      </TableRow>
    </>
  )
}
