import { Theme } from '@mui/material'

const CampaignFormNodeTableStyle = (theme: Theme) => {
  return {
    label: {
      color: theme.palette.grey[500],
    },
  }
}

export default CampaignFormNodeTableStyle
