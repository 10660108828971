const RegisterCampaignFormStyle = {
  container: {
    height: '100dvh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default RegisterCampaignFormStyle
