import AnonymousKPIsModel from '../../../services/anonymous/model/AnonymousKPIsModel'
import { useLocales } from '../../../locales';
import { KPIsModel } from '../../../services/common/model/KPIsModel';
import AnalyticsKPIs from '../../analytics/AnalyticsKPIs/AnalyticsKPIs';
import EmptyKPIsCard from '../../card/EmptyKPIsCard/EmptyKPIsCard';

export default function AnonymousKPIs({
  kpis,
} : {
  kpis: AnonymousKPIsModel,
}): JSX.Element {
  const { translate } = useLocales()

  const getKPIs = (): KPIsModel[] => {
    return [
      new KPIsModel(
        `${translate('analytics:kpis:visitors')}`,
        `${translate('analytics:kpis:initial:visitors')}`,
        kpis.unique_detections_sum,
        false,
      ),
      new KPIsModel(
        `${translate('analytics:kpis:visits')}`,
        `${translate('analytics:kpis:initial:visits')}`,
        kpis.detections_sum,
        false,
      ),
      new KPIsModel(
        `${translate('analytics:kpis:avg:time')}`,
        `${translate('analytics:kpis:initial:avg:time')}`,
        kpis.average_time_stay,
        true,
      ),
    ]
  }

  if (!kpis) {
    return <EmptyKPIsCard />
  }

  return (
    <AnalyticsKPIs kpis={getKPIs()} />
  )
}
