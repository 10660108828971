import { useState } from 'react'

const INITIAL_STATE = Object.freeze({
  showConfirmation: false,
  show: false,
  type: 'success',
  message: '',
  id: '',
})

export default function useRemoveStatus() {
  const [state, setState] = useState(INITIAL_STATE)

  function activateConfirmation(id, message) {
    setState({
      ...INITIAL_STATE,
      id,
      showConfirmation: true,
      message,
    })
  }

  function cancel() {
    setState(INITIAL_STATE)
  }

  function activateFeedback(type, message) {
    setState({ show: true, type, message, showConfirmation: false })
  }

  function closeFeedback() {
    setState(state => ({ ...state, show: false }))
  }

  return {
    ...state,
    activateConfirmation,
    cancel,
    activateFeedback,
    closeFeedback,
  }
}
