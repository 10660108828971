export default class RegisterCampaignRegisterPayloadModel {
  constructor(
    readonly uuid: string,
    readonly foreignId: string,
    readonly phone: string,
    readonly email: string,
    readonly gender: string,
    readonly zipcode: string,
    readonly birthdate: string
  ) {}
}
