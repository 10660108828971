import i18n from "../../../locales/i18n";
import PermissionsValue from "../../common/enum/PermissionsValues";

const defaultUserPermissions = (form: any) =>  [
  {
    label: `${i18n.t('new-user:form:permission:anonymous-module')}`,
    value: PermissionsValue.ANONYMOUS_PERMISSION,
    checked: form.values?.permissions?.PERM_ANONYMOUS ?? false,
  },
  {
    label: `${i18n.t('new-user:form:permission:registered-module')}`,
    value: PermissionsValue.REGISTERED_PERMISSION,
    checked: form.values?.permissions?.PERM_REGISTERED ?? false,
  },
  {
    label: `${i18n.t('new-user:form:permission:captation-permission')}`,
    value: PermissionsValue.CAPTATION_PERMISSION,
    checked: form.values?.permissions?.CAPTATION_PERMISSION ?? false,
  },
]

export default defaultUserPermissions
