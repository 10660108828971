const RegisteredKPIsComparativeChartStyle = {
  card: {
    px: 3,
    py: 2,
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}

export default RegisteredKPIsComparativeChartStyle
