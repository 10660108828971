import EmptyCard from '../../card/EmptyCard/EmptyCard';
import { Grid } from '@mui/material';

export default function EmptyKPIsCard(): JSX.Element {
  return (
    <>
      <Grid item xs={12} md={4}>
        <EmptyCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <EmptyCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <EmptyCard />
      </Grid>
    </>
  )
}
