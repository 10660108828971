import { Box, Card, Typography } from '@mui/material'
import style from './LinearAndBarChartCardStyle'
import InfoTooltip from '../../../infoTooltip/InfoTooltip'
import Spacer from '../../../Spacer/Spacer'
import LinearAndBarChart from '../LinearAndBarChart'

export default function LinearAndBarChartCard({
  id,
  title,
  subtitle,
  series,
  categories,
  tooltip,
  height,
}: {
  id: string,
  title: string,
  subtitle: string,
  series: any,
  categories: string[],
  tooltip?: string,
  height?: number,
}) {
  const getTooltip = (): JSX.Element => {
    if (!Boolean(tooltip)) {
      return <></>
    }

    return <InfoTooltip info={tooltip!} />
  }

  return (
    <Card sx={style.container}>
      <Box sx={style.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        {getTooltip()}
      </Box>
      <Box>
        <Typography color={'#828d99'} variant="subtitle2">
          {subtitle}
        </Typography>
      </Box>
      <Spacer size={3} />
      <LinearAndBarChart
        id={id}
        series={series}
        categories={categories}
        height={height}
      />
    </Card>
  )
}
