import {
  Card,
  FormControl,
  Grid,
} from '@mui/material'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import style from './OrganizationSectionFormStyle'
import { useLocales } from '../../locales'
import { useEffect, useState } from 'react'
import ImageDropArea from '../image-drop-area/ImageDropArea'
import { createFileFromUrl, createFileName } from '../../utils/createFile'

export default function OrganizationImageForm({ form }: { form: any }) {
  const { translate } = useLocales()

  const [file, setFile] = useState<File | null>(null)

  const getFormPreview = (): string => {
    return (process.env.REACT_APP_HOST_API_KEY + form.values.logo).replace(
      /([^:]\/)\/+/g,
      '$1',
    )
  }

  useEffect(() => {
    async function loadHeaderImage() {
      if (file || !form.values.logo) {
        return
      }

      const downloadedFile = await createFileFromUrl(
        getFormPreview(),
        createFileName('logo'),
      )

      if (downloadedFile) {
        setFile(downloadedFile)
      }
    }

    loadHeaderImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.logo])

  const handleImageChange = (file: File) => {
    form.setLogo(file)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('new:organization:logo:title')}`}
          subtitle={`${translate('new:organization:logo:description')}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              <ImageDropArea
                onChange={handleImageChange}
                onError={form.handleImageError}
                hasError={!!form.errors.logo}
                fileImage={file}
              />
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
