import i18n from "../../../locales/i18n"

const TABLE_HEADER = [
  {
    id: 'name',
    label: i18n.t('users:table:heading:name'),
    align: 'left',
    width: '18.75rem',
  },
  {
    id: 'role',
    label: i18n.t('users:table:heading:role'),
    align: 'left',
  },
  {
    id: 'organization.name',
    label: i18n.t('users:table:heading:organization'),

    align: 'left',
  },
  { id: 'actions', label: '', align: 'center' },
]

export default TABLE_HEADER
