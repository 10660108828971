import { useLocales } from '../../locales'
import TrafficZonesModel from '../../services/common/model/TrafficZonesModel'
import LinearAndBarChartCard from '../chart/LinearAndBarChart/LinearAndBarChartCard/LinearAndBarChartCard'
import { calculateIncrementPercentage } from '../../utils/mathHelper'
import DatePeriod from '../../services/common/enum/DatePeriod'
import { format, getWeek, isValid } from 'date-fns'
import { FILTER_PERIODS, getWeekDay, WEEK_DAYS } from '../../utils/periods'

class LinearAndBarModel {
  date: string
  count: number
  fullDate?: string;

  constructor(
    date: string,
    count: number,
    fullDate?: string,
  ) {
    this.date = date
    this.count = count
    if (fullDate) {
      this.fullDate = fullDate;
    }
  }
}

export default function RegisteredLinearAndBarVisits({
  selectedPeriod,
  registeredTrafficZonesData,
  anonymousTrafficZonesData,
 }: {
  selectedPeriod: string,
  registeredTrafficZonesData: TrafficZonesModel[],
  anonymousTrafficZonesData: TrafficZonesModel[],
 }) {
  const { translate } = useLocales()

  const registeredData: LinearAndBarModel[] = []
  const anonymousData: LinearAndBarModel[] = []

  const updateData = (
    data: TrafficZonesModel,
    barData: LinearAndBarModel[],
  ) => {
    for (let i = 0; i < data.currentZoneVisits.length; i++) {
      if (barData[i] === undefined) {
        barData.push(
          new LinearAndBarModel(
            data.currentZoneVisits[i].date,
            data.currentZoneVisits[i].count,
            data.currentZoneVisits[i]!.fullDate,
          )
        )
      } else {
        barData[i].count = barData[i].count + data.currentZoneVisits[i].count
      }
    }
  }

  for (const trafficZonesData of registeredTrafficZonesData || []) {
    updateData(trafficZonesData, registeredData)
  }

  for (const trafficZonesData of anonymousTrafficZonesData || []) {
    updateData(trafficZonesData, anonymousData)
  }

  const getIncrement = (): string => {
    const totalRegistered = registeredData.reduce((acc, item) => acc + item.count, 0);
    const totalAnonymous = anonymousData.reduce((acc, item) => acc + item.count, 0);

    if (totalRegistered === 0 || totalAnonymous === 0) {
      return ''
    }

    return (
        `(${calculateIncrementPercentage(totalAnonymous, totalRegistered)}) ${translate('analytics:kpis:registered:vs:anonymous')}`
    )
  }

  const series = [
    {
      name: `${translate('registered:kpis:chart:total-anonymous-registered:registered:label')}`,
      type: 'column',
      data: anonymousData.map(data => data.count),
    },
    {
      name: `${translate('registered:kpis:chart:total-anonymous-registered:anonymous:label')}`,
      type: 'area',
      data: registeredData.map(data => data.count),
    },
  ]

  const getLast28DaysLabelData = (date: string, index: number) => {
    const newDate = new Date(date)

    if (!isValid(newDate)) {
      return ''
    }

    if (index === 0 || format(newDate, 'EEEE') === WEEK_DAYS.MONDAY) {
        return `${getWeek(newDate)} ${getWeekDay(newDate)}`
    }

    return getWeekDay(newDate)
  }

  const chartCategories = registeredData.map(
    (data: LinearAndBarModel, index: number) => {
      if (selectedPeriod === DatePeriod.LAST_28_DAYS) {
        return getLast28DaysLabelData(data.date, index)
      }

      if (selectedPeriod.slice(0, 5) === FILTER_PERIODS.month.toUpperCase()) {
        return data.date
      }

      return (
        `${translate(
          `date:calendar:${data.date.toLowerCase()}`,
        )}`
      )
    },
  )

  return (
    <LinearAndBarChartCard
      id="registered-linear-bar"
      title={`${translate('registered:kpis:chart:total-anonymous-registered:title')}`}
      tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque gravida nunc quis ante imperdiet, id interdum tellus suscipit."
      subtitle={getIncrement()}
      series={series}
      categories={chartCategories}
    />
  )
}


