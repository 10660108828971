import { useEffect, useState, useCallback } from 'react'
import { fetchRegisteredFrequency } from './registeredService'
import RegisteredFrequencyModel from './model/RegisteredFrequencyModel'
import { SelectItemType } from '../common/types/SelectItemType'

export type UseRegisteredFrequencyStateType = {
  data: RegisteredFrequencyModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useRegisteredFrequency(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): object {
  const [state, setState] = useState<UseRegisteredFrequencyStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchRegisteredFrequency(organizationUUID, tagGroupUUID, locations, period)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID, period, tagGroupUUID, locations])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useRegisteredFrequency
