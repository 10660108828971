import { Typography } from '@mui/material'

export default function SuccessInfo({
  title,
  subtitle,
}: {
  title: string
  subtitle: string
}) {
  return (
    <>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2">
        {subtitle}
      </Typography>
    </>
  )
}
