import { CampaignsDataModel } from "../../campaigns/model/CampaignsModel";
import CampaignsNodeModel from "../../campaigns/model/CampaignsNodeModel";

export class LoyaltyCampaignsModel extends CampaignsDataModel{
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly description: string,
    readonly node: CampaignsNodeModel,
    readonly start: string,
    readonly end: string,
    readonly text: string,
    readonly imageUrl: string | null,
    readonly status: string,
    readonly paused: boolean,
    readonly sendMessagesCount: number
  ) {
    super(
      uuid,
      name,
      description,
      node,
      start,
      end,
      text,
      imageUrl,
      status,
      paused
    );
  }
}
