import { useEffect, useState, useCallback } from 'react'
import { fetchRegisteredAttractionRate } from './registeredService'
import AttractionRateModel from '../common/model/AttractionRateModel'
import { SelectItemType } from '../common/types/SelectItemType'

type useRegisteredDetectionsStateType = {
  data: AttractionRateModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useRegisteredAttractionRate(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): object {
  const [state, setState] = useState<useRegisteredDetectionsStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchRegisteredAttractionRate(organizationUUID, tagGroupUUID, locations, period)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [locations, organizationUUID, period, tagGroupUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useRegisteredAttractionRate
