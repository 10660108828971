const UsersTableRowStyle = {
  nameCell: {
    display: 'flex',
    gap: '1rem'
  },
  brandsCell: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'start',
  }
}

export default UsersTableRowStyle
