import FluxBreadcrumbs from "../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs";
import { useLocales } from "../../../locales";
import NewUser from "../NewUser";

export default function NewOrganizationUser() {
  const { translate } = useLocales()

  return (
    <>
      <FluxBreadcrumbs currentPath={`${translate('users:create')}`} hasPadding />
      <NewUser isOrganizationView />
    </>
  )
}
