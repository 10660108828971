import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import RegisterCampaignModel from '../../services/campaigns/model/RegisterCampaignModel'
import style from './RegisterCampaignInfoStyle'

export default function RegisterCampaignInfo({
  campaignInfo,
}: {
  campaignInfo: RegisterCampaignModel | undefined
}) {
  return (
    <Grid container spacing={2} sx={style.formContainer}>
      <Grid item xs={12} sx={style.imageContainer}>
        <Box
          component="img"
          sx={style.image}
          src={campaignInfo?.form.imageUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{campaignInfo?.description}</Typography>
      </Grid>
    </Grid>
  )
}
