import { Box, Card, Typography } from '@mui/material'
import style from './TotalVisitsGraphStyle'
import Spacer from '../Spacer/Spacer'
import ColumnChart from '../chart/ColumnChart/ColumnChart'
import InfoTooltip from '../infoTooltip/InfoTooltip'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import { ZONES } from '../../utils/zones'
import { calculateIncrementPercentage } from '../../utils/mathHelper'
import DatePeriod from '../../services/common/enum/DatePeriod'
import { fDate, fDayOfWeek } from '../../utils/formatTime'
import { useTheme } from '@emotion/react'
import { ZonesInterface } from '../../services/common/model/AnalyticsZonesInterface'
import { format, getWeek, isValid } from 'date-fns'
import { getWeekDay, WEEK_DAYS } from '../../utils/periods'
import TrafficZonesModel from '../../services/common/model/TrafficZonesModel'
import { TrafficZonesVisitInterface } from '../../services/common/interface/TrafficZonesInterface'

export default function TotalVisitsGraph({
  trafficZones,
  detectionsSum,
  selectedPeriod,
}: {
  trafficZones: TrafficZonesModel[]
  detectionsSum: ZonesInterface
  selectedPeriod: string
}) {
  const { translate } = useLocales()
  const title = `${translate('anonymous:kpis:chart:total:visits')}`
  const tooltipText = `${translate(
    'anonymous:kpis:chart:total:visits:tooltip',
  )}`
  const theme = useTheme() as any

  const SHOW_COLUMN_LABELS_IN_DAYS_THRESHOLD = 20

  const getEmptyCard = (): JSX.Element => {
    return <EmptyCard title={title} height={300} tooltip={tooltipText} />
  }

  if (!trafficZones) {
    return getEmptyCard()
  }

  const getLast28DaysLabelData = (date: string, index: number) => {
    const newDate = new Date(date)

    if (!isValid(newDate)) {
      return ['']
    }

    if (index === 0 || format(newDate, 'EEEE') === WEEK_DAYS.MONDAY) {
      const previousData = trafficZones[0]?.previousZoneVisits[index]
      const previousDate = new Date(previousData?.date)
      const weekLabel = `${getWeek(previousDate) || ''} / ${getWeek(newDate)} ${getWeekDay(newDate)}`

      return [weekLabel]
    }

    return [getWeekDay(newDate)]
  }

  const chartCategories = trafficZones[0]?.currentZoneVisits.map(
    (trafficZone: TrafficZonesVisitInterface, index: number) => {
      let mainLabel = trafficZone.date
      const isLabelTranslatable = mainLabel.match(/^[\d]+$/g) === null

      if (isLabelTranslatable) {
        mainLabel = `${translate(
          `date:calendar:${trafficZone.date.toLowerCase()}`,
        )}`
      }

      if (!isLabelTranslatable) {
        const dayWeek = `${translate(
          `date:calendar:initials:${fDayOfWeek(
            trafficZone.fullDate,
          ).toLocaleLowerCase()}`,
        )}`
        const date = trafficZone.date

        return [dayWeek, date]
      }

      if (selectedPeriod === DatePeriod.CURRENT_YEAR) {
        return [mainLabel]
      }

      if (selectedPeriod === DatePeriod.LAST_28_DAYS) {
        return getLast28DaysLabelData(trafficZone.date, index)
      }


      const date = fDate(new Date(trafficZone.fullDate ?? null))
      return [mainLabel, date]
    },
  )

  const lightColors = [
    theme.palette.chart.light.cashRegisters,
    theme.palette.chart.light.firstZone,
    theme.palette.chart.light.secondZone,
  ]
  const darkColors = [
    theme.palette.chart.dark.cashRegisters,
    theme.palette.chart.dark.firstZone,
    theme.palette.chart.dark.secondZone,
  ]

  const previousTrafficZones = trafficZones
    .map((trafficZone: TrafficZonesModel) => {
      return {
        name: `${trafficZone.zoneName} prev`,
        group: 'previous',
        color: darkColors[trafficZone.zoneIndex],
        zoneIndex: trafficZone.zoneIndex,
        data: trafficZone.previousZoneVisits.map(
          (previousData: TrafficZonesVisitInterface) =>
            previousData.count,
        ),
      }
    })
    .sort(
      (currentZoneIndex, comparisonZoneIndex) =>
        currentZoneIndex.zoneIndex - comparisonZoneIndex.zoneIndex,
    )

  const currentTrafficZones = trafficZones
    .map((trafficZone: TrafficZonesModel) => {
      return {
        name: trafficZone.zoneName,
        group: 'current',
        color: lightColors[trafficZone.zoneIndex],
        zoneIndex: trafficZone.zoneIndex,
        data: trafficZone.currentZoneVisits.map(
          (previousData: TrafficZonesVisitInterface) =>
            previousData.count,
        ),
      }
    })
    .sort(
      (currentZoneIndex, comparisonZoneIndex) =>
        currentZoneIndex.zoneIndex - comparisonZoneIndex.zoneIndex,
    )

  const showColumnLabels =
    trafficZones[0]?.previousZoneVisits.length <
    SHOW_COLUMN_LABELS_IN_DAYS_THRESHOLD

  const trafficZonesData = previousTrafficZones.concat(currentTrafficZones)

  if (trafficZonesData.length === 0 || !trafficZonesData || !detectionsSum) {
    return getEmptyCard()
  }
  const ZONE_CHECKOUT = detectionsSum[ZONES.ZONE_CHECKOUT.value]
  const ZONE_2 = detectionsSum[ZONES.ZONE_2.value]
  const ZONE_3 = detectionsSum[ZONES.ZONE_3.value]

  const CURRENT_TOTAL =
    (ZONE_CHECKOUT?.current || 0) +
    (ZONE_2?.current || 0) +
    (ZONE_3?.current || 0)
  const PREVIOUS_TOTAL =
    (ZONE_CHECKOUT?.previous || 0) +
    (ZONE_2?.previous || 0) +
    (ZONE_3?.previous || 0)

  const getIncrement = (): JSX.Element => {
    if (CURRENT_TOTAL === 0 || PREVIOUS_TOTAL === 0) {
      return <></>
    }

    return (
      <Typography color={'#828d99'} variant="subtitle2">
        {`(${calculateIncrementPercentage(CURRENT_TOTAL, PREVIOUS_TOTAL)})`}
        {` ${translate(
          `analytics:kpis:chart:total:visits:variation:${selectedPeriod.toLocaleLowerCase()}`,
        )}`}
      </Typography>
    )
  }

  return (
    <Card sx={style.container}>
      <Box sx={style.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        <InfoTooltip info={tooltipText} />
      </Box>
      {getIncrement()}
      <Spacer size={3} />
      <ColumnChart
        data={trafficZonesData}
        categories={chartCategories}
        showColumnLabels={showColumnLabels}
      />
    </Card>
  )
}
