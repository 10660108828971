import { useCallback, useReducer, useState } from "react"
import { useLocales } from "../locales"
import RegisterCampaignFormInterface from "../services/campaigns/interface/registerCampaignFormInterface"
import { getDefaultCountry } from "../utils/getDefaultCountry"
import { emailValidator, phoneNumberValidator, preffixValidator, zipCodeValidator } from "../utils/validators"

const DEFAULT_PREFIX = getDefaultCountry().prefix

const calculateInitialState = () => ({
  values: {
    contactPhonePreffix: DEFAULT_PREFIX,
    phone: '',
    name: '',
    email: '',
    gender: '',
    zipCode: '',
    birthDate: '',
  },
  errors: {
    contactPhonePreffix: '',
    phone: '',
    name: '',
    email: '',
    gender: '',
    zipCode: '',
    birthDate: '',
  },
})


const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'changeFields':
      return {
        ...state,
        values: { ...state.values, ...action.payload },
      }
    case 'changeField':
      return {
        ...state,
        values: { ...state.values, [action.field]: action.payload },
      }
    case 'updateError':
      return {
        ...state,
        errors: { ...state.errors, [action.field]: action.payload },
      }
    case 'updateErrors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    default:
      return state
  }
}

export default function useRegisterCampaignForm (
  { campaignId, organizationId, foreignId, formFields, successCallback }: { campaignId: string, organizationId: string, foreignId: string, formFields?: RegisterCampaignFormInterface, successCallback: (campaignId: string, organizationId: string, foreignId: string, formValues: object) => void;  }
) {
  const [form, dispatchForm] = useReducer(
    formReducer,
    calculateInitialState(),
  )
  const { translate } = useLocales()

  const ERRORS = {
    missingField: translate('common:form:error-missing-field'),
    invalidPhone: translate('common:error-invalid-phone'),
    invalidEmail: translate('common:error-invalid-email'),
    invalidZipCode: translate('common:error-invalid-zip-code'),
  }

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const makeValidations = () => ({
    contactPhonePreffix: (value: string) => formFields?.phone ? (!Boolean(value) ? ERRORS.missingField : !preffixValidator(value) ? ERRORS.invalidPhone : '') : '',
    phone: (value: string) => formFields?.phone ? (!Boolean(value) ? (ERRORS.missingField) : !phoneNumberValidator(value) ? (ERRORS.invalidPhone) : '') : '',
    email: (value: string) => formFields?.email ? (!Boolean(value) ? (ERRORS.missingField) : !emailValidator(value) ? ERRORS.invalidEmail : '') : '',
    gender: (value: string) => formFields?.gender ? (!Boolean(value) ? ERRORS.missingField : '') : '',
    zipCode: (value: string) => formFields?.zipcode ? (!zipCodeValidator(value) ? ERRORS.invalidZipCode : '') : '',
    birthDate: (value: string) => formFields?.birthdate ? (!Boolean(value) ? ERRORS.missingField : '') : ''
  })

  const validations: any = makeValidations()

  const validateField = useCallback(
    (event: any) => {
      if (!shouldValidateForm) {
        return
      }

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'updateError',
        field: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validateForm = useCallback(() => {

    if (!shouldValidateForm) {
      setShouldValidateForm(true)
    }

    const errors = { ...form.errors }

    errors.contactPhonePreffix = validations.contactPhonePreffix(form.values.contactPhonePreffix)
    errors.phone = validations.phone(form.values.phone)
    errors.email = validations.email(form.values.email)
    errors.gender = validations.gender(form.values.gender)
    errors.zipCode = validations.zipCode(form.values.zipCode)
    errors.birthDate = validations.birthDate(form.values.birthDate)

    dispatchForm({
      type: 'updateErrors',
      payload: errors,
    })

    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors, shouldValidateForm])

  const submit = useCallback(
    (event: any) => {
      event.preventDefault()

      if (!shouldValidateForm) {
        setShouldValidateForm(true)
      }

      const isFormValid = validateForm()

      if (isFormValid) {
        successCallback(campaignId, organizationId, foreignId, form.values)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldValidateForm, validateForm, successCallback, form.values],
  )

  const changeField = (event: any) => {
    dispatchForm({
      type: 'changeField',
      field: event.target.name,
      payload: event.target.value,
    })
  }

  return {
    form,
    validateField,
    validateForm,
    changeField,
    submit,
  }
}
