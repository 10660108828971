import { ListSubheader, Stack } from '@mui/material'
import NavSectionItem from '../NavSectionItem/NavSectionItem'
import style from './NavSectionVerticalStyle'
import { useLocales } from '../../../locales'
import { NavigationSectionType } from '../../../services/common/types/NavigationSectionType'

export default function NavSectionVertical({
  data,
}: {
  data: NavigationSectionType[]
}) {
  const { translate } = useLocales()

  return (
    <Stack spacing={0.5} sx={style.container}>
      {data.map((menuElements: NavigationSectionType, index: number) => {
        if (!menuElements.items.length) {
          return null
        }

        const navigationItems = menuElements?.items?.map((item: any) => (
          <NavSectionItem key={`link-to-${item.path}`} item={item} />
        ))

        return (
          <Stack key={index}>
            <ListSubheader sx={style.title}>
              {`${translate(menuElements.subheader)}`}
            </ListSubheader>
            {navigationItems}
          </Stack>
        )
      })}
    </Stack>
  )
}
