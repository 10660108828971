import { useEffect, useState, useCallback } from 'react'
import { fetchRegisteredDwellTime } from './registeredService'
import DwellTimeModel from '../common/model/DwellTimeModel'
import { SelectItemType } from '../common/types/SelectItemType'

type UseRegisteredDwellTimeStateType = {
  data: DwellTimeModel | undefined
  loading: boolean
  error: Error | null
}

function useRegisteredDwellTime(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): object {
  const [state, setState] = useState<UseRegisteredDwellTimeStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchRegisteredDwellTime(
        organizationUUID,
        tagGroupUUID,
        locations,
        period,
      )

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [locations, organizationUUID, period, tagGroupUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useRegisteredDwellTime
