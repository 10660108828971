import i18n from "../../locales/i18n"
import { calculatePercentage, roundKpisProperties } from "../../utils/mathHelper"
import AttractionRateModel from "../common/model/AttractionRateModel"
import DwellTimeModel from "../common/model/DwellTimeModel"
import TrafficZonesModel from "../common/model/TrafficZonesModel"
import AverageVisitsModel from "../registered/model/AverageVisitModel"
import AnonymousAttractionRateApiInterface from "./interfaces/AnonymousAttractionRateApiInterface"
import AnonymousAverageVisitsApiInterface from "./interfaces/AnonymousAverageVisitsApiInterface"
import { AnonymousDetectionsApiInterface } from "./interfaces/AnonymousDetectionsApiInterface"
import { AnonymousDwellTimeApiInterface } from "./interfaces/AnonymousDwellTimeApiInterface"
import AnonymousFrequencyApiInterface from "./interfaces/AnonymousFrequencyApiInterface"
import { AnonymousTraficZonesApiInterface } from "./interfaces/AnonymousTraficZonesApiInterface"
import AnonymousDetectionsModel from "./model/AnonymousDetectionsModel"
import AnonymousDetectionsWeekApiModel from "./model/AnonymousDetectionsWeekApiModel"
import AnonymousFrequencyModel from "./model/AnonymousFrequencyModel"
import AnonymousKPIsApiModel from "./model/AnonymousKPIsApiModel"
import AnonymousKPIsModel from "./model/AnonymousKPIsModel"


export function adaptAnonymousKPIsResponse(response: AnonymousKPIsApiModel): AnonymousKPIsModel {
  return new AnonymousKPIsModel(
    roundKpisProperties(response?.unique_detection_sum) || {},
    roundKpisProperties(response?.detection_sum) || {},
    roundKpisProperties(response?.detection_average) || {},
    roundKpisProperties(response?.average_time_stay) || {},
    roundKpisProperties(response?.new_vs_loyal) || {},
    roundKpisProperties(response?.average_time_comeback) || {},
  )
}

export function adaptAnonymousTraficZonesResponse(
  response: AnonymousTraficZonesApiInterface[],
): TrafficZonesModel[] {
  return response.map((traficZone: AnonymousTraficZonesApiInterface) => {
    return new TrafficZonesModel(
      traficZone.zoneIndex,
      traficZone.zoneName,
      traficZone.currentZoneVisits,
      traficZone.previousZoneVisits,
    )
  })
}

export function adaptAnonymousDwellTimeResponse(
  response: AnonymousDwellTimeApiInterface,
): DwellTimeModel {
  return new DwellTimeModel(
    calculatePercentage(response.dwellTimeRate ?? 0, 2),
    calculatePercentage(response.dwellTimeDifferenceRate ?? 0, 2)
  )
}

export function adaptAnonymousDetectionsResponse(
  response: AnonymousDetectionsApiInterface[],
): AnonymousDetectionsModel[] {
  return response.map((detection: AnonymousDetectionsApiInterface) => {
    return new AnonymousDetectionsModel(
      detection.hour,
      detection.data.map((data: AnonymousDetectionsWeekApiModel) => {
        return {
          x: i18n.t(`date:calendar:${data.weekday.toLowerCase()}`),
          y: data.value,
        }
      }),
    )
  })
}

export function adaptAnonymousFrequencyReponse(
  response: AnonymousFrequencyApiInterface,
): AnonymousFrequencyModel {
  return new AnonymousFrequencyModel(
    response.one,
    response.two,
    response.three_plus,
  )
}

export function adaptAnonymousAttractionRate(
  response: AnonymousAttractionRateApiInterface,
): AttractionRateModel {
  return new AttractionRateModel(
    {
      external: calculatePercentage(response.current.external, 2),
      internal: calculatePercentage(response.current.internal, 2),
    },
    {
      external: calculatePercentage(response.previous.external, 2),
      internal: calculatePercentage(response.previous.internal, 2),
    },
    calculatePercentage(response.variation, 2),
  )
}

export function adaptAnonymousAverageVisits(response: AnonymousAverageVisitsApiInterface): AverageVisitsModel {
  return new AverageVisitsModel(
    Math.round(response.currentPeriod),
    Math.round(response.previousPeriod),
    calculatePercentage(response.variation, 2),
  )
}
