import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { fetch, remove } from './service'
import { UsersStateInterface } from './interfaces/UsersStateInterface'

export default function useFetchUsers({
  page,
  itemsPerPage,
  order,
  orderBy
} : {
  page: number,
  itemsPerPage: number,
  order: string,
  orderBy: string
}) {
  const params = useParams()
  const organizationId = params.organizationId ?? null

  const [state, setState] = useState<UsersStateInterface>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const users = await fetch(organizationId)

      setState(state => ({
        ...state,
        data: users,
        loading: false,
        error: null,
      }))
    } catch (error) {
      setState(state => ({
        ...state,
        loading: false,
        error,
      }))
    }
  }, [organizationId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const deleteUser = useCallback(async (userId: string) => {
    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      await remove(userId)
      setState(state => ({
        data: state.data!.filter((obj: { uuid: string }) => obj.uuid !== userId),
        loading: false,
        error: null,
      }))
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [])

  // Note: Permissions are only applied to BASE role, so we can order
  // by role here.
  const orderProp = orderBy === 'permissions' ? 'role' : orderBy
  const offset = page * itemsPerPage
  const limit = offset + itemsPerPage

  const list = toSorted(
    state.data ?? [],
    function compareFunction(userA: any, userB: any) { // TODO - Change when user model will be implemented
      const valueA = getObjectValueByPath(userA, orderProp)
      const valueB = getObjectValueByPath(userB, orderProp)

      if (valueA === valueB) return 0

      const compareOrderFn = order === 'asc' ? asc : desc
      return compareOrderFn(valueA, valueB)
    },
  ).slice(offset, limit)

  return {
    ...state,
    totalCount: (state.data ?? []).length,
    list,
    refetch: fetchData,
    delete: deleteUser,
  }
}

/**
 * Immutable array sort function.
 * @template T
 * @param {T[]} array Array to sort
 * @param {(a: T, b: T) => 0 | 1 | -1} compareFn Compare function to sort the array
 * @returns {T[]}
 */
function toSorted(array: any, compareFn: ((a: any, b: any) => number) | undefined) {
  return [...array].sort(compareFn)
}

function asc(valueA: string, valueB: string) {
  return valueA > valueB ? 1 : -1
}

function desc(valueA: string, valueB: string) {
  return valueA < valueB ? 1 : -1
}

/**
 * Returns deep value of an object by path. Works only with subobjects, not arrays.
 * @param {object} object
 * @param {string} path
 * @returns {unknown}
 * @example
 * const object = {
 *  organization: { name: 'Inditex' }
 * }
 * getObjectValueByPath(object, 'organization.name') // Inditex
 */
function getObjectValueByPath(object: any, path: string) {
  let parts = path.split('.')
  let value = object

  if (parts.length === 1) return value[path]

  while (parts.length !== 0) {
    if (value?.[parts?.[0]] === undefined) {
      return undefined
    }

    value = value[parts[0]]

    if (value === undefined) {
      return undefined
    }

    parts.shift()
  }

  return value
}
