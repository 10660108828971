import { useEffect, useState, useCallback } from 'react'

import { getActiveCampaignInfo } from './campaignsService'
import RegisterCampaignModel from './model/RegisterCampaignModel'

type UseRegisterCampaignStateType = {
  data: RegisterCampaignModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useRegisterCampaign(
  mac: string,
  network: number,
): object {
  const [state, setState] = useState<UseRegisterCampaignStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await getActiveCampaignInfo(mac, network)

      setState({
        data: response.data,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [mac, network])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useRegisterCampaign
