import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  CardContent,
  Grid,
} from '@mui/material'
import { useLocales } from '../../locales'
import CardLoadingSkeleton from '../skeleton/CardLoadingSkeleton/CardLoadingSkeleton'
import style from './PermissionsCardStyle'
import { PermissionsType } from '../../services/permissions/types/PermissionType'

export default function PermissionsCard({
  cardTitle,
  permissions,
  loading,
  error,
  onBlur,
  onChangePermissions,
}: {
  cardTitle?: string
  permissions: PermissionsType[]
  error?: string
  onBlur?: () => void
  loading?: boolean
  onChangePermissions: (value: string, checked: boolean) => void
}) {
  const { translate } = useLocales()

  const renderPermissions = () => {
    return permissions.map((permission, index) => (
      <FormControlLabel
        key={`${index}`}
        control={
          <Switch
            id={`${permission.value}`}
            value={permission.value}
            checked={permission.checked}
            disabled={permission.disabled}
            onChange={(_, status) => {
              onChangePermissions(permission.value, status)
            }}
            onBlur={onBlur}
          />
        }
        labelPlacement="start"
        label={permission.label}
      />
    ))
  }

  return (
    <Grid item xs={12}>
      {loading ? (
        <CardLoadingSkeleton
          title={`${translate('account-permissions:page:title')}`}
          icon={null}
        />
      ) : (
        <CardContent sx={style.container} style={{ padding: 0 }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="subtitle2" color="text.primary">
              {`${translate(cardTitle)}`}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              sx={error ? style.boxWrapperError : style.boxWrapper}
            >
              {renderPermissions()}
            </Box>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </Box>
        </CardContent>
      )}
    </Grid>
  )
}
