import { useState } from 'react'
import { MenuItem, Stack, Theme, Typography, alpha } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButtonAnimate } from '../../../../components/animate'
import MenuPopover from '../../../../components/menu-popover/MenuPopover'
import Iconify from '../../../../components/iconify'
import { useLocales } from '../../../../locales'
import style from './ActionsPopoverStyle'

export default function ActionPopover({
  handleDelete,
  handleEdit,
  handlePause,
  handleRestart,
  goToView,
}: {
  handleDelete?: () => void
  handleEdit?: () => void
  handlePause?: () => void
  handleRestart?: () => void
  goToView?: () => void
}) {
  const [openPopover, setOpenPopover] = useState<EventTarget | null>(null)
  const { translate } = useLocales()

  const IconComponent: any = Iconify
  const IconButtonAnimateComponent: any = IconButtonAnimate

  const handleOpenPopover = (event: Event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButtonAnimateComponent
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...((openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: Theme) => alpha(theme.palette.grey[700], 0.1),
            },
          }) as any),
        }}
      >
        <MoreVertIcon sx={style.moreVertIcon} />
      </IconButtonAnimateComponent>

      <MenuPopover
        disabledArrow={true}
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-center"
        sx={style.menuPopover}
      >
        {handleEdit !== undefined ? (
          <MenuItem sx={style.menuItem} onClick={handleEdit}>
            <IconComponent icon="solar:pen-bold" color={'other.facebook'} />
            <Typography>{`${translate('action:edit')}`}</Typography>
          </MenuItem>
        ) : null}

        {goToView !== undefined ? (
          <MenuItem sx={style.menuItem} onClick={goToView}>
            <IconComponent icon="solar:eye-bold" color={'other.facebook'} />
            <Typography>{`${translate('action:view')}`}</Typography>
          </MenuItem>
        ) : null}

        {handleDelete !== undefined ? (
          <MenuItem sx={style.menuItem} onClick={handleDelete}>
            <IconComponent
              icon="solar:trash-bin-trash-bold"
              color={'other.facebook'}
            />
            <Typography>{`${translate('action:delete')}`}</Typography>
          </MenuItem>
        ) : null}

        {handlePause !== undefined ? (
          <MenuItem sx={style.menuItem} onClick={handlePause}>
            <IconComponent icon="solar:pause-bold" color={'other.facebook'} />
            <Typography>{`${translate('action:pause')}`}</Typography>
          </MenuItem>
        ) : null}

        {handleRestart !== undefined ? (
          <MenuItem sx={style.menuItem} onClick={handleRestart}>
            <IconComponent icon="solar:restart-bold" color={'other.facebook'} />
            <Typography>{`${translate('action:restart')}`}</Typography>
          </MenuItem>
        ) : null}
      </MenuPopover>
    </Stack>
  )
}
