import Chart from 'react-apexcharts';

export default function DonutChart({
  data,
  height,
}: {
  data: {color: string, value: number, label: string}[];
  height?: number;
}) {
  const options = {
    chart: {
      type: 'donut',
    },
    labels: data.map((serie) => serie.label),
    colors: data.map((serie) => serie.color),
    legend: {
      show: true,
      position: 'bottom',
      formatter: (seriesName: any, opts: any) => {
        const seriesIndex = opts.seriesIndex
        const value = opts.w.globals.series[seriesIndex]
        return `${seriesName} - ${value}`
      },
      markers: {
        shape: 'square',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              fontWeight: 800,
            },
            total: {
              color: '#84909b',
              fontWeight: 800,
              fontSize: '12px',
              showAlways: true,
              show: true
            }
          }
        }
      }
    },
  };

  return (
    <>
      {/* @ts-ignore */}
      <Chart options={options} series={data.map((serie) => serie.value)} type="donut" height={height} />
    </>
  );
}
