import { generateUUIDV5 } from './../../utils/uuidGenerator';
import RegisterCampaignFields from "./enum/registerCampaignFields"
import RegisterCampaignRegisterPayloadModel from "./model/RegisterCampaignRegisterPayloadModel"

export function adaptFormValuesToPayload(foreignId: string, formValues: RegisterCampaignFields) {
  return new RegisterCampaignRegisterPayloadModel(
    generateUUIDV5(foreignId),
    foreignId,
    formValues.phone,
    formValues.email,
    formValues.gender,
    formValues.zipcode,
    formValues.birthdate,
  )
}
