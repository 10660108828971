const actionsPopoverStyle = {
  moreVertIcon: {
    color: 'action.active'
  },
  menuPopover: {
    width: 200,
    p: 0
  },
  menuItem: {
    m: 1,
  }
}

export default actionsPopoverStyle
