import { TrafficZonesInterface, TrafficZonesVisitInterface } from "../interface/TrafficZonesInterface";

export default class TrafficZonesModel implements TrafficZonesInterface {
  constructor(
    readonly zoneIndex: number,
    readonly zoneName: string,
    readonly currentZoneVisits: TrafficZonesVisitInterface[],
    readonly previousZoneVisits: TrafficZonesVisitInterface[],
  ) {}
}
