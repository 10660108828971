import { useEffect, useState, useCallback } from 'react'
import { fetchPublicOrganization } from './service'
import PublicOrganizationModel from './model/PublicOrganizationModel'

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: null,
}

type UserPublicOrganizationStateType = {
  data: PublicOrganizationModel | undefined,
  loading: boolean,
  error: Error | null,
}

function usePublicOrganization(organizationId: string): object {
  const [state, setState] = useState<UserPublicOrganizationStateType>(INITIAL_STATE)

  const fetchData = useCallback(async () => {
    if (organizationId === undefined) {
      setState(INITIAL_STATE)
      return
    }

    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchPublicOrganization(organizationId)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationId])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }


  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    clearError,
    refetch: fetchData,
  }
}

export default usePublicOrganization
