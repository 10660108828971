import { useState } from 'react'
import { useNavigate } from 'react-router'
import style from './LocationFormStyle'

import { useLocales } from '../../../locales'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import {
  Alert,
  Grid,
  Typography,
  Stack,
} from '@mui/material'
import SecondaryButton from '../../../components/button/SecondaryButton/SecondaryButton'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import LocationInfoForm from './LocationInfoForm'
import LocationTaxonomiesForm from './LocationTaxonomiesForm'
import LocationContactInfoForm from './LocationContactInfoForm'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'

export default function LocationForm({ form, organizationId, editing }: {
  form: any,
  organizationId: string,
  editing?: boolean,
}) {
  const navigate = useNavigate()
  const { translate } = useLocales()
  const [openAlert, setOpenAlert] = useState(false)

  const title = editing
    ? `${translate('new-location:title:edit')}`
    : `${translate('new-location-form:title')}`

  return (
    <>
      <Grid container sx={style.container} spacing={3}>

        <Grid item xs={12}>
          <FluxBreadcrumbs currentPath={title} />
          <Typography variant="h4" sx={style.title}>
            {`${title}`}
          </Typography>
        </Grid>

        <LocationInfoForm form={form} />
        <LocationContactInfoForm form={form} />
        <LocationTaxonomiesForm form={form} organizationUUID={organizationId} />

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <SecondaryButton
              onClick={() => {
                navigate(PATH_ACCOUNTS.locations(organizationId))
              }}
            >
              {`${translate('action:cancel')}`}
            </SecondaryButton>
            <PrimaryButton
              onClick={form.submit}
            >
              {editing
                ? (translate('form-label') as unknown as string)
                : (translate('new-location-form:title') as unknown as string)}
            </PrimaryButton>
          </Stack>
        </Grid>

        <Grid item xs="auto" marginLeft="auto" marginTop="48px">
          {openAlert ? (
            <Alert
              onClose={() => {
                setOpenAlert(false)
              }}
              severity="error"
            >
              {editing
                ? translate('form-label') as unknown as string
                : translate('new-location-form:title') as unknown as string}
            </Alert>
          ) : null}
        </Grid>
      </Grid>
    </>
  )
}
