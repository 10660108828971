import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material'

import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table'
import Iconify from '../../../components/iconify'
import DashboardError from '../../../components/dashboard-error/DashboardError'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import ModalConfirmAction from '../../../components/modal/ModalConfirmAction'
import UsersTableRow from '../../../sections/dashboard/users/table/UsersTableRow/UsersTableRow'
import useUsers from '../../../services/users/useUsers'
import { useLocales } from '../../../locales'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import useSnackbarMessageByQueryParams from '../../../hooks/useSnackbarMessageByQueryParams'
import TABLE_HEADER from './UsersTableHeader'
import GridContainer from '../../../components/grid/GridContainer/GridContainer'
import TableLoader from '../../../components/TableLoader/TableLoader'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'

export default function Users() {
  const { organizationId } = useParams()
  const { translate } = useLocales()

  const [snackbarMessage, closeSnackbarMessage] =
    useSnackbarMessageByQueryParams({
      created: 'users:created',
      updated: 'users:updated',
      'permissions-updated': 'users:permissions-updated',
    })

  const navigate = useNavigate()
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'name', defaultOrder: 'asc' })

  const users = useUsers({ order, orderBy, itemsPerPage: rowsPerPage, page })

  const [modalOpened, setModalOpened] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(null)

  const handleOpenModal = () => {
    setModalOpened(true)
  }

  const handleCloseModal = () => {
    setUserIdToDelete(null)
    setModalOpened(false)
  }

  const handleDelete = id => {
    setUserIdToDelete(id)
    handleOpenModal()
  }

  const handleEdit = userId => {
    navigate(
      organizationId !== undefined
        ? PATH_ACCOUNTS.organizationUsersEdit(organizationId, userId)
        : PATH_ACCOUNTS.generalUsersEdit(userId),
    )
  }

  const handleConfirmDelete = () => {
    users.delete(userIdToDelete)
    handleCloseModal()
  }

  if (users.error) {
    return <DashboardError title={translate('users')} />
  }

  return (
    <>
      <Helmet>
        <title>{translate('users')} | So69</title>
      </Helmet>
      <ViewContainer>
        <GridContainer>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{translate('users')}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  navigate(
                    organizationId !== undefined
                      ? PATH_ACCOUNTS.organizationUsersNewUser(organizationId)
                      : PATH_ACCOUNTS.generalUsersNewUser,
                  )
                }}
                startIcon={<Iconify icon="eva:plus-fill" width={16} />}
                sx={{ backgroundColor: 'grey.800' }}
              >
                {translate('users:create')}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <TableContainer>
                <Scrollbar>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEADER}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />

                    <TableBody>
                      {users.loading ? (
                        <TableLoader />
                      ) : (
                        <>
                          {users.list.map(row => (
                            <UsersTableRow
                              key={row.uuid}
                              row={row}
                              selected={selected.includes(row.uuid)}
                              handleDelete={() => handleDelete(row.uuid)}
                              edit={() => handleEdit(row.uuid)}
                            />
                          ))}

                          <TableNoData
                            isNotFound={users.list.length === 0}
                            title={translate('users:table:no-users')}
                          />
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              {users.loading ? null : (
                <TablePaginationCustom
                  count={users?.totalCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              )}
            </Card>
          </Grid>
        </GridContainer>
      </ViewContainer>
      <ModalConfirmAction
        title={translate('users:delete:title')}
        text={translate('users:delete:text')}
        actionText={translate('action:delete')}
        opened={modalOpened}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseModal}
      />
      <SnackbarAlert
        isOpen={snackbarMessage !== null}
        message={snackbarMessage}
        onClose={closeSnackbarMessage}
      />
    </>
  )
}
