import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import ImageDropArea from '../image-drop-area/ImageDropArea'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormGroup,
} from '@mui/material'
import PermissionsCard from '../permissions-card/PermissionsCard'
import CampaignsPermissionsValues from '../../services/common/enum/CampaignsPermissionsValues'
import FormSectionCard from '../card/FormSectionCard/FormSectionCard'
import { useEffect, useState } from 'react'
import genders from '../../services/common/data/genders'
import Stack from '@mui/material/Stack'
import { useLocales } from '../../locales'
import Iconify from '../../components/iconify'
import campaignRegisterFormStyle from './CampaignRegisterFormStyle'
import { isInvalidGenderValidator } from '../../utils/validators'
import { createFileFromUrl, createFileName } from '../../utils/createFile'

export default function CampaignRegisterForm({
  form,
  editing = false,
  completed = false,
}: {
  form: any
  editing?: boolean
  completed?: boolean
}) {
  const { translate } = useLocales()
  const IconifyComponent = Iconify as any

  const [showGenderSector, setShowGenderSector] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    form.handleGenderOptions(value, event.target.checked)
  }

  const isInvalidGender = () => {
    return isInvalidGenderValidator(
      form.values.permissions.gender,
      form.values.genderOptions?.length ?? 0,
    )
  }

  useEffect(() => {
    async function loadHeaderImage() {

      if (!form.values.headerImage || file || !editing) {
        return
      }

      const downloadedFile = await createFileFromUrl(
        form.values.headerImage,
        createFileName('headerImagen'),
      )

      if (downloadedFile) {
        setFile(downloadedFile)
      }
    }

    loadHeaderImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.headerImage, form.values.image])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('campaings:create:register_section:title')}`}
          subtitle={`${translate(
            'campaings:create:register_section:subtitle',
          )}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormSectionCard>
          <form>
            <FormControl sx={campaignRegisterFormStyle.container}>
              <Typography sx={campaignRegisterFormStyle.title}>
                <>
                  {translate('campaings:create:register_section:header_image')}
                </>
              </Typography>
              <>
                <ImageDropArea
                  hasError={!!form.errors.headerImage}
                  onChange={form.handleHeaderImageChange}
                  onError={form.handleHeaderImageError}
                  fileImage={file!}
                />

                {form.errors.headerImage !== '' ? (
                  <FormHelperText error>
                    {form.errors.headerImage}
                  </FormHelperText>
                ) : null}
              </>
              <PermissionsCard
                cardTitle={`${translate(
                  'campaings:create:register_section:form_fields',
                )}`}
                permissions={[
                  {
                    label: 'Teléfono',
                    value: 'phone',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.PHONE
                      ],
                    disabled: true,
                  },
                  {
                    label: 'Nombre',
                    value: 'name',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.NAME
                      ],
                    disabled: false,
                  },
                  {
                    label: 'Email',
                    value: 'email',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.EMAIL
                      ],
                    disabled: false,
                  },
                  {
                    label: 'Código postal',
                    value: 'zipcode',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.ZIPCODE
                      ],
                    disabled: false,
                  },
                  {
                    label: 'Fecha de nacimento',
                    value: 'birthdate',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.BIRTHDATE
                      ],
                    disabled: false,
                  },
                  {
                    label: 'Género',
                    value: 'gender',
                    checked:
                      form?.values.permissions?.[
                        CampaignsPermissionsValues.GENDER
                      ],
                    disabled: false,
                  },
                ]}
                error={''}
                onChangePermissions={(value: string, checked: boolean) => {
                  if (value === 'gender') {
                    setShowGenderSector(checked)
                  }
                  form.changePermissions({ value, checked })
                }}
              />
              {showGenderSector && (
                <FormGroup sx={campaignRegisterFormStyle.genderContainer}>
                  <>
                    {genders.map(gender => {
                      return (
                        <FormControlLabel
                          key={gender.value}
                          control={
                            <Checkbox
                              checked={
                                form.values.genderOptions.indexOf(
                                  gender.value,
                                ) !== -1
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => handleChange(event, gender.value)}
                            />
                          }
                          label={gender.label}
                        />
                      )
                    })}
                  </>
                </FormGroup>
              )}

              {isInvalidGender() && (
                <Stack
                  direction="row"
                  sx={campaignRegisterFormStyle.stack}
                  color="red"
                >
                  <IconifyComponent icon="eva:info-fill" />
                  <Typography marginLeft="2px">
                    <>{translate('campaigns:create:error_gender')}</>
                  </Typography>
                </Stack>
              )}

              <TextField
                fullWidth
                variant="outlined"
                disabled={completed}
                sx={campaignRegisterFormStyle.field}
                name="textLegalConditions"
                label={`${translate(
                  'campaings:create:register_section:form_field_legal_conditions_text',
                )}`}
                value={form.values.textLegalConditions}
                onChange={form.handleFieldChange}
                error={form.errors.textLegalConditions !== ''}
                helperText={form.errors.textLegalConditions}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                disabled={completed}
                sx={campaignRegisterFormStyle.field}
                name="urlLegalConditions"
                label={`${translate(
                  'campaings:create:register_section:form_field_legal_conditions_url',
                )}`}
                value={form.values.urlLegalConditions}
                onChange={form.handleFieldChange}
                error={form.errors.urlLegalConditions !== ''}
                helperText={form.errors.urlLegalConditions}
              />
              <Typography sx={{ color: 'text.secondary' }}>
                <>
                  {translate(
                    'campaings:create:register_section:form_field_confirmation_message',
                  )}
                </>
              </Typography>

              <TextField
                fullWidth
                multiline
                variant="outlined"
                disabled={editing || completed}
                rows={5}
                sx={campaignRegisterFormStyle.field}
                name="confirmationMessage"
                placeholder={`${translate(
                  'campaings:create:register_section:form_field_confirmation_message_placeholder',
                )}`}
                value={form.values.confirmationMessage}
                onChange={form.handleFieldChange}
                error={form.errors.text !== ''}
                helperText={form.errors.text}
              />
            </FormControl>
          </form>
        </FormSectionCard>
      </Grid>
    </Grid>
  )
}
