import { TableRow, TableCell, Link } from '@mui/material'
import style from './NodesTableRowStyle'
import { CheckCircle, Unpublished } from '@mui/icons-material'
import ActionPopover from '../../users/table/ActionsPopover'

export default function NodesTableRow({
  row,
  selected,
  onClickEdit,
}: {
  row: any,
  selected: boolean,
  onClickEdit: () => void,
}) {
  const { foreignID, organization, location, zone, status } = row

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left">
        <Link
          href={'https://rms.teltonika-networks.com/devices/'+ foreignID}
          target='_blank'
        >
          {foreignID}
        </Link>
      </TableCell>
      <TableCell align="left">{organization}</TableCell>
      <TableCell align="left">{location}</TableCell>
      <TableCell align="left" sx={style.zoneRow}>{zone}</TableCell>
      <TableCell align="center">{status ? <CheckCircle color='primary' /> : <Unpublished color='error' />}</TableCell>
      <TableCell align="right">
        <ActionPopover
          handleEdit={onClickEdit}
        />
      </TableCell>
    </TableRow>
  )
}

