import { Box, Card, Grid, Typography } from '@mui/material'
import KPI_CARD from '../../card/KPI_CARD/KPI_CARD'
import { ZONES } from '../../../utils/zones';
import GridStyle from '../../../style/components/GridStyle';
import style from './AnalyticsKPIsStyle'
import { useLocales } from '../../../locales';
import { calculateIncrementPercentage } from '../../../utils/mathHelper';
import { KPIsModel } from '../../../services/common/model/KPIsModel';
import ForwardIcon from '@mui/icons-material/Forward'

export default function AnalyticsKPIs({
  kpis,
} : {
  kpis: KPIsModel[],
}): JSX.Element {
  const { translate } = useLocales()

  const getKpiCard = (zoneNumber: number, kpis: KPIsModel[], tooltip?: string): JSX.Element => {
    return (
      <Grid item xs={12} md={3.2}>
        <KPI_CARD
          zoneNumber={zoneNumber}
          kpis={kpis}
          tooltip={tooltip}
        />
      </Grid>
    )
  }

  const getVariantBlock = (title: string, variant: string | null): JSX.Element => {
    if (!variant) {
      return (
        <Box sx={style.emptyVariant}/>
      )
    }

    return (
      <Typography variant="body2">
        <b>{title}</b> ({variant})
      </Typography>
    )
  }

  const getVariantContainer = (firstZone: number, secondZone: number,): JSX.Element[] => {
    return kpis.map((kpi: KPIsModel, index: number) => {
      const variant = calculateIncrementPercentage(
        kpi.zones[firstZone]?.current,
        kpi.zones[secondZone]?.current,
      )

      return <Grid item key={index} sx={style.variantItem}>
        {getVariantBlock(`${translate(kpi.shortLabel)}`, variant)}
      </Grid>
    })
  }

  const getPercentBlock = (
    firstZone: number,
    secondZone: number,
  ): JSX.Element => {
    return (
      <Grid item xs={12} md={1.2}>
        <Card sx={[style.percentCard, GridStyle.smallCard]}>
          <Grid container spacing={2} sx={style.gridContainer}>
            <Grid item height={38}>
              <ForwardIcon />
            </Grid>
            {getVariantContainer(firstZone, secondZone)}
          </Grid>
        </Card>
      </Grid>
    )
  }

  const tooltipLoremIpsum: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque gravida nunc quis ante imperdiet, id interdum tellus suscipit.'

  return (
    <>
      {getKpiCard(
        ZONES.ZONE_3.value,
        kpis,
        tooltipLoremIpsum,
      )}
      {getPercentBlock(ZONES.ZONE_2.value, ZONES.ZONE_3.value)}
      {getKpiCard(
        ZONES.ZONE_2.value,
        kpis,
        tooltipLoremIpsum,
      )}
      {getPercentBlock(ZONES.ZONE_CHECKOUT.value, ZONES.ZONE_2.value)}
      {getKpiCard(
        ZONES.ZONE_CHECKOUT.value,
        kpis,
        tooltipLoremIpsum,
      )}
    </>
  )
}
