import i18n from '../../../locales/i18n'

enum GenderEnums {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NO_RESPONSE = 'NO_RESPONSE',
}

const genders = [
  {
    value: GenderEnums.MALE,
    label: `${i18n.t('register:campaign:gender:male')}`,
  },
  {
    value: GenderEnums.FEMALE,
    label: `${i18n.t('register:campaign:gender:female')}`,
  },
  {
    value: GenderEnums.NO_RESPONSE,
    label: `${i18n.t('register:campaign:gender:no:response')}`,
  },
]

export default genders
