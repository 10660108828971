const totalRegisteredVisitFrequencyStyle = {
  container: {
    width: '100%',
    p: 3,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  donutContainer: {
    pt: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chartBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartTitle: {
    textAlign: 'center',
  },
};


export default totalRegisteredVisitFrequencyStyle
