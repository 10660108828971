const getRoundPercentage = (percentageIncrement: number): number => {
  return Math.round(percentageIncrement * 10) / 10
}

const getSignPercentage = (conversion: number): string | null => {
  return conversion > 0 ? `+${conversion}%` : `${conversion}%`
}

export function calculateIncrementPercentage (current: number, previous: number): string | null {
  const increment = current - previous;
  const percentageIncrement = (increment / previous) * 100;
  const roundPercentIncrement = getRoundPercentage(percentageIncrement)

  if (!Boolean(current) || !Boolean(previous) || isNaN(percentageIncrement)) {
    return null
  }


  return getSignPercentage(roundPercentIncrement)
}

export function calculatePercentageConversion (initialValue: number, finalValue: number): string | null {
  const conversion = (finalValue * 100) / initialValue
  const roundConversion = getRoundPercentage(conversion)

  if (!Boolean(initialValue) || !Boolean(finalValue) || isNaN(conversion)) {
    return null
  }

  return getSignPercentage(roundConversion)
}

export const roundKpisProperties = (property: any, decimals: number = 1): any => {
  if (typeof property === "number") {
    return parseFloat(property.toFixed(decimals));
  } 
  
  if (Array.isArray(property)) {
    return property.map(item => roundKpisProperties(item, decimals));
  } 
  
  if (typeof property === "object" && property !== null) {
    const roundedProperty: any = {};
    for (const key in property) {
      roundedProperty[key] = roundKpisProperties(property[key], decimals);
    }
    return roundedProperty;
  }
  return property;
}

export const calculatePercentage = (value: number, decimals: number) => {
  return parseFloat(((value) * 100).toFixed(decimals))
}
