import axios from '../../utils/axios'
import {
  ClientDoesNotExist,
  ForbiddenOrganization,
  HTTPFailureStatusCodes,
} from '../Errors'
import { adaptFormValuesToPayload } from './adapters'
import RegisterCampaignFields from './enum/registerCampaignFields'

import {
  CampaignAlreadyExistError,
  CampaignEndedError,
  CampaignNotBelongOrganizationError,
  CampaignNotExistError,
  CreateCampaignError,
  CustomerAlreadyUnsubscribedError,
  CustomerNotFoundInOrganization,
  InvalidFieldError,
  InvalidUuidError,
  NodeNotFoundError,
  OrganizationNotFoundError,
} from './Error'
import CampaignsAdapter from './adapter/CampaignsAdapter'

const MISSING_PARAMS_ERROR = 'MissingParamsError'
const CUSTOMER_NOT_FOUND_ERROR = 'CustomerNotFound'
const CUSTOMER_ALREADY_UNSUBSCRIBED_ERROR = 'CustomerAlreadyUnsubscribedError'
const CAMPAIGN_ALREADY_EXIST_ERROR = 'CampaignAlreadyExistsError'
const INVALID_FIELD_LENGTH_ERROR = 'InvalidFieldLengthError'
const ORGANIZATION_NOT_FOUND_ERROR = 'OrganizationNotFound' 
const CAMPAIGN_NOT_EXIST_ERROR = 'CampaignNotExistsError'
const NODE_NOT_FOUND_ERROR = 'NodeNotFound'
const CAMPAIGN_ENDED_ERROR = 'CampaignEndedError'
const CAMPAIGN_NOT_BELONG_ORGANIZATION_ERROR = 'CampaignNotBelongOrganizationError'

const manageErrors = (error: any, url: string): Error => {
  const errorMessage = error.response.data.name

  if (errorMessage === CAMPAIGN_ALREADY_EXIST_ERROR) {
    throw new CampaignAlreadyExistError(url)
  }

  if (errorMessage === INVALID_FIELD_LENGTH_ERROR) {
    throw new InvalidFieldError(url)
  }

  if (errorMessage === ORGANIZATION_NOT_FOUND_ERROR) {
    throw new OrganizationNotFoundError(url)
  }

  if (errorMessage === CAMPAIGN_NOT_EXIST_ERROR) {
    throw new CampaignNotExistError(url)
  }

  if (errorMessage === NODE_NOT_FOUND_ERROR) {
    throw new NodeNotFoundError(url)
  }

  if (errorMessage === CAMPAIGN_ENDED_ERROR) {
    throw new CampaignEndedError(error)
  }

  if (errorMessage === CAMPAIGN_NOT_BELONG_ORGANIZATION_ERROR) {
    throw new CampaignNotBelongOrganizationError(url)
  }

  throw new CreateCampaignError(error)
}

export async function createCampaign(data: any, organizationId: string) {
  const url = `/api/v1/organizations/${organizationId}/campaigns`
  try {
    const body = CampaignsAdapter.adaptCreateCampaignToPayload(data)

    const response = await axios.post(
      url,
      body,
    )

    return response.data
  } catch (error) {
    manageErrors(error, url)
  }
}

export async function updateCampaign(
  organizationId: string,
  campaignId: string,
  payload: object,
) {
  const url = `/api/v1/organizations/${organizationId}/campaigns/${campaignId}`
  try {
    const response = await axios.patch(url, payload)
    return response.data
  } catch (error) {
    manageErrors(error, url)
  }
}

export async function fetchOne(organizationId: string, campaignId: string) {
  try {
    const response = await axios.get(
      `/api/v1/organizations/${organizationId}/campaigns/${campaignId}`,
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}

export async function fetch(organizationId: string) {
  try {
    const endpoint = `/api/v1/organizations/${organizationId}/campaigns`
    const response = await axios.get(endpoint)

    return response.data
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}
export async function getActiveCampaignInfo(mac: string, network: number) {
  try {
    const response = await axios.get(
      `/api/v1/campaigns/mac:${mac}/networks/${network}`,
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}

export async function campaignRegister(
  campaignId: string,
  organizationId: string,
  foreignId: string,
  fields: RegisterCampaignFields,
) {
  try {
    const response = await axios.post(
      `/api/v1/organizations/${organizationId}/campaigns/${campaignId}/customers`,
      adaptFormValuesToPayload(foreignId, fields),
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}

export async function fetchPublicCampaign(
  organizationId: string,
  campaignId: string,
) {
  const url = `/api/v1/organizations/${organizationId}/campaigns/${campaignId}`
  try {
    const response = await axios.get(url)

    return response
  } catch (error) {
    switch (error.response.data.name) {
      case MISSING_PARAMS_ERROR:
        throw new InvalidUuidError(url)
      case CUSTOMER_NOT_FOUND_ERROR:
        throw new CustomerNotFoundInOrganization(url)
      case CUSTOMER_ALREADY_UNSUBSCRIBED_ERROR:
        throw new CustomerAlreadyUnsubscribedError(url)
      default:
        throw error
    }
  }
}

export async function unsubscribe(organizationId: string, customerId: string) {
  const url = `/api/v1/organizations/${organizationId}/customers/${customerId}/unsubscribe`
  try {
    const response = await axios.put(url)

    return response
  } catch (error) {
    switch (error.response.data.name) {
      case MISSING_PARAMS_ERROR:
        throw new InvalidUuidError(url)
      case CUSTOMER_NOT_FOUND_ERROR:
        throw new CustomerNotFoundInOrganization(url)
      case CUSTOMER_ALREADY_UNSUBSCRIBED_ERROR:
        throw new CustomerAlreadyUnsubscribedError(url)
      default:
        throw error
    }
  }
}

export async function switchCampaignState(organizationId: string, campaignId: string, paused: boolean) {
  const url = `/api/v1/organizations/${organizationId}/campaigns/${campaignId}`
  try {
    const response = await axios.patch(url, {
      paused: paused
    })
    return response.data
  } catch (error) {
    throw new Error()
  }
}
