export class InvalidNewOrganizationPayloadError extends Error {
  constructor(path: string) {
    super(`Error creating new organization from endponit ${path}`)
    this.name = 'InvalidNewOrganizationPayloadError'
  }
}

export class InvalidUpdateOrganizationPayloadError extends Error {
  constructor(endpoint: string) {
    super(`Error updating organization from endponit ${endpoint}`)
    this.name = 'InvalidUpdateOrganizationPayloadError'
  }
}

export class CustomerAlreadyUnsubscribedError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error unsubscribe customer from endpoint ${endpoint}`)
    this.name = 'CustomerAlreadyUnsubscribedError'
  }
}

export class CustomerNotFound extends Error {
  constructor(readonly endpoint: string) {
    super(`Error unsubscribe customer from endpoint ${endpoint}`)
    this.name = 'CustomerNotFound'
  }
}
