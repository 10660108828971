import { useTheme } from '@mui/material'
import { useLocales } from '../../locales'
import HorizontalBarChartCard from '../card/Chart/HorizontalBarChartCard/HorizontalBarChartCard'
import AnonymousFrequencyModel from '../../services/anonymous/model/AnonymousFrequencyModel'

export default function AnonymousTotalVisitsFrequencyGraph({
  frequencyData,
}: {
  frequencyData: AnonymousFrequencyModel | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const title = `${translate('analytics:kpis:chart:frequency:title')}`
  const tooltip = `${translate('analytics:kpis:chart:frequency:tooltip')}`

  const series = [
    {
      data: frequencyData?.one || 0,
      name: `${translate('anonymous:kpis:chart:frequency:one-visit')}`,
      color: theme.palette.chart.light.secondZone,
    },
    {
      data: frequencyData?.two || 0,
      name: `${translate('anonymous:kpis:chart:frequency:two-visits')}`,
      color: theme.palette.chart.light.firstZone,
    },
    {
      data: frequencyData?.three_plus || 0,
      name: `${translate(
        'anonymous:kpis:chart:frequency:three-or-more-visits',
      )}`,
      color: theme.palette.chart.light.cashRegisters,
    },
  ]

  return (
    <HorizontalBarChartCard
      title={title}
      tooltip={tooltip}
      maxValue={frequencyData?.getTotalVisits()}
      series={series}
      isDataEmpty={series.every(item => item.data === 0)}
    />
  )
}
