import { useNavigate } from 'react-router'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useLocales } from '../../../locales'
import { PATH_DASHBOARD } from '../../../routes/paths'
import GridContainer from '../../../components/grid/GridContainer/GridContainer'
import SecondaryButton from '../../../components/button/SecondaryButton/SecondaryButton'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import CampaignInfoForm from '../../../components/campaign/CampaignInfoForm'
import CampaignRegisterForm from '../../../components/campaign/CampaignRegisterForm'
import CampaignMessageContentForm from '../../../components/campaign/CampaignMessageContentForm'

export default function CampaignForm({
  form,
  groupId,
  organizationId,
  editing = false,
  completed = false,
}: {
  form: any
  groupId: string | undefined
  organizationId: string | undefined
  editing?: boolean
  completed?: boolean
}) {
  const navigate = useNavigate()
  const { translate } = useLocales()

  const goBackToCampaigns = () => {
    navigate(PATH_DASHBOARD.campaigns(organizationId, groupId))
  }
  
  return (
    <GridContainer>
      <CampaignInfoForm form={form} completed={completed} />
      <CampaignRegisterForm
        form={form}
        editing={editing}
        completed={completed}
      />
      <CampaignMessageContentForm
        form={form}
        editing={editing}
        completed={completed}
      />
      <Grid item xs={12} md={11}>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <SecondaryButton onClick={goBackToCampaigns}>
            {`${translate('action:cancel')}`}
          </SecondaryButton>
          <PrimaryButton onClick={form.handleSubmit}>
            {`${translate(
              completed
                ? 'edit-campaign:back-to-campaigns'
                : editing
                ? 'edit-campaign:save-changes'
                : 'new-campaign:confirm',
            )}`}
          </PrimaryButton>
        </Stack>
      </Grid>
    </GridContainer>
  )
}
