import axios from '../../utils/axios'
import { getFilteredEndpoint } from '../../utils/routeHelper'
import { NoLocationsError } from '../analytics/errors/noLocationsFoundError'
import { UserUnauthorizedError } from '../analytics/errors/UserUnauthorizedError'
import { adaptAnonymousDetectionsResponse } from '../anonymous/anonymousAdapter'
import ApiErrorMessages from '../common/apiErrorMessages'
import DetectionsModel from '../common/model/DetectionsModel'
import DwellTimeModel from '../common/model/DwellTimeModel'
import TrafficZonesModel from '../common/model/TrafficZonesModel'
import { SelectItemType } from '../common/types/SelectItemType'
import RegisteredAttractionRateApiInterface from './interfaces/RegisteredAttractionRateApiInterface'
import RegisteredAverageVisitsApiInterface from './interfaces/RegisteredAverageVisitsApiInterface'
import { RegisteredDetectionsApiInterface } from './interfaces/RegisteredDetectionsApiInterface'
import { RegisteredDwellTimeApiInterface } from './interfaces/RegisteredDwellTimeApiInterface'
import RegisteredRegistrationsApiInterface from './interfaces/RegisteredRegistrationsApiInterface'
import { RegisteredTrafficZonesApiInterface } from './interfaces/RegisteredTrafficZonesApiInterface'
import RegisteredKPIsModel from './model/RegisteredKPIsModel'
import { adaptRegisteredAttractionRateResponse, adaptRegisteredAverageVisits, adaptRegisteredDwellTimeResponse, adaptRegisteredFrequencyResponse, adaptRegisteredKPIsResponse, adaptRegisteredTrafficZonesResponse, adaptRegistrations } from './registeredAdapter'
import { RegisteredAttractionRateGenericError, RegisteredAverageFrequencyVisitsGenericError, RegisteredAverageVisitsGenericError, RegisteredDetectionsGenericError, RegisteredDwellTimeGenericError, RegisteredFrequencyGenericError, RegisteredKpisGenericError, RegisteredRegistrationsGenericError, RegisteredTrafficZonesGenericError } from './registeredError'

enum EndpointType {
  KPIS = 'kpis',
  TRAFFIC = 'zone-traffic',
  REGISTERED_LOYAL_CLIENTS = 'registered-loyal-clients',
  ATTRACTION_RATE = 'attraction-rate',
  DWELL_RATE = 'dwell-rate-gauge',
  HEAT_MAP = 'heatmap',
  VISIT_AVERAGE = 'visit-average',
  VISIT_FREQUENCY_AVERAGE = 'visit-frequency-average',
}

// TODO - Change endpoint to registered endpoint
const getEndpoint = (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
  type: string,
): string => {
  return getFilteredEndpoint(
    `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}/registrations/${type}`,
    locations,
    period,
  )
}

// TODO - Manage errors in all request

export async function fetchRegisteredKPIs (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<RegisteredKPIsModel> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.KPIS,
  )

  try {
    const response = await axios.get(endpoint)

    return adaptRegisteredKPIsResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    if (error.response.data.message === ApiErrorMessages.UserUnauthorizedException) {
      throw new UserUnauthorizedError()
    }

    throw new RegisteredKpisGenericError(endpoint)
  }
}

export async function fetchRegisteredFrequency (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
) {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.REGISTERED_LOYAL_CLIENTS,
  )

  try {
    const response = await axios.get(endpoint)

    return adaptRegisteredFrequencyResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredFrequencyGenericError(endpoint)
  }
}

export async function fetchRegisteredTrafficZones (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<TrafficZonesModel[]> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.TRAFFIC,
  )

  try {
    const response = await axios.get<RegisteredTrafficZonesApiInterface[]>(
      endpoint,
    )

    return adaptRegisteredTrafficZonesResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredTrafficZonesGenericError(endpoint)
  }
}

export async function fetchRegisteredAttractionRate (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
) {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.ATTRACTION_RATE,
  )

  try {
    const response = await axios.get<RegisteredAttractionRateApiInterface>(
      endpoint,
    )

    return adaptRegisteredAttractionRateResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredAttractionRateGenericError(endpoint)
  }
}

export async function fetchRegisteredDwellTime(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<DwellTimeModel> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.DWELL_RATE,
  )

  try {
    const response = await axios.get<RegisteredDwellTimeApiInterface>(endpoint)

    return adaptRegisteredDwellTimeResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredDwellTimeGenericError(endpoint)
  }
}

export async function fetchRegisteredDetections(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<DetectionsModel[]> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.HEAT_MAP,
  )

  try {
    const response = await axios.get<RegisteredDetectionsApiInterface[]>(
      endpoint,
    )

    return adaptAnonymousDetectionsResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredDetectionsGenericError(endpoint)
  }
}

export async function fetchRegisteredAverageVisits (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
) {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.VISIT_AVERAGE,
  )

  try {
    const response = await axios.get<RegisteredAverageVisitsApiInterface>(endpoint)

    return adaptRegisteredAverageVisits(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredAverageVisitsGenericError(endpoint)
  }
}

export async function fetchRegisteredAverageFrequencyVisits(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
) {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.VISIT_FREQUENCY_AVERAGE,
  )

  try {
    const response = await axios.get<RegisteredAverageVisitsApiInterface>(endpoint)

    return adaptRegisteredAverageVisits(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredAverageFrequencyVisitsGenericError(endpoint)
  }
}

export async function fetchRegistrations(
  organizationUUID: string,
  locations: SelectItemType[],
  period: string,
) {
  const endpoint = getFilteredEndpoint(
   `/api/v1/organizations/${organizationUUID}/customers/kpis`,
    locations,
    period,
  )

  try {
    const response = await axios.get<RegisteredRegistrationsApiInterface>(endpoint)

    return adaptRegistrations(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }

    throw new RegisteredRegistrationsGenericError(endpoint)
  }
}
