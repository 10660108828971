import CampaignsNodeModel from "./CampaignsNodeModel";

export class CampaignsDataModel {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly description: string,
    readonly node: CampaignsNodeModel,
    readonly start: string,
    readonly end: string,
    readonly text: string,
    readonly imageUrl: string | null,
    readonly status: string,
    readonly paused: boolean,
  ) { }
}

export class CampaignsModel extends CampaignsDataModel{
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly description: string,
    readonly node: CampaignsNodeModel,
    readonly start: string,
    readonly end: string,
    readonly text: string,
    readonly imageUrl: string | null,
    readonly status: string,
    readonly paused: boolean,
    readonly registrations: number
  ) {
    super(
      uuid,
      name,
      description,
      node,
      start,
      end,
      text,
      imageUrl,
      status,
      paused
    );
  }
}
