import { Box, Card, CardHeader } from "@mui/material";
import style from './ChartCardStyle'
import InfoTooltip from "../../../infoTooltip/InfoTooltip";
import Variant from "../../../variant/Variant";

export default function ChartCard({
  title,
  children,
  variant=undefined,
  tooltip,
  height,
}: {
  title: string,
  children: React.ReactNode,
  variant?: number | undefined,
  tooltip?: string,
  height?: number,
}) {
  const getVariant = (): JSX.Element => {
    if (!variant) {
      return <></>
    }

    return <Variant value={variant} />
  }

  return (
    <Card sx={[style.container, { height: height, minHeight: height} ]}>
      <Box sx={style.headerContainer}>
        <Box sx={style.titleContainer}>
          <CardHeader title={title} sx={style.cardHeader} />
          {tooltip && <InfoTooltip info={tooltip} /> }
        </Box>
        {getVariant()}
      </Box>
      {children}
    </Card>
  )
}
