import { Suspense, lazy } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')))
export const SendEmailPage = Loadable(
  lazy(() => import('../pages/reset-password/SendEmail')),
)
export const ConfirmPasswordPage = Loadable(
  lazy(() => import('../pages/reset-password/ConfirmPassword')),
)

// GENERAL
export const Organizations = Loadable(
  lazy(() => import('../pages/general/Organizations/Organizations')),
)
export const Users = Loadable(
  lazy(() => import('../pages/general/Users/Users')),
)

// DASHBOARD
export const Analytics = Loadable(
  lazy(() => import('../pages/dashboard/Analytics')),
)
export const Campaigns = Loadable(
  lazy(() => import('../pages/Campaigns/Campaigns/Campaigns')),
)
export const NewCampaign = Loadable(
  lazy(() => import('../pages/dashboard/NewCampaign')),
)
export const Connect = Loadable(
  lazy(() => import('../pages/dashboard/Connect')),
)
export const RealTime = Loadable(
  lazy(() => import('../pages/dashboard/RealTime')),
)
export const Registers = Loadable(
  lazy(() => import('../pages/dashboard/Registers')),
)

// 404
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))
