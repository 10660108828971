const es = {
  demo: {
    title: `Español`,
    introduction: `Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de la industria desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen.`,
  },
  docs: {
    hi: `Hola`,
    description: `¿Necesitas ayuda? \n Por favor, revisa nuestra documentación.`,
    documentation: `documentación`,
  },
  avatar: {
    home: `Inicio`,
    logout: `Cerrar sesión`,
    profile: `Perfil`,
    settings: `Configuración`,
  },
  'list-dashboards': 'Listar cuentas por usuario',
  accounts: `cuentas`,
  app: `app`,
  clients: `Organizaciones`,
  'table:rows-per-page': 'Filas por página:',
  users: `Usuarios`,
  'users:create': 'Nuevo usuario',
  'users:edit': 'Editar usuario',
  'users:table:no-users': 'No hay usuarios',
  'users:table:heading:name': 'Nombre',
  'users:table:heading:role': 'Rol',
  'users:table:heading:organization': 'Organización',
  'users:table:heading:permissions': 'Permisos',
  'users:delete:title': '¿Estás seguro de que quieres eliminar este usuario?',
  'users:delete:text':
    'El usuario seleccionado será eliminado permanentemente.',
  'users:permissions-updated':
    'Los permisos de usuario se han actualizado con éxito',
  'users:created': 'El usuario se ha creado exitosamente',
  'users:updated': 'El usuario se ha actualizado exitosamente',
  list: `lista`,
  edit: `editar`,
  shop: `tienda`,
  blog: `blog`,
  post: `publicación`,
  mail: `correo electrónico`,
  chat: `chat`,
  cards: `tarjetas`,
  posts: `publicaciones`,
  create: `crear`,
  kanban: `kanban`,
  general: `general`,
  banking: `banca`,
  booking: `reserva`,
  profile: `perfil`,
  account: `cuenta`,
  product: `producto`,
  invoice: `factura`,
  details: `detalles`,
  checkout: `pagar`,
  calendar: `calendario`,
  analytics: `analíticas`,
  ecommerce: `comercio electrónico`,
  management: `gestión`,
  menu_level: `nivel de menú`,
  menu_level_2a: `nivel de menú 2a`,
  menu_level_2b: `nivel de menú 2b`,
  menu_level_3a: `nivel de menú 3a`,
  menu_level_3b: `nivel de menú 3b`,
  menu_level_4a: `nivel de menú 4a`,
  menu_level_4b: `nivel de menú 4b`,
  item_disabled: `elemento deshabilitado`,
  item_label: `etiqueta de elemento`,
  item_caption: `leyenda de elemento`,
  item_external_link: `enlace externo de elemento`,
  description: `descripción`,
  other_cases: `otros casos`,
  item_by_roles: `elemento por roles`,
  only_admin_can_see_this_item: `Solo el administrador puede ver este elemento`,
  campaigns: 'Campañas',
  stores: 'Tiendas',
  delete: 'Eliminar',
  'locations:title': 'Localizaciones',
  // Campaigns
  'campaigns:status:live': 'En curso',
  'campaigns:status:paused': 'Pausadas',
  'campaigns:status:programmed': 'Programadas',
  'campaigns:status:completed': 'Completadas',
  'campaigns:table:name': 'Nombre',
  'campaigns:table:description': 'Descripción',
  'campaigns:table:start-date': 'Fecha de inicio',
  'campaigns:table:end-date': 'Fecha de finalización',
  'campaigns:table:registered-users': 'Nº de registros',
  'campaigns:table:status': 'Estado',
  'campaigns:successful-creation': 'La campaña se ha creado exitosamente',
  'campaign:status:paused': 'Pausada',
  'campaign:status:live': 'En curso',
  'campaign:status:programmed': 'Programada',
  'campaign:status:completed': 'Completada',
  'campaign:pause': 'Pausar campaña',
  'campaign:activate': 'Activar campaña',
  'campaigns:title': 'Campañas de captación',
  'campaigns:loyalty:title': 'Campañas de fidelización',
  'campaigns:create-button': 'Crear Campaña',
  'campaigns:delete:title': 'Eliminar Campaña',
  'campaigns:delete:text': '¿Estás seguro de querer eliminar la campaña?',
  'campaigns:created': 'La campaña se ha creado exitosamente',
  'campaigns:updated': 'La campaña se ha actualizado exitosamente',
  'campaigns:ended':
    'La campaña no se ha podido actualizar porque ya ha finalizado',
  'campaigns:create:caption':
    'Solamente se puede programar una campaña para un mismo periodo de fechas.',
  'campaings:create:information_section:title': 'Información de la campaña',
  'campaings:create:information_section:subtitle': 'Propiedades de la campaña',
  'campaings:create:register_section:title': 'Formulario de registro',
  'campaings:create:register_section:subtitle':
    'Configuración del formulario de registro',
  'campaings:create:register_section:header_image': 'Imagen de cabecera',
  'campaings:create:register_section:form_fields': 'Campos del formulario',
  'campaings:create:register_section:form_field_legal_conditions_text':
    'Texto condiciones legales',
  'campaings:create:register_section:form_field_legal_conditions_url':
    'URL condiciones legales',
  'campaings:create:register_section:form_field_confirmation_message':
    'Mensaje de confirmación',
  'campaings:create:register_section:form_field_confirmation_message_placeholder':
    'Escribe algo destacado',
  'campaings:create:register_section:message_content_title':
    'Contenido del mensaje',
  'campaings:create:register_section:message_content_subtitle':
    'Contenido del mensaje',
  'campaigns:loyalty:send:messages:count': 'Nº mensajes',
  'campaigns:create:error_gender': 'Mínimo tiene que seleccionar un valor.',
  'campaigns:add:time:slot': 'Añadir franja horaria',
  'campaigns:remove:time:slot': 'Eliminar',
  'campaigns:time:slot:error':
    'La hora de finalización debe ser superior a la de inicio.',
  'campaigns:schedule': 'Horario',
  'edit-campaign:back-to-campaigns': 'Volver a campañas',
  'edit-campaign:save-changes': 'Guardar cambios',
  'edit-campaign:title': 'Editar campaña',
  'new-campaign:title': 'Crear Campaña',
  'new-campaign:confirm': 'Crear Campaña',
  'new-campaign:details-title': 'Detalles de campaña',
  'new-campaign:details-description': 'Propiedades de la campaña',
  'new-campaign:content-title': 'Contenido de campaña',
  'new-campaign:content-description': 'Estructura del mensaje',
  'new-campaign:form-name-label': 'Nombre',
  'new-campaign:form-location': 'Localización',
  'new-campaign:form-node': 'Nodo',
  'new-campaign:form-description-label': 'Descripción',
  'new-campaign:form-start-label': 'Fecha inicio',
  'new-campaign:form-end-label': 'Fecha fin',
  'new-campaign:form-type-label': 'Tipo de Campaña',
  'new-campaign:form-channel-label': 'Canal de Campaña',
  'new-campaign:form-text-label': 'Mensaje',
  'new-campaign:form-text-placeholder': 'Escribe algo destacado...',
  'new-campaign:form-image-label': 'Imagen',
  'new-campaign:form-image-drop-text': 'Arrastra y suelta aquí o ',
  'new-campaign:form-image-drop-button': 'elige una imagen',
  'new-campaign:form-image-drop-restrictions':
    'Formato admitido: JPEG, PNG. Tamaño máximo: 5MB',
  'new-campaign:form-error-missing-field':
    'Por favor, completa la información requerida',
  'new-campaign:form-error-missing-image': 'Por favor, carga una imagen',
  'new-campaign:form-error-image-failed':
    'Hubo un fallo al cargar la imagen. Por favor, asegúrate de que el formato y el tamaño sean correctos.',
  'new-campaign:form-error-invalid-past-date':
    'No es posible asignar una fecha pasada',
  'new-campaign:form-error-invalid-date-range': 'Rango de fecha inválido',
  'new-campaign:form-error-name-too-long':
    'El nombre no puede ser superior a 50 caracteres',
  'new-campaign:form-error-description-too-long':
    'La descripción no puede ser superior a 50 caracteres',
  'new-campaign:error-dates-already-active':
    'Ya existe una campaña activa para la misma fecha. Por favor, selecciona otra',
  'new-campaign:submitting-form-info-message': 'Creando campaña...',
  'new-campaign:submit-error-message':
    'Ha ocurrido un error en la creación de campaña. Por favor, inténtalo más tarde',
  'campaign-type:registration': 'Campaña de registro',
  'campaign:error:campaign:not:exist':
    'Ha ocurrido un error, la campaña no se encuentra en la organización',
  'campaign:error:organization:not:found':
    'Ha ocurrido un error, la organizacion no se ha encontrado',
  'campaign:error:node:not:found':
    'Ha ocurrido un error, no se encuentra uno de los nodos seleccionados',
  'campaign:error:campaign:not:belong:organization':
    'Ha ocurrido un error, os nodos asociados a esta campaña no se encuentran en las localizaciones de la organización',
  'campaign:generic:error':
    'Ha ocurrido un error, inténtalo de nuevo mas tarde',
  // New user
  'new-user:title': 'Crear usuario',
  'new-user:submitting-form-info-message': 'Creando usuario...',
  'new-user:form:organization-label': 'Organización',
  'new-user:form:organization-label:loading': 'Cargando organizaciones...',
  'new-user:form:name-label': 'Nombre',
  'new-user:form:lastname-label': 'Apellidos',
  'new-user:form:email-label': 'Correo electrónico',
  'new-user:form:password-label': 'Contraseña',
  'new-user:form:password-helper':
    'Asegúrate de copiar esta contraseña antes de continuar',
  'new-user:form:role-label': 'Rol',
  'new-user:form:submit-label': 'Crear usuario',
  'new-user:form:error-missing-field':
    'Por favor, completa la información requerida',
  'new-user:form:permission:anonymous-module': 'Módulo de anónimos',
  'new-user:form:permission:captation-permission': 'Campañas de captación',
  'new-user:form:permission:registered-module': 'Módulo registrados',
  'new-user:groups-label': 'Agrupaciones',
  'edit-user:title': 'Editar usuario',
  'edit-user:loading': 'Cargando datos...',
  'edit-user:updating': 'Actualizando usuario...',
  'edit-user:form:organization-label': 'Organización',
  'edit-user:form:organization-label:loading': 'Cargando organizaciones...',
  'edit-user:form:brands-label:select-organization': 'Organización',
  'edit-user:form:brands-label': 'Marcas',
  'edit-user:form:brands-label:loading': 'Cargando marcas...',
  'edit-user:form:error-missing-field':
    'Por favor, completa la información requerida',
  'edit-user:form:error-stores-not-informed':
    'Por favor, asegúrate de que todas las marcas tienen asociadas al menos una tienda',
  'edit-user:form:error-invalid-email': 'Por favor, introduce un email válido',
  'edit-user:form:error-invalid-permissions':
    'Debes seleccionar al menos un permiso',
  'edit-user:form:submit-label': 'Guardar cambios',
  'campaign-channel:whatsapp': 'WhatsApp',
  'action:apply': 'Aplicar',
  'action:cancel': 'Cancelar',
  'action:delete': 'Borrar',
  'action:edit': 'Editar',
  'action:pause': 'Pausar',
  'action:restart': 'Iniciar de nuevo',
  'analytics:title': 'Analíticas',
  'in-store:granularity': 'Usuarios divididos por {{granularity}}',
  granularity: {
    day: 'día',
    week: 'semana',
    month: 'mes',
    quarter: 'trimestre',
    year: 'año',
  },
  // Auth
  'auth:credentials:error': 'El email o la contraseña son incorrectos',
  'login:title': 'Inicia sesión en Summer of 69',
  'login:form:forgot-password': '¿Has olvidado tu contraseña?',
  'login:form:submit-label': 'Iniciar sesión',
  'login:form:email:label': 'Correo electrónico',
  'login:form:email:error:required': 'El correo electrónico es obligatorio',
  'login:form:email:error:invalid': 'Debe ser una dirección válida',
  'login:form:password:label': 'Contraseña',
  'login:form:password:error:required': 'La contraseña es obligatoria',
  'reset-password:page:title': 'Restablece tu contraseña',
  'reset-password:title': '¿Has olvidado tu contraseña?',
  'reset-password:description':
    'Por favor, ingresa el correo electrónico asociado a tu cuenta. Te enviaremos un email con instrucciones para que puedas restablecer tu contraseña.',
  'reset-password:input:label': 'Correo electrónico',
  'reset-password:confirmation:title': 'Revisa tu correo electrónico',
  'reset-password:confirmation:description':
    'Hemos enviado un correo a {{email}} con las instrucciones para restablecer tu contraseña. Si no ves el correo en tu bandeja de entrada, revisa tu bandeja de spam.',
  'reset-password:action:send': 'Enviar solicitud',
  'reset-password:action:return': 'Volver a iniciar sesión',
  'reset-password:error:email-required':
    'Por favor, introduce tu correo electrónico',
  'confirm-password:page:title': 'Restablece tu nueva contraseña',
  'confirm-password:title': 'Restablece tu nueva contraseña',
  'confirm-password:description':
    '¡Ya casi estamos! Crea tu nueva contraseña con un mínimo de 8 caracteres',
  'confirm-password:input:label': 'Nueva contraseña',
  'confirm-password:confirmed-input:label': 'Confirmar nueva contraseña',
  'confirm-password:confirmation:title': 'Tu nueva contraseña está lista',
  'confirm-password:confirmation:description':
    'Tu contraseña se ha restablecido con éxito. Por favor, haz clic a continuación para iniciar sesión',
  'confirm-password:action:send': 'Restablecer contraseña',
  'confirm-password:action:return': 'Volver a iniciar sesión',
  'navigation-welcome': 'Bienvenido de nuevo, {{user}} ',
  'navigation-button': 'Backoffice',
  'navigation-button-brands': 'Marcas',
  'confirm-password:error:password-required':
    'Se requiere una nueva contraseña', //REVISAR
  'confirm-password:error:password-length':
    'Tu contraseña debe contener al menos 8 caracteres',
  'confirm-password:error:confirmed-password-required':
    'Se requiere confirmar la nueva contraseña', //REVISAR
  'confirm-password:error:password-match':
    'Las contraseñas no coinciden. Por favor, inténtalo de nuevo',
  'account-permissions:page:title': 'Permisos',
  'account-permissions': 'Permisos de cuenta',
  'permission-analytics.read': 'Ver sección de Analíticas', //REVISAR
  'permission-connect.read': 'Ver sección de Conectividad',
  'permission-stores.read': 'Ver sección de Tiendas',
  'permission-registers.read': 'Ver sección de Registros',
  'permission-campaign.read': 'Ver sección de Campañas',
  'permission-realtime.read': 'Ver sección de Tiempo real',
  'permission-application.read-economic-data': 'Ver datos económicos',
  'permission-campaign.write': 'Gestionar campañas',
  'new-organization:title': 'Crear organización',
  'new-organization:title:edit': 'Editar organización',
  'new-organization:details-title': 'Detalles de organización',
  'new-organization:details-description': 'Información sobre la organización',
  'new-organization:submitting-form-info-message': 'Creado organización...',
  'new-organization:submit-error-message':
    'Hubo un error en la creación de organización. Por favor, inténtalo de nuevo',
  'new-organization:form:name-label': 'Nombre',
  'new-organization:form:description-label': 'Descripción',
  'new-organization:form:industry-label': 'Sector',
  'new-organization:content-title': 'Contacto de organización',
  'new-organization:content-description':
    'Persona de contacto de la organización',
  'new-organization:contact-form:name-label': 'Nombre',
  'new-organization:contact-form:surname-label': 'Apellidos',
  'new-organization:contact-form:prefix-label': 'Prefijo',
  'new-organization:contact-form:phone-label': 'Número de teléfono',
  'new-organization:contact-form:email-label': 'Correo electrónico',
  'new-organization:submit-label': 'Crear Organización',
  'new-organization:submit-label:edit': 'Guardar cambios',
  'organizations:create': 'Nueva organización',
  'organizations:created': 'La organización se ha creado con éxito',
  'organizations:updated': 'La organización se ha actualizado con éxito',
  'organizations:empty-content:title': 'Aún no hay organizaciones',
  'organizations:empty-content:description':
    'Puedes empezar por crear una organización. Todas las organizaciones serán listadas en esta página.',
  'edit-organization:submitting-form-info-message':
    'Actualizando organización...',
  'edit-organization:submit-error-message':
    'Hubo un error en la actualización de organización. Por favor, inténtalo de nuevo',
  'new-brand:form:error-missing-field':
    'Por favor, completa la información requerida',
  'edit-brand:successful-creation': 'La marca se ha actualizado correctamente',
  'brands:empty-content:title': 'Aún no hay marcas',
  'brands:empty-content:description':
    'Puedes empezar por crear una marca para esta organización. Todas las marcas serán listadas en esta página.',
  'brands:create': 'Nueva marca',
  'brands:created': 'La marca se ha creado con éxito',
  'brands:updated': 'La marca se ha actualizado con éxito',
  //Locations
  'new-location-form:title': 'Crear localización',
  'new-location:title:edit': 'Editar localización',
  'new-location:form:error-missing-field':
    'Por favor, proporciona la información requerida',
  'new-location:submitting-form-info-message': 'Creando localización...',
  'new-location:submit-error-message':
    'Fallo en la creación de la localización. Inténtalo de nuevo más tarde.',
  'new-location:form:address-label': 'Dirección',
  'new-location:form:contact-name-label': 'Nombre de contacto',
  'new-location:form:contact-last-name-label': 'Apellido de contacto',
  'new-location:form:contact-phone-prefix-label': 'Prefijo',
  'new-location:form:contact-phone-number-label': 'Número de teléfono',
  'new-location:form:contact-email-label': 'Email',
  'new-location:content-title': 'Localización',
  'new-location:content-description': 'Información de la localización',
  'new-location:contact-title': 'Contacto',
  'new-location:contact-description': 'Información de contacto',
  'new-location:timezone-label': 'Zona horaria',
  'new-location:name-label': 'Nombre de la localización',
  'new-location:tag-group-title': 'Taxonomías',
  'new-location:tag-group-description':
    'Asigna una taxonomía a esta localización',
  'edit-location:submit-error-message':
    'Error en la edición de la tienda. Inténtalo de nuevo más tarde.',
  'edit-location:taxonomy-not-exist-message': 'La taxonomía no existe.',
  'locations:create': 'Nueva localización',
  'locations:created': 'La localización se ha creado con éxito',
  'locations:updated': 'La localización se ha actualizado con éxito',
  'no-locations:title': 'Aún no hay localizaciones',
  'no-locations:description':
    'Comienza creando una localización para esta organización. Todas las localizaciones se mostrarán en esta página.',
  'location:remove:success': 'Localización eliminada.',
  'location:remove:error': 'Error al eliminar la localización.',
  'location:remove:confirm': 'Se eliminará permanentemente',
  'location:remove:confirm:title':
    '¿Estás seguro de que quieres eliminar esta localización?',
  'location:taxonomies:empty':
    'No hay taxonomías, debes de crear al menos una para poder crear una localización',
  'form-label': 'Guardar cambios',
  'client:remove:success': 'Organización eliminada.',
  'client:remove:error': 'Error al eliminar la organización.',
  'client:remove:confirm':
    'Todas las marcas, tiendas, campañas, usuarios y eventos asociados también se eliminarán permanentemente',
  'client:remove:confirm:title':
    '¿Estás seguro de que quieres eliminar esta organización?',
  'brand:remove:success': 'Marca eliminada.',
  'brand:remove:error': 'Error al eliminar la marca.',
  'brand:remove:confirm':
    'Todas las tiendas, campañas, usuarios y eventos asociados también se eliminarán permanentemente',
  'brand:remove:confirm:title':
    '¿Estás seguro de que quieres eliminar esta marca?',
  'nav:accounts:subheader': 'admin',
  'nav:accounts:organizations': 'organizaciones',
  'nav:accounts:users': 'usuarios',
  'nav:accounts:nodes': 'nodos',
  // nav dashboard
  'nav:dashboard:subheader': 'dashboard',
  'nav:dashboard:subheader:reports': 'informes',
  'nav:dashboard:brands': 'marcas',
  'nav:dashboard:locations': 'localizaciones',
  'nav:dashboard:users': 'usuarios',
  'nav:dashboard:anonymous': 'Flujo anónimos',
  'nav:dashboard:registered': 'Flujo registrados',
  'nav:dashboard:analytics': 'analíticas',
  'nav:dashboard:connect': 'conectividad',
  'nav:dashboard:registers': 'registros',
  'nav:dashboard:campaign': 'campañas',
  'nav:dashboard:campaign:engagement': 'campañas de captación',
  'nav:dashboard:campaign:loyalty': 'campañas de fidelización',
  'nav:dashboard:realtime': 'Tiempo real',
  'kpis:out-door': 'Fuera de tienda',
  'kpis:in-store': 'Dentro de tienda',
  'kpis:checkout': 'En caja',
  'kpis:sell-out': 'Ventas',
  'graph:in-store-traffic': 'Tráfico en tienda',
  'graph:top-stores-traffic': 'Tiendas con mayor tráfico',
  'graph:users-visits-in-store-traffic':
    'Tráfico de usuarios y visitas en tienda',
  'graph:from-last-year': 'que el año pasado',
  'graph:from-last-period': 'que el periodo anterior',
  'graph:users': 'Usuarios',
  'graph:visits': 'Visitas',
  'graph:frequency': 'Frecuencia',
  'graph:frequency:one-visit': 'Una visita',
  'graph:frequency:two-visits': 'Dos visitas',
  'graph:frequency:more-than-three-visits': 'Más de 3 visitas',
  'graph:visits-by-gender': 'Visitas por género',
  'graph:visits-by-age': 'Visitas por edad',
  'graph:visits-by-age-over-65': 'Por encima de 65',
  'graph:visits-by-age-55-64': 'Entre 55-64',
  'graph:visits-by-age-45-54': 'Entre 45-54',
  'graph:visits-by-age-30-44': 'Entre 30-44',
  'graph:visits-by-age-18-29': 'Entre 18-29',
  'graph:visits-by-age-0-17': 'Entre 0-17',
  'users:registered': 'Usuarios registrados',
  'users:registered-users': 'Usuarios registrados',
  'users:new-users': 'Nuevos Usuarios',
  'users:recurrent': 'Usuarios recurrentes',
  'users:men': 'Hombres',
  'users:women': 'Mujeres',
  'dates-bar:select-date': 'Seleccionar fecha',
  'dates-bar:YESTERDAY': 'Ayer',
  'dates-bar:LAST_7_DAYS': 'Últimos 7 días',
  'dates-bar:week': 'Semana en curso',
  'dates-bar:LAST_28_DAYS': 'Últimos 28 días',
  'dates-bar:CURRENT_YEAR': 'Año en curso',
  'dates-bar:month': 'Mes',
  'connect:title': 'Conectividad',
  'connect:percentages': '% de usuarios registrados',
  'connect:sales-to-registered': 'Ventas a usuarios registrados',
  'connect:visits-needed-to-register': 'Visitas necesarias para registro',
  'connect:accumulated-registrations': 'Registros acumulados',
  'connect:campaign-performance': 'Rendimiento de campañas:',
  'connect:number-people-store': 'Número de personas por tienda:',
  'connect:number-people-store-opportunity': 'Oportunidad',
  'connect:registered-by-minutes': 'Registros por minutos',
  '404:title:page': '404 Page Not Found',
  '404:title': '¡Vaya, algo no salió como esperábamos!',
  '404:description':
    'No hemos podido recuperar los datos. Inténtalo de nuevo en unos minutos. Si el error persiste, contacta con nosotros.',
  '404:go-home': 'Volver',
  '403:title:page': '403 Permission Denied',
  '403:title': 'Acceso restringido',
  '403:description':
    'La página a la que estás intentando acceder es de acceso restringido. Por favor, consulta al  administrador del sistema',
  '403:go-home': 'Volver',
  'tabs:all': 'Todas',
  'tabs:detections': 'Detecciones',
  'tabs:registers': 'Registros',
  'tabs:sales': 'Ventas',
  'tabs:revenue': 'Ingresos',
  'store:table:kpi': 'KPI',
  'store:table:zone': 'Zona',
  'store:table:new-users': 'Usuarios nuevos',
  'store:table:recurrent-users': 'Usuarios recurrentes',
  'store:table:registered-users': 'Usuarios recurrentes',
  'store:table:no-stats': 'No hay datos',
  'locations:table:name': 'Nombre',
  'locations:table:address': 'Dirección',
  'registers:title:page': 'Registros',
  'registers:table:user': 'Usuario',
  'registers:table:event': 'Evento',
  'registers:table:store-name': 'Nombre tienda',
  'registers:table:date-time': 'Fecha y hora',
  'registers:table:store-id': 'ID tienda',
  'registers:table:account-id': 'ID cuenta',
  'registers:table:campaign-name': 'Nombre campaña',
  'registers:table:no-data': 'No hay eventos',
  'realtime:title:page': 'Tiempo real',
  'realtime:table:user': 'Usuario',
  'realtime:table:event': 'Evento',
  'realtime:table:store': 'Nombre tienda',
  'realtime:table:date-time': 'Fecha y hora',
  'realtime:table:zone': 'Nombre zona',
  'realtime:table:revenue': 'Ingresos',
  'realtime:table:campaign': 'Nombre campaña',
  'realtime:no-data': 'No hay datos',
  'contact-info': 'Información de contacto',
  'date-picker:title': 'Seleccione un rango de fechas',
  'date-picker:from': 'Desde',
  'date-picker:to': 'Hasta',
  'date-picker:error:start-date': 'Por favor, seleccione una fecha inicial',
  'date-picker:error:end-date':
    'La fecha final debe ser superior a la fecha inicial',
  'date-picker:error:start-date-today':
    'La fecha inicial no puede ser superior a hoy',
  'date-picker:error:end-date-today':
    'La fecha final no puede ser superior a hoy',
  // Common
  'common:form:error-missing-field':
    'Por favor, completa la información requerida',
  'common:error-invalid-email': 'Por favor, introduce un email válido',
  'common:error-invalid-phone': 'Por favor, introduce un teléfono válido',
  'common:error-invalid-zip-code':
    'Por favor, introduce un código postal válido',
  loading: 'Cargando...',
  saving: 'Guardando...',
  updating: 'Actualizando...',
  'common:button:accept': 'Aceptar',
  'common:button:cancel': 'Cancelar',
  'common:no:data': 'no disponible',
  // Taxonomy
  'nav:accounts:taxonomies': 'Taxonomías',
  'view:taxonomies:title': 'Taxonomías',
  'view:taxonomies:add:button': 'Nueva taxonomía',
  'create:taxonomy:title': 'Crear taxonomía',
  'create:taxonomy:button': 'Crear taxonomía',
  'create:taxonomy:form:name': 'Nombre',
  'create:taxonomy:form:tag': 'Etiqueta',
  'create:taxonomy:add:tag:button': 'Añadir etiqueta',
  'create:taxonomy:error:generic':
    'Hubo un error en la creación de la taxonomía. Por favor, inténtalo de nuevo.',
  'create:taxonomy:error:already:exist':
    'Error de duplicación. La taxonomía ya existe.',
  'edit:taxonomy:title': 'Editar taxonomía',
  'edit:taxonomy:button': 'Guardar taxonomía',
  'taxonomy:created': 'La taxonomía se ha creado con éxito',
  'taxonomy:updated': 'La taxonomía se ha actualizado con éxito',
  'taxonomies:empty-content:title': 'Aún no hay taxonomías',
  'taxonomies:empty-content:description':
    'Puedes empezar por crear una taxonomía. Todas las taxonomías serán listadas en esta página.',
  'taxonomy:remove:success': 'Taxonomía eliminada.',
  'taxonomy:remove:error': 'Error al eliminar la taxonomía.',
  'taxonomy:remove:confirm:title':
    '¿Estás seguro de que quieres eliminar esta taxonomía?',
  'taxonomy:remove:confirm:used':
    'La taxononomía que quieres eliminar está asociada a una localizacación y asignada a una agrupación',
  'tag:group:empty':
    'No hay taxonomías, debes de crear al menos una para poder crear una agrupación',
  // Tag Group
  'nav:accounts:tag:group': 'Agrupaciones',
  'view:tag:group:title': 'Agrupaciones',
  'view:tag:group:add:button': 'Nueva agrupación',
  'create:tag:group:title': 'Crear agrupación',
  'create:tag:group:button': 'Crear agrupación',
  'create:tag:group:error:generic':
    'Hubo un error en la creación de la agrupación. Por favor, inténtalo de nuevo.',
  'create:tag:group:error:already:exist':
    'Error de duplicación. La agrupación ya existe.',
  'edit:tag:group:title': 'Editar agrupación',
  'edit:tag:group:button': 'Guardar agrupación',
  'tag:group:created': 'La agrupación se ha creado con éxito',
  'tag:group:updated': 'La agrupación se ha actualizado con éxito',
  'tag:group:empty-content:title': 'Aún no hay agrupación',
  'tag:group:empty-content:description':
    'Puedes empezar por crear una agrupación. Todas los grupos serán listados en esta página.',
  'tag:group:remove:success': 'Agrupación eliminada.',
  'tag:group:remove:error': 'Error al eliminar la agrupación.',
  'tag:group:remove:confirm:title':
    '¿Estás seguro de que quieres eliminar esta agrupación?',
  //Nodes
  'nodes:title': 'Nodos',
  'nodes:empty-content:title': 'Aún no hay nodos',
  'nodes:empty-content:description':
    'Cuando haya algún nodo disponible aparecerá aquí',
  'nodes:table:organization': 'Organización',
  'nodes:table:location': 'Localización',
  'nodes:table:zone': 'Zona',
  'nodes:table:status': 'Estado',
  'create:node:title': 'Crear nodo',
  'create:node:button': 'Crear nodo',
  'create:node:form:description': 'Descripción',
  'create:node:form:organization': 'Organización',
  'create:node:form:location': 'Localización',
  'create:node:form:zone': 'Zona',
  'create:node:form:description:not:pos:error':
    'La descripción es obligatoria si la zona corresponde al punto de venta.',
  'create:node:form:empty:locations:error':
    'La organización seleccionada no tiene localizaciones asignadas.',
  'create:node:form:empty:zones:error':
    'La organización seleccionada no tiene zonas asignadas.',
  'Missing or incorrect required params: organizationId':
    'Parámetro organizationId incorrecto',
  'Missing or incorrect required params: locationId':
    'Parámetro locationId incorrecto',
  'Missing or incorrect required params: zoneId': 'Parámetro zoneId incorrecto',
  'create:node:error:generic':
    'Hubo un error en la creación del nodo. Por favor, inténtalo de nuevo.',
  'nodes:no:data': 'Actualmente no hay nodos.',
  'edit:node:title': 'Editar nodo',
  'edit:node:button': 'Guardar cambios',
  'edit:node:error:generic':
    'Hubo un error en la edición del nodo. Por favor, inténtalo de nuevo.',
  // Organization
  'new:organization:zones:title': 'Zonas',
  'new:organization:zones:description':
    'La primera zona corresponde a el punto de venta',
  'new:organization:zones:form:label': 'Zona',
  'new:organization:zones:form:point:of:sales': 'Zona punto de venta',
  'empty:organization:selector': 'Sin organización',
  'new:organization:modules:title': 'Módulos',
  'new:organization:modules:description':
    'Elige los módulos que la organización tendrá disponibles',
  'new:organization:logo:title': 'Logo',
  'new:organization:logo:description': 'Elige el logo de la organización',
  'new:organization:logo:empty': 'Selecciona un logo para la organización',
  'new:organization:logo:button': 'Sube un logo',
  'organization:fetch:generic:error':
    'Hubo un error al solicitar la organización.',
  'organization:unsubscribe:generic:error':
    'Hubo un error al tramitar la baja de la organización.',
  'organization:unsubscribe:customer:already:exist:error':
    'El usuario ya se dió de baja.',
  'organization:unsubscribe:customer:not:found:error': 'El usuario no existe.',
  // Roles
  'role.SUPER_ADMIN': 'Super administrador',
  'role.ORGANIZATION_ADMIN': 'Administrador de organización',
  'role.BASE': 'Empleado',
  // User
  'create:user:error':
    'Hubo un error en la creación de usuario. Por favor, inténtalo de nuevo',
  'create:user:existing:email': 'Hubo un error en la creación del usuario, el email ya existe',
  'edit:user:error':
    'Hubo un error en la edición de usuario. Por favor, inténtalo de nuevo.',
  'edit:user:invalid:role:with:assigned:organization:error':
    'No se puede asignar una organización a un usuario Super Administrador.',
  // Permissions
  'permission:PERM_ANONYMOUS': 'Habilitar módulo de anónimos',
  'permission:PERM_REGISTERED': 'Habilitar módulo de registrados',
  // Anonymous
  'anonymous:kpis:no:data': 'No hay datos disponibles',
  'anonymous:kpis:location:filter': 'Localizaciones',
  'anonymous:kpis:location:filter:title': 'Filtro de localizaciones',
  'anonymous:kpis:location:selector': 'Localizaciones',
  'anonymous:kpis:location:selector:empty:result':
    'No hay localizaciones seleccionadas',
  'anonymous:kpis:location:selector:empty:tag': 'No hay tags seleccionados',
  'anonymous:kpis:label:detections_sum': 'Suma de visitantes en',
  'anonymous:kpis:label:detections_average': 'Media de visitantes en',
  'anonymous:kpis:label:average_time_stay': 'Tiempo de estancia medio en',
  'anonymous:kpis:label:new_vs_loyal': 'Tasa de vuelta a',
  'anonymous:kpis:label:average_time_comeback': 'Tiempo medio de regreso a',
  'anonymous:kpis:zone:zone_checkout': 'Caja',
  'anonymous:kpis:zone:zone_2': 'Zona 2',
  'anonymous:kpis:zone:zone_3': 'Zona 3',
  'anonymous:kpis:chart:total:visits': 'Visitas Totales',
  'anonymous:kpis:chart:total:visits:tooltip':
    'Distribución temporal de la totalidad de las visitas detectadas en las diferentes zonas, para las localizaciones y fechas seleccionadas.',
  'anonymous:kpis:chart:frequency:one-visit': 'Una visita',
  'anonymous:kpis:chart:frequency:two-visits': 'Dos visitas',
  'anonymous:kpis:chart:frequency:three-or-more-visits': 'Tres o más visitas',
  'anonymous:kpis:chart:total:detection:flow': 'Flujo de visitantes totales',
  'anonymous:kpis:chart:total:detection:flow:tooltip':
    'Funnel con el flujo de los visitantes en las diferentes zonas de las localizaciones, para fechas seleccionadas.',
  // Breadcrumbs
  'breadcrumbs:locations': 'Localizaciones',
  'breadcrumbs:locations/new': 'Nueva localización',
  'breadcrumbs:taxonomies': 'Taxonomías',
  'breadcrumbs:taxonomies/new': 'Nueva taxonomía',
  'breadcrumbs:tag-groups': 'Agrupaciones',
  'breadcrumbs:tag-groups/new': 'Nueva agrupación',
  'breadcrumbs:users': 'Usuarios',
  'breadcrumbs:users/new': 'Nuevo usuario',
  'breadcrumbs:anonymous': 'Anónimos',
  'breadcrumbs:registered': 'Registrados',
  'breadcrumbs:backoffice': 'Backoffice',
  'breadcrumbs:campaigns': 'Campañas de captación',
  'breadcrumbs:new-campaign': 'Nueva campaña de captación',
  // Date
  'date:calendar:monday': 'Lunes',
  'date:calendar:tuesday': 'Martes',
  'date:calendar:wednesday': 'Miércoles',
  'date:calendar:thursday': 'Jueves',
  'date:calendar:friday': 'Viernes',
  'date:calendar:saturday': 'Sábado',
  'date:calendar:sunday': 'Domingo',
  'date:calendar:january': 'Enero',
  'date:calendar:february': 'Febrero',
  'date:calendar:march': 'Marzo',
  'date:calendar:april': 'Abril',
  'date:calendar:may': 'Mayo',
  'date:calendar:june': 'Junio',
  'date:calendar:july': 'Julio',
  'date:calendar:august': 'Agosto',
  'date:calendar:september': 'Septiembre',
  'date:calendar:october': 'Octubre',
  'date:calendar:november': 'Noviembre',
  'date:calendar:december': 'Diciembre',
  'date:calendar:full-day': '24 horas',
  'date:calendar:half-day': '12 horas',
  'date:calendar:interval': 'Intervalo',
  'date:calendar:initials:monday': 'L',
  'date:calendar:initials:tuesday': 'M',
  'date:calendar:initials:wednesday': 'X',
  'date:calendar:initials:thursday': 'J',
  'date:calendar:initials:friday': 'V',
  'date:calendar:initials:saturday': 'S',
  'date:calendar:initials:sunday': 'D',
  // Campaigns
  'register:campaign:phone:label': 'Teléfono',
  'register:campaign:email:label': 'Correo eletrónico',
  'register:campaign:gender:label': 'Género',
  'register:campaign:zipCode:label': 'Código postal',
  'register:campaign:birthDate:label': 'Fecha de nacimiento',
  'register:campaign:register:button': 'Registro',
  'new:campaign:wifi:web': 'Seleccionar canal',
  'register:campaign:gender:male': 'Masculino',
  'register:campaign:gender:female': 'Femenino',
  'register:campaign:gender:no:response': 'No quiero decirlo',
  'register:campaign:error:message':
    'Ha habido un error en el registro de la campaña',
  'register:campaign:prefix:label': 'Prefijo',
  'register:campaign:terms:checkbox:label': 'Acepto los términos y condiciones',
  'register:campaign:page:title': 'Registro campaña',
  'register:campaign:channel': 'Canal',
  'register:not:active:campaign':
    'La campaña para la que intentas registrarte no está disponible',
  // Unsubscribe
  'unsubscribe:title': 'Date de baja de',
  'unsubscribe:text':
    'Si estás seguro de que quieres darte de baja haz click en el botón.',
  'unsubscribe:button': 'Darse de baja',
  'unsubscribe:success:title': '¡Hecho!',
  'unsubscribe:success:text': 'Tu baja se ha tramitado con éxito.',
  'unsubscribe:generic:error': 'Hubo un fallo al tramitar tu baja.',
  // Analytics
  'analytics:empty:filter:title': 'No hay criterios de filtrado seleccionados.',
  'analytics:empty:filter:text':
    'Selecciona una localización o un rango de fechas para ver los resultados.',
  'analytics:no-locations:error:message': 'No hay localizaciones asociadas.',
  'analytics:user:unauthorized:error:message':
    'No tienes autorización para acceder a esta vista.',
  'analytics:user:unauthorized:error:button': 'Ir a mis agrupaciones',
  'analytics:request:generic:error':
    'Hubo un error al solicitar los  datos. Por favor, inténtalo más tarde.',
  'analytics:request:kpis:generic:error':
    'Hubo un error al solicitar los  kpis. Por favor, inténtalo más tarde.',
  'analytics:request:frequency:generic:error':
    'Hubo un error al solicitar la frecuencia. Por favor, inténtalo más tarde.',
  'analytics:request:traffic:zones:generic:error':
    'Hubo un error al solicitar las visitas. Por favor, inténtalo más tarde.',
  'analytics:request:attraction:rate:generic:error':
    'Hubo un error al solicitar los ratios. Por favor, inténtalo más tarde.',
  'analytics:request:dwell:time:generic:error':
    'Hubo un error al solicitar los tiempos. Por favor, inténtalo más tarde.',
  'analytics:request:registrations:generic:error':
    'Hubo un error al solicitar los registros. Por favor, inténtalo más tarde.',
  'analytics:kpis:visits': 'Visitas',
  'analytics:kpis:visitors': 'Visitantes únicos',
  'analytics:kpis:visitors:short': 'Visitantes',
  'analytics:kpis:avg:time': 'tpo prom.',
  'analytics:kpis:initial:visits': 'V',
  'analytics:kpis:initial:visitors': 'VU',
  'analytics:kpis:initial:avg:time': 'TP',
  'analytics:kpis:chart:average-visits-comeback': 'Tiempo Medio Entre Visitas',
  'analytics:kpis:chart:average-visits-comeback:tooltip':
    'Media de tiempo en días, entre visitas de un mismo dispositivo, para las localizaciones y fechas seleccionadas.',
  'analytics:kpis:chart:average-visits-comeback:yAxisDays': 'días',
  'analytics:kpis:chart:frequency:title': 'Frecuencia de Visitas Totales',
  'analytics:kpis:chart:frequency:tooltip':
    'Visitantes totales detectados, distribuidos por cantidad de visitas realizadas, para las localizaciones y fechas seleccionadas.',
  'analytics:ratio-attraction:graph:title': 'Ratio de Atracción',
  'analytics:ratio-attraction:graph:tooltip':
    'Cantidad de dispositivos detectados en las inmediaciones de la localización, por un tiempo superior al ratio de tiempo indicado (+2 minutos), y que posteriormente han sido visitantes de la localización.',
  'analytics:ratio-attraction:graph:label': 'Exposición +2 Minutos',
  'analytics:retention-ratio:graph:title': 'Ratio de permanencia',
  'analytics:retention-ratio:graph:tooltip':
    'Visitantes que permanecen en la localización seleccionada, por un tiempo superior al ratio de tiempo indicado (+2 minutos).',
  'analytics:retention-ratio:graph:info': 'Visitas de +2 Min',
  'analytics:heatmap:detections:label': 'Mapa de calor de visitantes',
  'analytics:heatmap:detections:tooltip':
    'Distribución en forma de mapa de calor, que refleja la cantidad de visitantes detectados por día y hora, en las localizaciones y fechas seleccionadas.',
  'analytics:kpis:visits-average': 'Promedio de Visitas',
  'analytics:kpis:visits-average:tooltip':
    'Media de visitas totales detectadas, en las localizaciones y fechas seleccionadas.',
  'analytics:kpis:frequency-visits-average':
    'Promedio de Frecuencia de Visitas',
  'analytics:kpis:frequency-visits-average:tooltip':
    'Media de las visitantes frecuentes, en más de una ocasión, para las localizaciones y fechas seleccionadas.',
  'analytics:kpis:chart:total:visits:variation:yesterday': 'versus ayer',
  'analytics:kpis:chart:total:visits:variation:last_7_days':
    'versus semana pasada',
  'analytics:kpis:chart:total:visits:variation:last_28_days':
    'versus 28 días anteriores',
  'analytics:kpis:chart:total:visits:variation:current_year':
    'versus año pasado',
  'analytics:kpis:chart:total:visits:variation:month_january': 'Enero',
  'analytics:kpis:chart:total:visits:variation:month_february': 'Febrero',
  'analytics:kpis:chart:total:visits:variation:month_march': 'Marzo',
  'analytics:kpis:chart:total:visits:variation:month_april': 'Abril',
  'analytics:kpis:chart:total:visits:variation:month_may': 'Mayo',
  'analytics:kpis:chart:total:visits:variation:month_june': 'Junio',
  'analytics:kpis:chart:total:visits:variation:month_july': 'Julio',
  'analytics:kpis:chart:total:visits:variation:month_august': 'Agosto',
  'analytics:kpis:chart:total:visits:variation:month_september': 'Septiembre',
  'analytics:kpis:chart:total:visits:variation:month_october': 'Octubre',
  'analytics:kpis:chart:total:visits:variation:month_november': 'Noviembre',
  'analytics:kpis:chart:total:visits:variation:month_december': 'Diciembre',
  'analytics:anonymous': 'Anónimos',
  'analytics:registered': 'Registrados',
  'analytics:default:total:label': 'Total',
  'analytics:kpis:registered:vs:anonymous': 'Anónimos vs Registrados',
  // Registered
  'registered:kpis:chart:frequency:one-visit': 'Una visita',
  'registered:kpis:chart:frequency:two-visits': 'Dos visitas',
  'registered:kpis:chart:frequency:three-or-more-visits': 'Tres o más visitas',
  'registered:kpis:chart:total-registered-frequency':
    'Frecuencia de Visitas Totales Registrados',
  'registered:kpis:chart:total-anonymous-registered:title':
    'Visitas Anónimos - Registrados',
  'registered:kpis:chart:total-anonymous-registered:anonymous:label':
    'Anónimos',
  'registered:kpis:chart:total-anonymous-registered:registered:label':
    'Registrados',
  'registered:kpis:chart:registered-visits-frequency-average:title':
    'Promedio de Frecuencia de Visitas',
  // Unsubscribe
  'unsubscribe:campaign:error:invalid:uuid':
    'El usuario o la campaña no existe',
  'unsubscribe:campaign:error:invalid:customer:organization':
    'El usuario no pertenece a esta organización',
  'unsubscribe:campaign:error:invalid:customer': 'El usuario no existe',
  'unsubscribe:campaign:error:already:unsubscribed':
    'El usuario ya estaba desuscrito',
  'unsubscribe:campaign:error:generic':
    'Hubo un error al tramitar la baja de la campaña',
  //Genders
  male: 'Hombre',
  female: 'Mujer',
  no_response: 'Prefiero no responder',
}

export default es
