class Registration {
  constructor(
    readonly value: number,
    readonly rate: number,
  ) {}
}
export default class RegistrationsModel {
  constructor(
    readonly totalRegistrations: Registration,
    readonly registrations: Registration,
  ) {}
}
