export const ANALYTICS_KPI_DATA = [
  {
    height: 200,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    height: 200,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    height: 200,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    height: 200,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
]
export const ANALYTICS_KPI_COLUMN_DATA = [
  {
    height: 300,
    xs: 12,
    sm: 6,
    md: 4,
    lg: null,
  },
  {
    height: 300,
    xs: 12,
    sm: 6,
    md: 4,
    lg: null,
  },
  {
    height: 300,
    xs: 12,
    sm: 6,
    md: 4,
    lg: null,
  },
]

export const ANALYTICS_TRAFFIC_DATA = [
  {
    height: 300,
    xs: 12,
    md: 8,
    lg: null,
  },
]

export const ANALYTICS_HORIZONTAL_BAR_DATA = [
  {
    height: 250,
    xs: 12,
  },
]

export const ANALYTICS_DETECTIONS_DATA = [
  {
    height: 500,
    xs: 12,
    md: 8,
    lg: null,
  },
]

export const AVERAGE_DETECTIONS_DATA = [
  {
    height: 150,
    xs: 12,
    md: 8,
    lg: null,
  },
]

export const ANONYMOUS_ANALYTIC_DATA = [
  {
    height: 300,
    xs: 12,
    sm: null,
    md: 6,
    lg: 8,
  },
  {
    height: 300,
    xs: 12,
    sm: null,
    md: 6,
    lg: 4,
  },
  {
    height: 300,
    xs: 12,
    sm: null,
    md: 6,
    lg: 4,
  },
  {
    height: 300,
    xs: 12,
    sm: null,
    md: 6,
    lg: 8,
  },
]

export const CONNECT_LOADING_DATA = [
  {
    title: 'Registered Users',
    icon: null,
    height: undefined,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    title: '% Of Users Registered',
    icon: null,
    height: undefined,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    title: 'Sales To Users Registered',
    icon: null,
    height: undefined,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    title: 'Visits Needed To Register',
    icon: null,
    height: undefined,
    xs: 12,
    sm: 6,
    md: 3,
    lg: null,
  },
  {
    title: 'Accumulated Registrations',
    icon: null,
    height: 500,
    xs: 12,
    sm: null,
    md: 6,
    lg: 6,
  },
  // {
  //   title: 'Campaigns Performance',
  //   icon: null,
  //   height: 500,
  //   xs: 12,
  //   sm: null,
  //   md: 6,
  //   lg: 4,
  // },
  {
    title: 'Number Of People By Store:',
    icon: null,
    height: 500,
    xs: 12,
    sm: null,
    md: 6,
    lg: 4,
  },
  {
    title: 'Registered By Minutes',
    icon: null,
    height: 200,
    xs: 12,
    sm: null,
    md: 6,
    lg: 4,
  },
]
