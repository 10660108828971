import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Card,
  Table,
  Typography,
  TableBody,
  TableContainer,
  Stack,
} from '@mui/material'
import { useLocales } from '../../../locales'
import {
  useTable,
  getComparator,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../../components/table'
import Scrollbar from '../../../components/scrollbar'
import Iconify from '../../../components/iconify'
import useSnackbarMessageByQueryParams from '../../../hooks/useSnackbarMessageByQueryParams'
import TableLoader from '../../../components/TableLoader/TableLoader'
import UseStateType from '../../../services/common/types/UseStateType'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import style from '../Campaigns/CampaignsStyle'
import { useParams } from 'react-router'
import campaignsStatusEnum from '../../../services/campaigns/enum/campaignsStatusEnum'
import { PATH_DASHBOARD } from '../../../routes/paths'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import CAMPAIGNS_TABLE_HEADER from './LoyaltyCampaignsTableHeader'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import CampaignsTableFilters from '../Campaigns/CampaignsTableFilters'
import useLoyaltyCampaigns from '../../../services/loyaltyCampaigns/useLoyaltyCampaigns'
import { LoyaltyCampaignsModel } from '../../../services/loyaltyCampaigns/model/LoyaltyCampaignsModel'
import LoyaltyCampaignsTableRow from '../../../sections/dashboard/campaigns/loyaltyCampaigns/LoyaltyCampaignsTableRow'
import { applyCampaignsTableFilter } from '../../../utils/campaignsHelper'

export default function LoyaltyCampaigns() {
  const { organizationId, groupId } = useParams()
  const { data: campaignsData, loading: isCampaignsDataLoading } = useLoyaltyCampaigns(
    organizationId!,
  ) as UseStateType<LoyaltyCampaignsModel[]>
  const { translate } = useLocales()
  const navigate = useNavigate()
  const IconComponent: any = Iconify
  const title = `${translate('campaigns:loyalty:title')}`

  const [snackbarMessage, closeSnackbarMessage] =
    useSnackbarMessageByQueryParams({
      created: 'campaigns:created',
      updated: 'campaigns:updated',
      'ended-error': 'campaigns:ended',
    })

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    selected,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'start', defaultOrder: 'desc' })

  const [filterStatus, setFilterStatus] = useState(
    campaignsStatusEnum.ALL as string,
  )

  const dataFiltered: LoyaltyCampaignsModel[] = applyCampaignsTableFilter({
    inputData: campaignsData || [],
    comparator: getComparator(order, orderBy),
    filterStatus,
  })

  const handleFilterStatus = (_: any, newValue: string) => {
    setPage(0)
    setFilterStatus(newValue)
  }

  const editCampaign = (campaignId: string) => {
    // navigate(PATH_DASHBOARD.editCampaign(organizationId, brandId, campaignId))
  }

  const viewCampaign = (campaignId: string) => {
    // navigate(PATH_DASHBOARD.editCampaign(organizationId, brandId, campaignId))
  }

  const getIcon = (): JSX.Element => {
    return <IconComponent icon="eva:plus-fill" width={16} />
  }

  return (
    <>
      <Helmet>
        <title>{title} | So69</title>
      </Helmet>
      <ViewContainer>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography variant="h4" sx={style.titleContainer}>
            {title}
          </Typography>
          <PrimaryButton
            iconComponent={getIcon()}
            onClick={() => {
              navigate(PATH_DASHBOARD.newCampaign(organizationId, groupId))
            }}>
            {`${translate('campaigns:create-button')}`}
          </PrimaryButton>
        </Stack>
        <Grid item xs={12}>
          <Card>
            <CampaignsTableFilters
              campaignsData={campaignsData}
              filterStatus={filterStatus}
              handleChange={handleFilterStatus}
            />
            <TableContainer>
              <Scrollbar>
                <Table sx={style.tableContainer}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={CAMPAIGNS_TABLE_HEADER}
                    rowCount={0}
                    numSelected={selected.length}
                    onSort={onSort}
                  />
                  <TableBody>
                    {isCampaignsDataLoading ? (
                      <TableLoader />
                    ) : (
                      <>
                        {dataFiltered
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map(row => (
                            <LoyaltyCampaignsTableRow
                              key={row.uuid}
                              row={{ ...row }}
                              selected={selected.includes(row.uuid)}
                              edit={() => {
                                editCampaign(row.uuid)
                              }}
                              goToView={() => {
                                viewCampaign(row.uuid)
                              }}
                            />
                          ))}
                      </>
                    )}
                    <TableNoData isNotFound={false} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePaginationCustom
              count={dataFiltered?.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </ViewContainer>
      <SnackbarAlert
        isOpen={snackbarMessage !== null}
        onClose={closeSnackbarMessage as () => void}
        message={snackbarMessage as string}
      />
    </>
  )
}
