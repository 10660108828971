const campaignMessageContentFormStyle = {
  container: {
    display: 'flex',
    gap: 2,
  },
  title: {
    mb: 1,
    fontWeight: 'bold',
  },
  field: {
    mb: 1,
  },
  genderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}

export default campaignMessageContentFormStyle
