/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { TableHeadCustom } from '../table'
import TABLE_HEADER from './TableHeader'
import TableLoader from '../TableLoader/TableLoader'
import { getNodesByDates } from '../../services/Nodes/nodeService'
import { useParams } from 'react-router'
import useStyle from './CampaignFormNodeTableStyle'

type NodeType = {
  uuid: string
  mac: string
  organizationUUID: string
  locationUUID: string
  zoneUUID: string
  organizationName: string
  locationName: string
  locationAddress: string
  zoneName: string
  networks: number[]
  remainingNetworks: number[]
}
type FormNodeType = {
  uuid: string
  mac: string
  organizationUUID: string
  locationUUID: string
  zoneUUID: string
  organizationName: string
  locationName: string
  zoneName: string
  network: string
}

export default function CampaignFormNodeTable({
  form,
  selectedNetwork,
}: {
  form: any
  selectedNetwork: string
}) {
  const [checked, setChecked] = useState<string[]>([])
  const [nodes, setNodes] = useState<NodeType[]>([])
  const [defaultNodes, setDefaultNodes] = useState<NodeType[]>([])
  const [nodesLoaded, setNodesLoaded] = useState<boolean>(false)
  const loading = false // TODO - Remove when receives data
  const theme = useTheme()
  const style = useStyle(theme)
  const { groupId, organizationId, campaignId } = useParams()

  useEffect(() => {
    if (!Boolean(selectedNetwork)) {
      return
    }

    filterNodes(parseInt(selectedNetwork))
    setChecked([])
  }, [selectedNetwork])

  const loadNodes = async () => {
    if (nodesLoaded) {
      return
    }

    if (!form.values.start || !form.values.end || !form.values.wifiNetwork) {
      return
    }

    try {
      const apiNodes = await getNodesByDates(
        organizationId ?? '',
        groupId ?? '',
        new Date(form.values.start).toISOString(),
        new Date(form.values.end).toISOString(),
        campaignId,
      )

      setDefaultNodes(apiNodes)
      if (form.values.nodes) {
        setNodes([...apiNodes])
        setNodesLoaded(true)
        const checkedValues: string[] = []
        form.values.nodes.map((value: FormNodeType, row: any) =>
          checkedValues.push(value.uuid),
        )
        setChecked([...checkedValues])

        return
      }

      const filteredData = apiNodes.filter(item =>
        item.remainingNetworks.includes(parseInt(form.values.wifiNetwork)),
      )
      setNodes([...filteredData])
      setNodesLoaded(true)
    } catch (error) {}
  }

  useEffect(() => {
    void loadNodes()
  }, [form.values.start, form.values.end, form.values.wifiNetwork])

  const handleToggle = (value: NodeType) => () => {
    const currentIndex = checked.indexOf(value.uuid)
    if (-1 === currentIndex) {
      form.values.nodes.push(value.uuid)
    } else {
      form.values.nodes.splice(currentIndex, 1)
    }

    setChecked([...form.values.nodes])
  }

  const selectAll = () => {
    setChecked([])
    if (nodes.length === checked.length) {
      return
    }

    const checkedValues: string[] = []
    nodes.map((value, row) => checkedValues.push(value.uuid))
    setChecked([...checkedValues])
  }

  const filterNodes = (selectedNetwork: number) => {
    const filteredData = defaultNodes.filter(item =>
      item.remainingNetworks.includes(selectedNetwork),
    )

    setNodes([...filteredData])
  }

  const getTableRow = (value: NodeType, index: number): JSX.Element => {
    return (
      <TableRow
        key={`table-row-${value.uuid}`}
        hover
        onClick={handleToggle(value)}
      >
        <TableCell align="left">
          <Checkbox
            edge="start"
            checked={checked.indexOf(value.uuid) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': value.uuid }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle1">{value.locationName}</Typography>
          <Typography variant="body2" sx={style.label}>
            {value.locationAddress}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle1">Nodo {index + 1}</Typography>
          <Typography variant="body2" sx={style.label}>
            {value.zoneName}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Card>
      <Table>
        <TableHeadCustom
          headLabel={TABLE_HEADER}
          rowCount={nodes.length}
          numSelected={checked.length}
          onSelectAllRows={selectAll}
        />
        <TableBody>
          {loading ? (
            <TableLoader />
          ) : (
            <>{nodes.map((row, index) => getTableRow(row, index))}</>
          )}
        </TableBody>
      </Table>
    </Card>
  )
}
