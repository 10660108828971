import { startOfMonth, startOfWeek, startOfYear, subDays, subMonths, format } from 'date-fns'
import DateRangeModel from '../services/common/model/DateRangeModel'
import MonthModel from '../services/common/model/MonthModel';
import i18n from '../locales/i18n';

const TOTAL_YEAR_MONTHS = 12

export const FILTER_PERIODS = {
  yesterday: 'yesterday',
  lastSevenDays: 'lastSevenDays',
  week: 'week',
  month: 'month',
  year: 'year',
  custom: 'custom',
}

export enum WEEK_DAYS {
  MONDAY = 'Monday',
}

const CURRENT_DATE = new Date()
export const YESTERDAY = subDays(CURRENT_DATE, 1);

// TODO - Activate when date range will be implemented
export const getPeriodDates = (period: string): DateRangeModel => {
  let startDate = CURRENT_DATE
  let endDate = CURRENT_DATE

  if (period === FILTER_PERIODS.yesterday) {
    endDate = YESTERDAY
    startDate = YESTERDAY
  }

  if (period === FILTER_PERIODS.lastSevenDays) {
    endDate = subDays(CURRENT_DATE, 1);
    startDate = subDays(CURRENT_DATE, 7);
  }

  if (period === FILTER_PERIODS.week) {
    startDate = startOfWeek(endDate, { weekStartsOn: 1 })
  }

  if (period === FILTER_PERIODS.month) {
    startDate = startOfMonth(endDate)
  }

  if (period === FILTER_PERIODS.year) {
    startDate = startOfYear(endDate)
  }

  return new DateRangeModel(
    startDate,
    endDate,
  )
}

export const getMonthsYearFromCurrentMonth = (): MonthModel[] => {
  const months: MonthModel[] = []
  const currentDate = new Date()

  for (let i = 0; i < TOTAL_YEAR_MONTHS; i++) {
    const month = subMonths(currentDate, i)
    const monthName = format(month, 'MMMM')
    const monthYear = format(month, 'yyyy')

    months.push(
      new MonthModel(
        monthName.toLowerCase(),
        monthYear,
        `MONTH_${monthName.toUpperCase()}`,
      )
    )
  }

  return months
}

export const getWeekDay = (date: Date): string => {
  const formatWeekDay = format(date, 'EEEE')
  return `${i18n.t(`date:calendar:initials:${formatWeekDay.toLowerCase()}`)}`
}
