enum CampaignsPermissionsValues {
  PHONE = 'phone',
  NAME = 'name',
  EMAIL = 'email',
  GENDER = 'gender',
  ZIPCODE = 'zipcode',
  BIRTHDATE = 'birthdate',
}

export default CampaignsPermissionsValues
