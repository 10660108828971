import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import ImageDropArea from '../image-drop-area/ImageDropArea'
import { FormHelperText } from '@mui/material'
import FormSectionCard from '../card/FormSectionCard/FormSectionCard'
import campaignMessageContentFormStyle from './CampaignMessageContentFormStyle'
import { useEffect, useState } from 'react'
import { createFileFromUrl, createFileName } from '../../utils/createFile'

export default function CampaignMessageContentForm({
  form,
  editing = false,
  completed = false,
}: {
  form: any
  editing?: boolean
  completed?: boolean
}) {
  const { translate } = useLocales()
  const [messageFile, setMessageFile] = useState<File | null>(null)

  useEffect(() => {
    async function loadMessageImage() {

      if (!form.values.image || messageFile || !editing) {
        return
      }

      const downloadedFile = await createFileFromUrl(
        form.values.image,
        createFileName('imagen'),
      )

      if (downloadedFile) {
        setMessageFile(downloadedFile)
      }
    }

    loadMessageImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.headerImage, form.values.image])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate(
            'campaings:create:register_section:message_content_title',
          )}`}
          subtitle={`${translate(
            'campaings:create:register_section:message_content_subtitle',
          )}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormSectionCard>
          <form>
            <FormControl sx={campaignMessageContentFormStyle.container}>
              <Typography sx={campaignMessageContentFormStyle.title}>
                {`${translate('new-campaign:form-text-label')}`}
              </Typography>

              <TextField
                fullWidth
                multiline
                variant="outlined"
                disabled={editing || completed}
                rows={5}
                sx={campaignMessageContentFormStyle.field}
                name="text"
                placeholder={`${translate(
                  'new-campaign:form-text-placeholder',
                )}`}
                value={form.values.text}
                onChange={form.handleFieldChange}
                error={form.errors.text !== ''}
                helperText={form.errors.text}
              />

              <Typography sx={campaignMessageContentFormStyle.title}>
                {`${translate('new-campaign:form-image-label')}`}
              </Typography>

              <>
                <ImageDropArea
                  hasError={!!form.errors.image}
                  onChange={form.handleImageChange}
                  onError={form.handleImageError}
                  fileImage={messageFile!}
                />

                {form.errors.image !== '' ? (
                  <FormHelperText error>{form.errors.image}</FormHelperText>
                ) : null}
              </>
            </FormControl>
          </form>
        </FormSectionCard>
      </Grid>
    </Grid>
  )
}
