export const emailValidator = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const phoneNumberValidator = (number: string): boolean => {
  const MAX_PHONE_LENGTH = 15
  const MIN_PHONE_LENGTH = 7

  const numberRegex = /^[0-9]+$/

  return numberRegex.test(number) && number.length <= MAX_PHONE_LENGTH && number.length >= MIN_PHONE_LENGTH
}

export const zipCodeValidator = (zipCode: string): boolean => {
  const zipCodeRegex = /^[A-Za-z0-9\s-]{3,10}$/
  return zipCodeRegex.test(zipCode)
}

export const preffixValidator = (preffix: string): boolean => {
  const preffixRegex = /^\+([1-9]\d{0,2})(\s|-)?$/
  return preffixRegex.test(preffix)
}

export const isInvalidGenderValidator = (
  gender: boolean,
  genderOptions: number,
): boolean => {
  return gender && 0 === genderOptions
}
