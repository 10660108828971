export const createFileFromUrl = async (
  url: string,
  fileName: string,
): Promise<File | undefined> => {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Error al obtener la imagen: ${response.statusText}`)
    }
    const blob: Blob = await response.blob()
    const file = new File([blob], fileName, { type: blob.type })

    return file
  } catch (error) {
    return undefined
  }
}

export const createFileName = (baseName: string): string => {
  const now = new Date()

  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  const timestamp = `${year}/${month}/${day}-${hours}${minutes}${seconds}`

  return `${baseName}-${timestamp}.png`
}
