export const PATH_AUTH = {
  login: '/login',
}

export const PATH_PASSWORD = {
  reset: '/reset-password',
  confirm: '/reset-password/:token',
}

export const PATH_PUBLIC = {
  untrack: '/untrack',
  unsubscribe: '/unsubscribe',
  registerOrganization: '/campaigns/register',
}

export const PATH_ACCOUNTS = {
  organizations: '/app/backoffice/organizations',
  newOrganization: '/app/backoffice/organizations/new',
  editOrganization: organizationId =>
    `/app/backoffice/organizations/${organizationId}/edit`,
  locations: organizationId =>
    `/app/backoffice/organizations/${organizationId}/locations`,
  newLocation: organizationId =>
    `/app/backoffice/organizations/${organizationId}/locations/new`,
  editLocation: (organizationId, locationId) =>
    `/app/backoffice/organizations/${organizationId}/locations/${locationId}/edit`,
  generalUsers: '/app/backoffice/users',
  generalUsersNewUser: '/app/backoffice/users/new',
  generalUsersPermissions: userId =>
    `/app/backoffice/users/${userId}/permissions`,
  generalUsersEdit: userId => `/app/backoffice/users/edit/${userId}`,
  organizationUsers: organizationId =>
    `/app/backoffice/organizations/${organizationId}/users`,
  organizationUsersNewUser: organizationId =>
    `/app/backoffice/organizations/${organizationId}/users/new`,
  organizationUsersPermissions: (organizationId, userId) =>
    `/app/backoffice/organizations/${organizationId}/users/${userId}/permissions`,
  organizationUsersEdit: (organizationId, userId) =>
    `/app/backoffice/organizations/${organizationId}/users/edit/${userId}`,
  taxonomies: organizationId =>
    `/app/backoffice/organizations/${organizationId}/taxonomies`,
  newTaxonomy: organizationId =>
    `/app/backoffice/organizations/${organizationId}/taxonomies/new`,
  editTaxonomy: (organizationId, taxonomyId) =>
    `/app/backoffice/organizations/${organizationId}/taxonomies/${taxonomyId}/edit`,
  tagGroups: organizationId =>
    `/app/backoffice/organizations/${organizationId}/tag-groups`,
  newTagGroup: organizationId =>
    `/app/backoffice/organizations/${organizationId}/tag-groups/new`,
  editTagGroup: (organizationId, tagGroupId) =>
    `/app/backoffice/organizations/${organizationId}/tag-groups/${tagGroupId}/edit`,
  nodes: '/app/backoffice/nodes',
  newNode: '/app/backoffice/nodes/new',
  editNode: nodeId => `/app/backoffice/nodes/${nodeId}/edit`,
}

export const PATH_DASHBOARD = {
  anonymous: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/anonymous`,
  registered: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/registered`,
  analytics: (organizationId, brandId) =>
    `/app/dashboard/${organizationId}/${brandId}/analytics`,
  connect: (organizationId, brandId) =>
    `/app/dashboard/${organizationId}/${brandId}/connect`,
  store: (organizationId, brandId) =>
    `/app/dashboard/${organizationId}/${brandId}/store`,
  registers: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/registers`,
  campaigns: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/campaigns`,
  fidelityCampaigns: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/loyalty-campaigns`,
  newCampaign: (organizationId, groupId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/new-campaign`,
  editCampaign: (organizationId, groupId, campaignId) =>
    `/app/dashboard/organizations/${organizationId}/groups/${groupId}/campaign/${campaignId}/edit`,
}
