import GaugeGraph from '../gaugeGraph/GaugeGraph'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import { GRAPH_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import ChartCard from '../card/Chart/ChartCard/ChartCard'
import AttractionRateModel from '../../services/common/model/AttractionRateModel'
import { useTheme } from '@mui/material'

export default function RegisteredAttractionRatioGraph({
  anonymousRateData,
  registeredRateData,
}: {
  anonymousRateData: AttractionRateModel | undefined
  registeredRateData: AttractionRateModel | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const tooltipText = `${translate('analytics:ratio-attraction:graph:tooltip')}`
  const title = `${translate('analytics:ratio-attraction:graph:title')}`
  const totalLabel = `${translate('analytics:ratio-attraction:graph:label')}`

  const series = [
    {
      value: anonymousRateData?.current.internal || 0,
      label: `${translate(
        'registered:kpis:chart:total-anonymous-registered:anonymous:label',
      )}`,
      color: theme.palette.chart.attraction,
    },
    {
      value: registeredRateData?.current.internal || 0,
      label: `${translate(
        'registered:kpis:chart:total-anonymous-registered:registered:label',
      )}`,
      color: theme.palette.chart.secondZone,
    },
  ]

  const isSeriesEmpty = series.every(serie => !serie.value)

  if (isSeriesEmpty) {
    return (
      <EmptyCard
        tooltip={tooltipText}
        title={title}
        height={GRAPH_CARD_MIN_HEIGHT}
      />
    )
  }

  const getMaxValue = (): number => {
    return anonymousRateData?.current.external || 0
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltipText}
      height={GRAPH_CARD_MIN_HEIGHT}
      variant={registeredRateData?.variation}
    >
      <GaugeGraph
        series={series}
        maxValue={getMaxValue()}
        totalLabel={totalLabel}
        showLegend={true}
      />
    </ChartCard>
  )
}
