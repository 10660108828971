import { Card } from "@mui/material";
import style from './FormSectionCardStyle'

export default function FormSectionCard({
  children,
}: {
  children: React.ReactNode,
}) {
  return (
    <Card sx={style.container}>
      {children}
    </Card>
  )
}
