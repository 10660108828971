import i18n from '../../locales/i18n'

const TABLE_HEADER = [
  {
    id: 'location',
    label: i18n.t('new-campaign:form-location'),
    align: 'left',
  },
  { id: 'node', label: i18n.t('new-campaign:form-node'), align: 'left' },
]

export default TABLE_HEADER
