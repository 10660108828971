export const base64Conversor = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (): void => {
    const result = reader.result as string;
    resolve(result);
  };

  reader.onerror = (): void => {
    reject(new Error('Error al leer el archivo'));
  };

  reader.readAsDataURL(file);
});


const isFileLogo = (file: string | File) => file instanceof File;

const getImagePreview = (image: string): string => {
  return (process.env.REACT_APP_HOST_API_KEY + image).replace(/([^:]\/)\/+/g, "$1")
}

async function urlToFile(url: string, filename: string): Promise<File> {
  const response = await fetch(url)
  const blob = await response.blob()
  const file = new File([blob], filename, { type: blob.type })

  return file;
}

export const getConversionImage = async (logo: string | File | null): Promise<string | null> => {
  if (!logo) {
    return null
  }

  if (isFileLogo(logo)) {
    return await base64Conversor(logo as File)
  }

  const imageUrl = getImagePreview(logo as string)
  const urlLogo = await urlToFile(imageUrl, 'logo')

  return base64Conversor(urlLogo)
}
