import { Grid, Typography, useTheme } from '@mui/material'
import Skeleton from '../../../components/skeleton/Skeleton'
import {
  ANALYTICS_DETECTIONS_DATA,
  ANALYTICS_HORIZONTAL_BAR_DATA,
  ANALYTICS_KPI_COLUMN_DATA,
  ANALYTICS_KPI_DATA,
  ANALYTICS_TRAFFIC_DATA,
  AVERAGE_DETECTIONS_DATA,
} from '../../../utils/dashboardsConstants'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import { SelectItemType } from '../../../services/common/types/SelectItemType'
import DatePeriod from '../../../services/common/enum/DatePeriod'
import AnalyticsContainer from '../../../components/container/AnalyticsContainer/AnalyticsContainer'
import RegisteredKPIs from '../../../components/registered/RegisteredKPIs/RegisteredKPIs'
import useRegisteredKPIs from '../../../services/registered/useRegisteredKPIs'
import { RegisteredAttractionRateGenericError, RegisteredFrequencyGenericError, RegisteredKpisGenericError, RegisteredRegistrationsGenericError, RegisteredTrafficZonesGenericError } from '../../../services/registered/registeredError'
import TotalVisitsGraph from '../../../components/totalVisitsGraph/TotalVisitsGraph'
import useRegisteredTrafficZones from '../../../services/registered/useRegisteredTrafficZones'
import useRegisteredFrequency from '../../../services/registered/useRegisteredFrequency'
import AttractionRateModel from '../../../services/common/model/AttractionRateModel'
import RegisteredAttractionRatioGraph from '../../../components/attractionRatioGraph/RegisteredAttractionRatioGraph'
import useRegisteredAttractionRate from '../../../services/registered/useRegisteredAttractionRate'
import UseRegisteredStateType from '../../../services/registered/types/UseRegisteredStateType'
import DwellTimeModel from '../../../services/common/model/DwellTimeModel'
import { GRAPH_CARD_MIN_HEIGHT } from '../../../style/components/GridStyle'
import EmptyCard from '../../../components/card/EmptyCard/EmptyCard'
import DwellTimeGraph from '../../../components/dwellTimeGraph/DwellTimeGraph'
import useRegisteredDwellTime from '../../../services/registered/useRegisteredDwellTime'
import HeatmapWeekSelector from '../../../components/HeatmapWeekSelector/HeatmapWeekSelector'
import useRegisteredDetections from '../../../services/registered/useRegisteredDetections'
import DetectionsModel from '../../../services/common/model/DetectionsModel'
import AverageVisitsModel from '../../../services/registered/model/AverageVisitModel'
import useRegisteredAverageVisits from '../../../services/registered/useRegisteredAverageVisits'
import AverageCard from '../../../components/card/AverageCard/AverageCard'
import RegisteredKPIsComparativeChart from '../../../components/chart/RegisteredKPIsComparativeChart/RegisteredKPIsComparativeChart'
import useAnonymousFrequency, {
  UseAnonymousFrequencyStateType,
} from '../../../services/anonymous/useAnonymousFrequency'
import TotalRegisteredVisitFrequency from '../../../components/totalRegisteredVisitFrequency/TotalRegisteredVisitFrequency'
import RegisteredKPIsModel from '../../../services/registered/model/RegisteredKPIsModel'
import UseStateType from '../../../services/common/types/UseStateType'
import RegisteredTimeComebackChart from '../../../components/timeComebackChart/RegisteredTimeComebackChart'
import useAnonymousKPIs from '../../../services/anonymous/useAnonymousKPIs'
import useAnonymousKPIsType from '../../../services/anonymous/types/useAnonymousKPIsType'
import RegisteredLinearAndBarVisits from '../../../components/registeredLinearAndBarVisits/RegisteredLinearAndBarVisits'
import RegisteredVisitsFrequencyAverage from '../../../components/registeredVisitsFrequencyAverage/RegisteredVisitsFrequencyAverage'
import useAnonymousAverageVisits from '../../../services/anonymous/useAnonymousAverageVisits'
import RegisteredAverageVisits from '../../../components/registeredAverageVisits/RegisteredAverageVisits'
import TrafficZonesModel from '../../../services/common/model/TrafficZonesModel'
import useAnonymousTraficZones from '../../../services/anonymous/useAnonymousTraficZones'
import useAnonymousAttractionRate from '../../../services/anonymous/useAnonymousAttractionRate'
import useAnonymousDwellTime from '../../../services/anonymous/useAnonymousDwellTime'
import useRegistrations from '../../../services/registered/useRegistrations'
import RegistrationsModel from '../../../services/registered/model/RegistrationsModel'
import RegisteredFrequencyModel from '../../../services/registered/model/RegisteredFrequencyModel'
import { NoLocationsError } from '../../../services/analytics/errors/noLocationsFoundError'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'
import { UserUnauthorizedError } from '../../../services/analytics/errors/UserUnauthorizedError'
import UnauthorizedGroup from '../../../components/unauthorized/UnauthorizedGroup/UnauthorizedGroup'

export default function Registered(): JSX.Element {
  const { translate } = useLocales()

  const title = `${translate('nav:dashboard:registered')}`

  const [selectedLocations, setSelectedLocations] = useState<SelectItemType[]>(
    [],
  )
  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    DatePeriod.YESTERDAY,
  )

  const { organizationId, groupId } = useParams<string>()
  const {
    data: registeredKPIsData,
    error: registeredKPIsError,
    clearError: registeredKPIsClearError,
    loading: isLoadingRegisteredKPIs,
  } = useRegisteredKPIs(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseStateType<RegisteredKPIsModel | undefined>
  const {
    data: registeredFrequencyData,
    loading: isLoadingRegisteredFrequencyData,
    error: registeredFrequencyError,
    clearError: registeredFrequencyClearError,
  } = useRegisteredFrequency(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseStateType<RegisteredFrequencyModel>
  const {
    data: anonymousFrequencyData,
  } = useAnonymousFrequency(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseAnonymousFrequencyStateType
  const {
    data: registeredTrafficZonesData,
    error: registeredTrafficZonesError,
    clearError: registeredTrafficZonesClearError,
    loading: isLoadingRegisteredTrafficZones,
  } = useRegisteredTrafficZones(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<TrafficZonesModel[]>
  const {
    data: anonymousTrafficZonesData,
  } = useAnonymousTraficZones(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as any
  const {
    data: registeredAttractionRateData,
    error: registeredAttractionRateError,
    clearError: registeredAttractionRateClearError,
  } = useRegisteredAttractionRate(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<AttractionRateModel>
  const {
    data: anonymousAttractionRateData,
  } = useAnonymousAttractionRate(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<AttractionRateModel>
  const {
    data: registeredDwellTimeData,
    loading: isLoadingRegisteredDwellTime,
  } = useRegisteredDwellTime(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<DwellTimeModel>
  const { data: anonymousDwellTimeData } =
    useAnonymousDwellTime(
      organizationId!,
      groupId!,
      selectedLocations,
      selectedPeriod,
    ) as UseRegisteredStateType<DwellTimeModel>
  const {
    data: registeredDetectionsData,
    loading: isLoadingDetections,
  } = useRegisteredDetections(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<DetectionsModel[]>
  const {
    data: registeredAverageVisitsData,
  } = useRegisteredAverageVisits(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<AverageVisitsModel>
  const {
    data: anonymousAverageVisitsData,
  } = useAnonymousAverageVisits(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<AverageVisitsModel>
  const {
    data: anonymousKPIsData,
    loading: isLoadingAnonymousKPIs,
  } = useAnonymousKPIs(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as useAnonymousKPIsType
  const {
    data: registrationsData,
    loading: isLoadingRegistrationsData,
    error: registrationsError,
  } = useRegistrations(
    organizationId!,
    selectedLocations,
    selectedPeriod,
  ) as UseRegisteredStateType<RegistrationsModel>

  const [errors, setErrors] = useState<string[]>([])
  const theme = useTheme() as any

  const setError = (error: string) => {
    setErrors(errors => {
      if (errors.includes(error)) {
        return errors
      }

      return [...errors, error]
    })
  }

  useEffect(() => {
    if (registeredKPIsError instanceof RegisteredKpisGenericError) {
      const error = `${translate('analytics:request:kpis:generic:error')}`
      setError(error)
    }

    if (registeredFrequencyError instanceof RegisteredFrequencyGenericError) {
      const error = `${translate('analytics:request:frequency:generic:error')}`
      setError(error)
    }

    if (registeredTrafficZonesError instanceof RegisteredTrafficZonesGenericError) {
      const error = `${translate('analytics:request:traffic:zones:generic:error')}`
      setError(error)
    }

    if (registeredAttractionRateError instanceof RegisteredAttractionRateGenericError) {
      const error = `${translate('analytics:request:attraction:rate:generic:error')}`
      setError(error)
    }

    if (registrationsError instanceof RegisteredRegistrationsGenericError) {
      const error = `${translate('analytics:request:registrations:generic:error')}`
      setError(error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registeredKPIsError,
    registeredFrequencyError,
    registeredTrafficZonesError,
    registeredAttractionRateError,
    registrationsError,
  ])

  const handleChangePeriod = (period: string) => {
    setSelectedPeriod(period)
  }

  const handleChangeLocations = (locations: SelectItemType[]) => {
    setSelectedLocations(locations)
  }

  const getKPIs = (): JSX.Element => {
    if (isLoadingRegisteredKPIs) {
      return <Skeleton loadingData={ANALYTICS_KPI_DATA} />
    }

    if (selectedLocations?.length === 0 && !selectedPeriod) {
      return (
        <NoItemsCard
          title={`${translate('analytics:empty:filter:title')}`}
          description={`${translate('analytics:empty:filter:text')}`}
        />
      )
    }

    return <RegisteredKPIs kpis={registeredKPIsData!} />
  }

  const handleCloseErrors = (): void => {
    registeredKPIsClearError()
    registeredFrequencyClearError()
    registeredTrafficZonesClearError()
    registeredAttractionRateClearError()
    setErrors([])
  }

  const getTotalVisitsChart = (): JSX.Element => {
    if (isLoadingRegisteredTrafficZones) {
      return <Skeleton loadingData={ANALYTICS_TRAFFIC_DATA} />
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TotalVisitsGraph
            detectionsSum={registeredKPIsData?.detections_sum!}
            trafficZones={registeredTrafficZonesData}
            selectedPeriod={selectedPeriod}
          />
        </Grid>
      </Grid>
    )
  }

  const getFrequencyChart = (): JSX.Element => {
    if (isLoadingRegisteredFrequencyData) {
      return <Skeleton loadingData={ANALYTICS_HORIZONTAL_BAR_DATA} />
    }

    return (
      <TotalRegisteredVisitFrequency
        registeredFrequencyData={registeredFrequencyData}
        anonymousFrequencyData={anonymousFrequencyData}
      />
    )
  }

  const getTimeComebackChart = (): JSX.Element => {
    if (isLoadingRegisteredKPIs) {
      return <Skeleton loadingData={ANALYTICS_HORIZONTAL_BAR_DATA} />
    }

    return (
      <RegisteredTimeComebackChart
        registeredAverageTimeComeback={
          registeredKPIsData?.average_time_comeback
        }
        anonymousAverageTimeComeback={anonymousKPIsData?.average_time_comeback}
      />
    )
  }

  const getAttractionFrequencyChart = (): JSX.Element => {
    return (
      <RegisteredAttractionRatioGraph
        registeredRateData={registeredAttractionRateData}
        anonymousRateData={anonymousAttractionRateData}
      />
    )
  }

  const getDwellTimeChart = (): JSX.Element => {
    const title = `${translate('analytics:retention-ratio:graph:title')}`
    const tooltip = `${translate('analytics:retention-ratio:graph:tooltip')}`

    if (isLoadingRegisteredDwellTime) {
      return <Skeleton loadingData={ANALYTICS_HORIZONTAL_BAR_DATA} />
    }

    if (!registeredDwellTimeData) {
      return (
        <EmptyCard
          title={title}
          tooltip={tooltip}
          height={GRAPH_CARD_MIN_HEIGHT}
        />
      )
    }

    return (
      <DwellTimeGraph
        title={title}
        tooltip={tooltip}
        variant={registeredDwellTimeData.dwellTimeDifferenceRate}
        totalLabel={`${translate('analytics:ratio-attraction:graph:label')}`}
        showLegend={true}
        series={[
          {
            value: registeredDwellTimeData?.dwellTimeRate,
            label: `${translate('analytics:registered')}`,
            color: theme.palette.chart.registered,
          },
          {
            value: anonymousDwellTimeData?.dwellTimeRate,
            label: `${translate('analytics:anonymous')}`,
            color: theme.palette.chart.attraction,
          },
        ]}
      />
    )
  }

  const getHeatmap = (): JSX.Element => {
    const title = `${translate('analytics:heatmap:detections:label')}`
    const tooltip = `${translate('analytics:heatmap:detections:tooltip')}`

    if (isLoadingDetections) {
      return <Skeleton loadingData={ANALYTICS_DETECTIONS_DATA} />
    }

    if (!registeredDetectionsData) {
      return <EmptyCard title={title} height={500} tooltip={tooltip} />
    }

    const maxValue = Math.max(
      ...registeredDetectionsData?.flatMap((item: { data: any[] }) =>
        item.data.map(dataPoint => dataPoint.y),
      ),
    )

    return (
      <HeatmapWeekSelector
        title={title}
        tooltip={tooltip}
        maxValue={maxValue}
        data={registeredDetectionsData}
      />
    )
  }

  const getComparativeKpis = (): JSX.Element => {
    if (isLoadingRegisteredKPIs || isLoadingAnonymousKPIs) {
      return <Skeleton loadingData={ANALYTICS_KPI_COLUMN_DATA} />
    }

    return (
      <RegisteredKPIsComparativeChart
        registeredKPIsData={registeredKPIsData}
        anonymousKPIsData={anonymousKPIsData}
      />
    )
  }

  const getTotalRegisteredCard = (): JSX.Element => {
    const title = `${translate('total registros')}`
    const tooltip = `${translate('tooltip')}`

    if (isLoadingRegistrationsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }

    if (!registrationsData) {
      return (
        <EmptyCard title={title} tooltip={tooltip} />
      )
    }

    return (
      <AverageCard
        title={title}
        value={registrationsData.totalRegistrations.value}
        variation={registrationsData.totalRegistrations.rate}
        tooltip={tooltip}
      />
    )
  }

  const getRegisteredCard = (): JSX.Element => {
    const title = `${translate('Registrados')}`
    const tooltip = `${translate('tooltip')}`

    if (isLoadingRegistrationsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }

    if (!registrationsData) {
      return (
        <EmptyCard title={title} tooltip={tooltip} />
      )
    }

    return (
      <AverageCard
        title={title}
        value={registrationsData.registrations.value}
        variation={registrationsData.registrations.rate}
        tooltip={tooltip}
      />
    )
  }

  const getContent = (): JSX.Element => {
    if (registeredKPIsError instanceof NoLocationsError) {
      return (
        <Typography variant="h4" align="center">
          {`${translate('analytics:no-locations:error:message')}`}
        </Typography>
      )
    }

    if (registeredKPIsError instanceof UserUnauthorizedError) {
      return (
        <UnauthorizedGroup />
      )
    }

    return (
      <>
        <FluxBreadcrumbs currentPath={title} hasPadding />
        <AnalyticsContainer
          title={title}
          errors={errors.join(' ')}
          onChangePeriod={handleChangePeriod}
          onChangeLocation={handleChangeLocations}
          onCloseErrors={handleCloseErrors}
        >
          <Grid container spacing={2}>
            {getKPIs()}
          </Grid>
          {getComparativeKpis()}
          {getTotalVisitsChart()}
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RegisteredLinearAndBarVisits
                    selectedPeriod={selectedPeriod}
                    registeredTrafficZonesData={registeredTrafficZonesData}
                    anonymousTrafficZonesData={anonymousTrafficZonesData}
                  />
                </Grid>
                <Grid item xs={12}>
                  {getFrequencyChart()}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {getAttractionFrequencyChart()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {getDwellTimeChart()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {getTotalRegisteredCard()}
                    </Grid>
                    <Grid item xs={6}>
                      {getRegisteredCard()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <RegisteredAverageVisits
                    registeredVisitsAverage={
                      registeredAverageVisitsData?.currentPeriod || 0
                    }
                    anonymousVisitsAverage={
                      anonymousAverageVisitsData?.currentPeriod || 0
                    }
                    variant={registeredAverageVisitsData?.variation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegisteredVisitsFrequencyAverage
                    registeredVisitsFrequencyAverage={
                      registeredAverageVisitsData?.currentPeriod || 0
                    }
                    anonymousVisitsFrequencyAverage={
                      anonymousAverageVisitsData?.currentPeriod || 0
                    }
                    variant={registeredAverageVisitsData?.variation}
                  />
                </Grid>
                <Grid item xs={12}>
                  {getTimeComebackChart()}
                </Grid>
                <Grid item sm={12}>
                  {getHeatmap()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AnalyticsContainer>
      </>
    )
  }

  return getContent()
}
