const campaignInfoFormStyle = {
  container: {
    display: 'flex',
    gap: 2,
  },
  field: {
    mb: 1,
  },
  stack: {
    pl: 1,
  },
}

export default campaignInfoFormStyle
