import { Box, Card, Typography, useTheme } from '@mui/material'
import DonutChart from '../chart/DonutChart/DonutChart'
import style from './TotalRegisteredVisitFrequencyStyle'
import InfoTooltip from '../infoTooltip/InfoTooltip'
import RegisteredFrequencyModel from '../../services/registered/model/RegisteredFrequencyModel'
import { useLocales } from '../../locales'

export default function TotalRegisteredVisitFrequency({
  anonymousFrequencyData,
  registeredFrequencyData,
}: {
  anonymousFrequencyData: RegisteredFrequencyModel | undefined
  registeredFrequencyData: RegisteredFrequencyModel | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  return (
    <Card sx={style.container}>
      <Box sx={style.titleContainer}>
        <Typography variant="h5">
          {`${translate('registered:kpis:chart:total-registered-frequency')}`}
        </Typography>
        <InfoTooltip info="TODO" />
      </Box>
      <Box sx={style.donutContainer}>
        <Box sx={style.chartBox}>
          <Typography variant="h6" sx={style.chartTitle}>
            Registrados
          </Typography>
          <DonutChart
            height={250}
            data={[
              {
                color: theme.palette.chart.light.secondZone,
                value: registeredFrequencyData?.one || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:one-visit',
                )}`,
              },
              {
                color: theme.palette.chart.light.firstZone,
                value: registeredFrequencyData?.two || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:two-visits',
                )}`,
              },
              {
                color: theme.palette.chart.light.cashRegisters,
                value: registeredFrequencyData?.three_plus || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:three-or-more-visits',
                )}`,
              },
            ]}
          />
        </Box>
        <Box sx={style.chartBox}>
          <Typography variant="h6" sx={style.chartTitle}>
            Anónimos
          </Typography>
          <DonutChart
            height={250}
            data={[
              {
                color: theme.palette.chart.light.secondZone,
                value: anonymousFrequencyData?.one || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:one-visit',
                )}`,
              },
              {
                color: theme.palette.chart.light.firstZone,
                value: anonymousFrequencyData?.two || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:two-visits',
                )}`,
              },
              {
                color: theme.palette.chart.light.cashRegisters,
                value: anonymousFrequencyData?.three_plus || 0,
                label: `${translate(
                  'registered:kpis:chart:frequency:three-or-more-visits',
                )}`,
              },
            ]}
          />
        </Box>
      </Box>
    </Card>
  )
}
