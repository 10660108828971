import { LONG_CARD_MIN_HEIGHT } from "../../../../style/components/GridStyle";
import HorizontalBarGraph from "../../../HorizontalBarGraph/HorizontalBarGraph";
import EmptyCard from "../../EmptyCard/EmptyCard";
import ChartCard from "../ChartCard/ChartCard";

export default function HorizontalBarChartCard({
  title,
  tooltip,
  maxValue,
  variant,
  series,
  isDataEmpty,
  axisYTooltipFormatter,
}: {
  title: string,
  tooltip: string,
  maxValue?: number
  variant?: number | undefined,
  series: { name: string; data: number, color?: string }[]
  isDataEmpty?: boolean,
  axisYTooltipFormatter?: (value: string) => string
}) {
  if (isDataEmpty) {
    return <EmptyCard title={title} tooltip={tooltip} height={LONG_CARD_MIN_HEIGHT} />
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltip}
      variant={variant}
      height={LONG_CARD_MIN_HEIGHT}
    >
      <HorizontalBarGraph
        series={series}
        maxValue={maxValue}
        axisYTooltipFormatter={axisYTooltipFormatter}
      />
    </ChartCard>
  )
}
