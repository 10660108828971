import GaugeGraph from '../gaugeGraph/GaugeGraph'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import { GRAPH_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import ChartCard from '../card/Chart/ChartCard/ChartCard'
import AttractionRateModel from '../../services/common/model/AttractionRateModel'
import { useTheme } from '@mui/material'

export default function AnonymousAttractionRatioGraph({
  rateData,
}: {
  rateData: AttractionRateModel | undefined,
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const tooltipText = `${translate('analytics:ratio-attraction:graph:tooltip')}`
  const title = `${translate('analytics:ratio-attraction:graph:title')}`
  const totalLabel = `${translate('analytics:ratio-attraction:graph:label')}`

  const series = [
    {
      value: rateData?.current.internal || 0,
      label: `${translate('anonymous:visits:graph:unique')}`,
      color: theme.palette.chart.attraction,
    },
  ]

  const isSeriesEmpty = series.every(serie => !serie.value)

  if (isSeriesEmpty) {
    return <EmptyCard tooltip={tooltipText} title={title}  height={GRAPH_CARD_MIN_HEIGHT} />
  }

  const getMaxValue = (): number => {
    return rateData?.current.external || Math.max(rateData!.current.internal)
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltipText}
      variant={rateData?.variation}
      height={GRAPH_CARD_MIN_HEIGHT}
    >
      <GaugeGraph series={series} maxValue={getMaxValue()} totalLabel={totalLabel} showTotal={true} />
    </ChartCard>
  )
}
