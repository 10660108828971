import FluxBreadcrumbs from "../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs";
import { useLocales } from "../../../locales";
import EditUser from "../EditUser";

export default function EditOrganizationUser() {
  const { translate } = useLocales()

  return (
    <>
      <FluxBreadcrumbs currentPath={`${translate('users:edit')}`} hasPadding />
      <EditUser isOrganizationView />
    </>
  )
}
