const RegisterCampaignInfoStyle = {
  formContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    maxHeight: 200,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}

export default RegisterCampaignInfoStyle
