import { useTheme } from '@mui/material'
import HorizontalBarChartCard from '../card/Chart/HorizontalBarChartCard/HorizontalBarChartCard'
import { useLocales } from '../../locales'

export default function RegisteredVisitsFrequencyAverage({
  registeredVisitsFrequencyAverage,
  anonymousVisitsFrequencyAverage,
  variant,
}: {
  registeredVisitsFrequencyAverage: number
  anonymousVisitsFrequencyAverage: number
  variant?: number,
}) {
  const theme = useTheme() as any
  const { translate } = useLocales()

  return (
    <HorizontalBarChartCard
      title={`${translate('registered:kpis:chart:registered-visits-frequency-average:title')}`}
      tooltip={`${translate('analytics:kpis:frequency-visits-average:tooltip')}`}
      variant={variant}
      series={[
        {
          name: `${translate(
            'registered:kpis:chart:total-anonymous-registered:registered:label',
          )}`,
          data: registeredVisitsFrequencyAverage,
          color: theme.palette.chart.light.attraction,
        },
        {
          name: `${translate(
            'registered:kpis:chart:total-anonymous-registered:anonymous:label',
          )}`,
          data: anonymousVisitsFrequencyAverage,
          color: theme.palette.chart.light.firstZone,
        },
      ]}
    />
  )
}
