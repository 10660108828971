import { useTheme } from '@mui/material'
import HorizontalBarChartCard from '../card/Chart/HorizontalBarChartCard/HorizontalBarChartCard'
import { useLocales } from '../../locales'

export default function RegisteredAverageVisits({
  registeredVisitsAverage,
  anonymousVisitsAverage,
  variant,
}: {
  registeredVisitsAverage: number
  anonymousVisitsAverage: number
  variant?: number,
}) {
  const theme = useTheme() as any
  const { translate } = useLocales()

  return (
    <HorizontalBarChartCard
      title={`${translate('analytics:kpis:visits-average')}`}
      tooltip={`${translate('analytics:kpis:visits-average:tooltip')}`}
      variant={variant}
      series={[
        {
          name: `${translate(
            'registered:kpis:chart:total-anonymous-registered:registered:label',
          )}`,
          data: registeredVisitsAverage,
          color: theme.palette.chart.light.attraction,
        },
        {
          name: `${translate(
            'registered:kpis:chart:total-anonymous-registered:anonymous:label',
          )}`,
          data: anonymousVisitsAverage,
          color: theme.palette.chart.light.firstZone,
        },
      ]}
    />
  )
}
