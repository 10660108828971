import { Card, Grid, Stack, Typography } from '@mui/material'
import style from './KPI_CARD_STYLE'
import { calculateIncrementPercentage } from '../../../utils/mathHelper'
import { TrendingDown, TrendingUp } from '@mui/icons-material'
import { secondsToHoursMinutesSeconds } from '../../../utils/formatTime'
import GridStyle, { SMALL_CARD_MIN_HEIGHT } from '../../../style/components/GridStyle'
import InfoTooltip from '../../infoTooltip/InfoTooltip'
import EmptyCard from '../EmptyCard/EmptyCard'
import { useLocales } from '../../../locales'
import { KPIsModel } from '../../../services/common/model/KPIsModel'
import { ZoneDataInterface } from '../../../services/common/model/AnalyticsZonesInterface'

export default function KPI_CARD({
  zoneNumber,
  kpis,
  tooltip,
}: {
  zoneNumber: number
  kpis: KPIsModel[],
  tooltip?: string
}): JSX.Element {
  const { translate } = useLocales()

  const getIncrementIcon = (zone: ZoneDataInterface): JSX.Element => {
    return zone.current > zone.previous ? (
      <TrendingUp sx={style.upIcon} />
    ) : (
      <TrendingDown sx={style.downIcon} />
    )
  }

  const getNoCurrentData = (text: string) => {
    return (
      <Typography variant="body2" sx={style.textZone}>
        {`${text} ${translate('common:no:data')}`}
      </Typography>
    )
  }

  const getNoPreviousData = (text: string, zone: ZoneDataInterface, isTimeCalc?: boolean) => {
    return (
      <Stack sx={style.incrementBlock}>
        <Typography variant="body2" sx={style.textZone}>
          {isTimeCalc
            ? secondsToHoursMinutesSeconds(zone.current)
            : zone.current}{' '}
          {text}
        </Typography>
      </Stack>
    )
  }

  const getIncrementPercentage = (zone: ZoneDataInterface, isTimeCalc?: boolean): JSX.Element => {
    if (isTimeCalc) {
      return <></>
    }

    return (
      <Typography variant="subtitle1">
        ({calculateIncrementPercentage(zone.current, zone.previous)})
      </Typography>
    )
  }

  const getIncrementData = (text: string, zone: ZoneDataInterface, isTimeCalc?: boolean) => {
    return (
      <Stack sx={style.incrementBlock}>
        {getIncrementIcon(zone)}
        <Typography variant="body2" sx={style.textZone}>
          {isTimeCalc
            ? secondsToHoursMinutesSeconds(zone.current)
            : zone.current}{' '}
          {text}
        </Typography>
        {getIncrementPercentage(zone, isTimeCalc)}
      </Stack>
    )
  }

  const getTitle = (): string => {
    return  kpis[0]?.zones[zoneNumber]?.name
  }

  const hasData = (): boolean => {
    for (const kpi of kpis) {
      if (kpi.zones[zoneNumber] !== undefined) {
        return true
      }
    }

    return false
  }

  const getDataBlock = (text: string, zone: ZoneDataInterface, isTimeCalc?: boolean): JSX.Element => {
    if (!Boolean(zone?.current)) {
      return getNoCurrentData(text)
    }

    if (!Boolean(zone?.previous)) {
      return getNoPreviousData(text, zone, isTimeCalc)
    }

    return getIncrementData(text, zone, isTimeCalc)
  }

  const getDataContainer = () => {
    return kpis.map((kpis: KPIsModel, index: number) => {
      return <Grid item key={index}>
        {getDataBlock(`${translate(kpis.label)}`, kpis.zones[zoneNumber], kpis.isTimeKpi)}
      </Grid>
    })
  }

  const getCardContent = (): JSX.Element => {
    if (!hasData()) {
      return (
        <EmptyCard title={getTitle()} tooltip={tooltip} height={SMALL_CARD_MIN_HEIGHT}/>
      )
    }

    const getTooltip = (): JSX.Element => {
      if (!tooltip) {
        return <></>
      }

      return <InfoTooltip info={tooltip} />
    }

    return (
      <>
        <Card sx={[style.container, GridStyle.smallCard]}>
          <Grid container sx={style.gridContainer} spacing={2}>
            <Grid item sx={style.titleContainer}>
              <Typography variant="subtitle2">{getTitle()}</Typography>
              {getTooltip()}
            </Grid>
            {getDataContainer()}
          </Grid>
        </Card>
      </>
    )
  }

  return <>{getCardContent()}</>
}
