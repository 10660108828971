/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Stack, Typography, useTheme } from '@mui/material'
import Skeleton from '../../../components/skeleton/Skeleton'
import {
  ANALYTICS_KPI_DATA,
  ANALYTICS_TRAFFIC_DATA,
  AVERAGE_DETECTIONS_DATA,
  ANALYTICS_DETECTIONS_DATA,
} from '../../../utils/dashboardsConstants'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import useAnonymousKPIs from '../../../services/anonymous/useAnonymousKPIs'
import AnonymousKPIs from '../../../components/anonymous/AnonymousKPIs/AnonymousKPIs'
import { useEffect, useState } from 'react'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import { SelectItemType } from '../../../services/common/types/SelectItemType'
import DatePeriod from '../../../services/common/enum/DatePeriod'
import useAnonymousTrafficZones from '../../../services/anonymous/useAnonymousTraficZones'
import useAnonymousFrequency, {
  UseAnonymousFrequencyStateType,
} from '../../../services/anonymous/useAnonymousFrequency'
import EmptyCard from '../../../components/card/EmptyCard/EmptyCard'
import useAnonymousDetections from '../../../services/anonymous/useAnonymousDetections'
import HeatmapWeekSelector from '../../../components/HeatmapWeekSelector/HeatmapWeekSelector'
import useAnonymousKPIsType from '../../../services/anonymous/types/useAnonymousKPIsType'
import AnonymousTimeComebackChart from '../../../components/timeComebackChart/AnonymousTimeComebackChart'
import useAnonymousDwellTime from '../../../services/anonymous/useAnonymousDwellTime'
import useAnonymousAttractionRate from '../../../services/anonymous/useAnonymousAttractionRate'
import UseStateType from '../../../services/common/types/UseStateType'
import AverageCard from '../../../components/card/AverageCard/AverageCard'
import useAnonymousAverageVisits from '../../../services/anonymous/useAnonymousAverageVisits'
import useAnonymousAverageFrequencyVisits from '../../../services/anonymous/useAnonymousAverageFrecuencyVisits'
import TotalVisitsGraph from '../../../components/totalVisitsGraph/TotalVisitsGraph'
import DwellTimeGraph from '../../../components/dwellTimeGraph/DwellTimeGraph'
import AnonymousTotalVisitsFrequencyGraph from '../../../components/totalVisitsFrequencyGraph/AnonymousTotalVisitsFrequencyGraph'
import { NoLocationsError } from '../../../services/analytics/errors/noLocationsFoundError'
import { GRAPH_CARD_MIN_HEIGHT } from '../../../style/components/GridStyle'
import AnalyticsContainer from '../../../components/container/AnalyticsContainer/AnalyticsContainer'
import { AnonymousGenericError } from '../../../services/anonymous/anonymousError'
import AttractionRateModel from '../../../services/common/model/AttractionRateModel'
import AnonymousAttractionRatioGraph from '../../../components/attractionRatioGraph/AnonymousAttractionRatioGraph'
import DwellTimeModel from '../../../services/common/model/DwellTimeModel'
import AverageVisitsModel from '../../../services/registered/model/AverageVisitModel'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'
import { UserUnauthorizedError } from '../../../services/analytics/errors/UserUnauthorizedError'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import UnauthorizedGroup from '../../../components/unauthorized/UnauthorizedGroup/UnauthorizedGroup'

export default function Anonymous(): JSX.Element {
  const { translate } = useLocales()

  const title = `${translate('nav:dashboard:anonymous')}`

  const [selectedLocations, setSelectedLocations] = useState<SelectItemType[]>(
    [],
  )
  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    DatePeriod.YESTERDAY,
  )

  const { organizationId, groupId } = useParams<string>()
  const {
    data: anonymousKPIsData,
    error: anonymousKPIsError,
    clearError: anonymousKPIsClearError,
    loading: isLoadingKPIs,
  } = useAnonymousKPIs(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as useAnonymousKPIsType
  const { data: anonymousDwellTimeData, loading: isLoadingDwellTime } =
    useAnonymousDwellTime(
      organizationId!,
      groupId!,
      selectedLocations,
      selectedPeriod,
    ) as UseStateType<DwellTimeModel>
  const {
    data: anonymousTrafficZonesData,
    error: anonymousTrafficZonesError,
    clearError: anonymousTrafficZonesClearError,
    loading: isLoadingTrafficZones,
  } = useAnonymousTrafficZones(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as any
  const {
    data: anonymousDetectionsData,
    error: anonymousDetectionsError,
    clearError: anonymousDetectionsClearError,
    loading: isLoadingDetections,
  } = useAnonymousDetections(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as any
  const {
    data: anonymousFrequencyData,
    loading: isLoadingAnonymousFrequencyData,
    error: anonymousFrequencyError,
  } = useAnonymousFrequency(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseAnonymousFrequencyStateType
  const {
    data: anonymousAttractionRateData,
    error: anonymousAttractionRateError,
    clearError: anonymousAttractionRateClearError,
  } = useAnonymousAttractionRate(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseStateType<AttractionRateModel>
  const {
    data: anonymousAverageVisitsData,
    loading: isLoadingAnonymousAverageVisitsData,
    error: anonymousAverageVisitsError,
  } = useAnonymousAverageVisits(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseStateType<AverageVisitsModel>
  const {
    data: anonymousAverageFrequencyVisitsData,
    loading: isLoadingAnonymousAverageFrequencyVisitsData,
    error: anonymousAverageFrequencyVisitsError,
  } = useAnonymousAverageFrequencyVisits(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as UseStateType<AverageVisitsModel>
  const [errors, setErrors] = useState<string[]>([])
  const theme = useTheme() as any

  useEffect(() => {
    if (anonymousKPIsError instanceof AnonymousGenericError) {
      const temporalError = `${translate('analytics:request:generic:error')}`
      setErrors((errors) => {
        if (errors.includes(temporalError)) {
          return errors;
        }

        return [...errors, temporalError];
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymousKPIsError])

  const handleChangePeriod = (period: string) => {
    setSelectedPeriod(period)
  }

  const handleChangeLocations = (locations: SelectItemType[]) => {
    setSelectedLocations(locations)
  }

  const getKPIs = (): JSX.Element => {
    if (isLoadingKPIs) {
      return <Skeleton loadingData={ANALYTICS_KPI_DATA} />
    }

    if (selectedLocations?.length === 0 && !selectedPeriod) {
      return (
        <NoItemsCard
          title={`${translate('anonymous:empty:filter:title')}`}
          description={`${translate('anonymous:empty:filter:text')}`}
        />
      )
    }

    return <AnonymousKPIs kpis={anonymousKPIsData!} />
  }


  const getTrafficChart = (): JSX.Element => {
    return (
      <TotalVisitsGraph
        detectionsSum={anonymousKPIsData?.detections_sum!}
        trafficZones={anonymousTrafficZonesData}
        selectedPeriod={selectedPeriod}
      />
    )
  }

  const getChartBlock = (): JSX.Element => {
    if (isLoadingTrafficZones) {
      return <Skeleton loadingData={ANALYTICS_TRAFFIC_DATA} />
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {getTrafficChart()}
        </Grid>
      </Grid>
    )
  }

  const getFrequencyChart = (): JSX.Element => {
    if (isLoadingAnonymousFrequencyData) {
      return <></>
    }

    return <AnonymousTotalVisitsFrequencyGraph frequencyData={anonymousFrequencyData} />
  }

  const getAttractionFrequencyChart = (): JSX.Element => {
    return <AnonymousAttractionRatioGraph rateData={anonymousAttractionRateData} />
  }

  const getDwellTimeChart = (): JSX.Element => {
    const title = `${translate('analytics:retention-ratio:graph:title')}`
    const tooltip = `${translate('analytics:retention-ratio:graph:tooltip')}`

    if (isLoadingDwellTime || !anonymousDwellTimeData) {
      return <EmptyCard title={title} tooltip={tooltip} height={GRAPH_CARD_MIN_HEIGHT} />
    }

    return (
      <DwellTimeGraph
        title={title}
        tooltip={tooltip}
        variant={anonymousDwellTimeData.dwellTimeDifferenceRate}
        showTotal={true}
        series={[
          {
            value: anonymousDwellTimeData.dwellTimeRate,
            label: `${translate('analytics:retention-ratio:graph:info')}`,
            color: theme.palette.chart.registered,
          },
        ]}
        totalLabel={`${translate('analytics:ratio-attraction:graph:label')}`}
        showLegend={false}
      />
    )
  }

  const getHeatmap = (): JSX.Element => {
    const title = `${translate('analytics:heatmap:detections:label')}`
    const tooltip = `${translate('analytics:heatmap:detections:tooltip')}`

    if (isLoadingDetections) {
      return <Skeleton loadingData={ANALYTICS_DETECTIONS_DATA} />
    }

    if (!anonymousDetectionsData) {
      return (
        <EmptyCard
          title={title}
          height={500}
          tooltip={tooltip}
        />
      )
    }

    const maxValue = Math.max(
      ...anonymousDetectionsData?.flatMap((item: { data: any[] }) =>
        item.data.map(dataPoint => dataPoint.y),
      ),
    )

    return (
      <HeatmapWeekSelector
        title={title}
        tooltip={tooltip}
        maxValue={maxValue}
        data={anonymousDetectionsData}
      />
    )
  }

  const getAverageVisitsCard = (): JSX.Element => {
    const title = `${translate('analytics:kpis:visits-average')}`
    const tooltip = `${translate('analytics:kpis:visits-average:tooltip')}`

    if (isLoadingAnonymousAverageVisitsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }

    if (!anonymousAverageVisitsData) {
      return <EmptyCard title={title} tooltip={tooltip} />
    }

    return (
      <AverageCard
        title={title}
        value={anonymousAverageVisitsData?.currentPeriod}
        variation={anonymousAverageVisitsData.variation}
        tooltip={tooltip}
      />
    )
  }

  const getAverageFrequencyVisitsCard = (): JSX.Element => {
    const title = `${translate('analytics:kpis:frequency-visits-average')}`
    const tooltip = `${translate('analytics:kpis:frequency-visits-average:tooltip')}`

    if (isLoadingAnonymousAverageFrequencyVisitsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }

    if (!anonymousAverageFrequencyVisitsData) {
      return (
        <EmptyCard title={title} tooltip={tooltip} />
      )
    }

    return (
      <AverageCard
        title={title}
        value={anonymousAverageFrequencyVisitsData?.currentPeriod}
        variation={anonymousAverageFrequencyVisitsData.variation}
        tooltip={tooltip}
      />
    )
  }

  const handleCloseErrors = (): void => {
    setErrors([])
    anonymousKPIsClearError()
  }

  const getContent = (): JSX.Element => {
    if (anonymousKPIsError instanceof NoLocationsError) {
      return (
        <Typography variant="h4" align="center">
          {`${translate('analytics:no-locations:error:message')}`}
        </Typography>
      )
    }

    if (anonymousKPIsError instanceof UserUnauthorizedError) {
      return (
        <UnauthorizedGroup />
      )
    }

    return (
      <>
        <FluxBreadcrumbs currentPath={title} hasPadding />
        <AnalyticsContainer
          title={title}
          errors={errors.join(' ')}
          onChangePeriod={handleChangePeriod}
          onChangeLocation={handleChangeLocations}
          onCloseErrors={handleCloseErrors}
        >
          <Grid container spacing={2}>
            {getKPIs()}
          </Grid>
          {getChartBlock()}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {getFrequencyChart()}
                </Grid>
                <Grid item xs={12}>
                  <AnonymousTimeComebackChart averageTimeComeback={anonymousKPIsData?.average_time_comeback} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {getAttractionFrequencyChart()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {getDwellTimeChart()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  {getHeatmap()}
                </Grid>
                <Grid item sm={6}>
                  {getAverageVisitsCard()}
                </Grid>
                <Grid item sm={6}>
                  {getAverageFrequencyVisitsCard()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AnalyticsContainer>
      </>
    )
  }

  return getContent()
}
