import { Container } from "@mui/material";
import { useSettingsContext } from "../../../components/settings";
import style from './ViewContainerStyle'

export default function ViewContainer({
  children,
} : {
  children: React.ReactNode,
}) {
  const { themeStretch } = useSettingsContext()

  return (
    <Container maxWidth={themeStretch ? false : 'xl'} sx={style.container}>
      {children}
    </Container>
  )
}
