import { Box, useTheme } from '@mui/material'
import Chart from 'react-apexcharts'
import style from './SemiGaugeGraphStyle'
import i18n from '../../locales/i18n'

export default function SemiGaugeGraph({
  series,
  totalLabel = `${i18n.t('analytics:default:total:label')}`,
  showLegend = false,
  showTotal = false,
}: {
  series: {
    value: number
    label: string
    color?: string
  }[],
  totalLabel?: string,
  showLegend?: boolean,
  showTotal?: boolean,
}) {
  const theme = useTheme() as any

  const normalizedSeries = series.map(serie => ({
    ...serie,
    value: (serie.value),
  }))

  const chartOptions = {
    legend: {
      show: showLegend,
      position: 'top',
    },
    chart: {
      height: 30,
      type: 'radialBar',
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          name: {
            fontSize: '12px',
            offsetY: 10,
          },
          value: {
            offsetY: -30,
            fontSize: '22px',
            color: 'black',
          },
          total: {
            show: showTotal,
            label: totalLabel,
            color: theme.palette.chart.totalLabel,
            fontSize: '12px',
            formatter: function (w: any) {
              return w.globals.seriesTotals[0] + '%'
            }
          }
        },
      },
      bar: {
        borderRadius: 4,
        columnWidth: '28%',
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'last',
      },
    },
    fill: {
      colors: series.map(serie => serie.color),

      fill: {
        type: 'solid',
      },
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round',
    },
    labels: series.map(serie => serie.label),
  }

  return (
    <Box sx={style.graphContainer}>
      {/* @ts-ignore */}
      <Chart
        type="radialBar"
        options={chartOptions}
        series={normalizedSeries.map(serie => serie.value)}
      />
    </Box>
  )
}
