import i18n from "../../../locales/i18n"

const TABLE_HEADER = [
  { id: 'foreignID', label: 'ID', align: 'left' },
  { id: 'organization', label: i18n.t('nodes:table:organization'), align: 'left' },
  { id: 'location', label: i18n.t('nodes:table:location'), align: 'left' },
  { id: 'zone', label: i18n.t('nodes:table:zone'), align: 'left', width: 'left' },
  { id: 'status', label: i18n.t('nodes:table:status'), align: 'center', width: 'left' },
  { id: 'actions', label: '', align: 'center', width: '2.5rem' },

]

export default TABLE_HEADER;
