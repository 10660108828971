import { useEffect, useState, useCallback } from 'react'
import UseStateType from '../common/types/UseStateType'
import { fetchPublicCampaign } from './campaignsService'
import PublicCampaignInfoModel from './model/publicCampaignInfoModel'

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: null,
}

type UserPublicOrganizationStateType = {
  data: PublicCampaignInfoModel | undefined,
  loading: boolean,
  error: Error | null,
}

function usePublicCampaign(organizationId: string, campaignId: string): UseStateType<PublicCampaignInfoModel | undefined> {
  const [state, setState] = useState<UserPublicOrganizationStateType>(INITIAL_STATE)

  const fetchData = useCallback(async () => {
    if (organizationId === undefined) {
      setState(INITIAL_STATE)
      return
    }

    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchPublicCampaign(organizationId, campaignId)

      setState({
        data: response.data,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [campaignId, organizationId])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    clearError,
    refetch: fetchData,
  }
}

export default usePublicCampaign
