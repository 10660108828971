import { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import Iconify from '../../components/iconify'
import CampaignFormNodeTable from '../../components/CampaignFormNodeTable/CampaignFormNodeTable'
import FormSectionCard from '../card/FormSectionCard/FormSectionCard'
import { EventTargetType } from '../../hooks/types/EventTargetType'
import { InputLabel, MenuItem, Select } from '@mui/material'
import campaignInfoFormStyle from './CampaignInfoFormStyle'
import i18n from '../../locales/i18n'
import { parseISO } from 'date-fns'

enum SelectEnum {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

const selectNetworks = [
  {
    value: SelectEnum.ZERO,
    label: `${i18n.t('register:campaign:channel')} 0`,
  },
  {
    value: SelectEnum.ONE,
    label: `${i18n.t('register:campaign:channel')} 1`,
  },
  {
    value: SelectEnum.TWO,
    label: `${i18n.t('register:campaign:channel')} 2`,
  },
  {
    value: SelectEnum.THREE,
    label: `${i18n.t('register:campaign:channel')} 3`,
  },
  {
    value: SelectEnum.FOUR,
    label: `${i18n.t('register:campaign:channel')} 4`,
  },
]

export default function CampaignInfoForm({
  form,
  completed = false,
}: {
  form: any
  completed?: boolean
}) {
  const { translate } = useLocales()
  const IconifyComponent = Iconify as any
  const [select, setSelect] = useState<string>('')

  useEffect(() => {
    if (form.values.wifiNetwork) {
      setSelect(form.values.wifiNetwork)
    }
  }, [form.values.wifiNetwork])

  const handleChangeSelect = (event: EventTargetType) => {
    setSelect(event.target.value)
    form.values.wifiNetwork = event.target.value
  }

  const getSelectItems = (): JSX.Element[] => {
    return selectNetworks.map((item, index) => {
      return (
        <MenuItem value={item.value} key={`${index}-${item.value}`}>
          {item.label}
        </MenuItem>
      )
    })
  }

  const getSelect = (): JSX.Element => {
    const label = translate('new:campaign:wifi:web')
    return (
      <FormControl fullWidth>
        <InputLabel id="select-label">{`${label}`}</InputLabel>
        <Select
          fullWidth
          labelId="select-label"
          id="select-id"
          value={select}
          onChange={handleChangeSelect}
          size="medium"
          label={`${label}`}
          disabled={Boolean(form.values.nodes.length)}
        >
          {getSelectItems()}
        </Select>
      </FormControl>
    )
  }

  const getParsedDate = (date: any) => {
    return date instanceof Date ? date : parseISO(date)
  }

  const getStartDate = () => {
    if (form.values.start) {
      return getParsedDate(form.values.start)
    }
    return null
  }

  const getEndDate = () => {
    if (form.values.end) {
      return getParsedDate(form.values.end)
    }
    return null
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('campaings:create:information_section:title')}`}
          subtitle={`${translate(
            'campaings:create:information_section:subtitle',
          )}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormSectionCard>
          <form>
            <FormControl sx={campaignInfoFormStyle.container}>
              <TextField
                fullWidth
                variant="outlined"
                disabled={completed}
                sx={campaignInfoFormStyle.field}
                name="name"
                label={`${translate('new-campaign:form-name-label')}`}
                value={form.values.name}
                onChange={form.handleFieldChange}
                error={form.errors.name !== ''}
                helperText={form.errors.name}
              />

              <TextField
                fullWidth
                variant="outlined"
                disabled={completed}
                sx={campaignInfoFormStyle.field}
                name="description"
                label={`${translate('new-campaign:form-description-label')}`}
                value={form.values.description}
                onChange={form.handleFieldChange}
                error={form.errors.description !== ''}
                helperText={form.errors.description}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={campaignInfoFormStyle.field}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label={`${translate('new-campaign:form-start-label')}`}
                    disabled={completed}
                    format="dd-MM-yyyy HH:mm"
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: form.errors.start !== '',
                        helperText: form.errors.start,
                      },
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={getStartDate()}
                    minDate={getParsedDate(new Date().toISOString())}
                    minTime={getParsedDate(new Date().toISOString())}
                    disableIgnoringDatePartForTimeValidation
                    maxDate={getParsedDate(form.values.end)}
                    onChange={date => form.handleDateChange('start', date)}
                  />
                  <DateTimePicker
                    label={`${translate('new-campaign:form-end-label')}`}
                    disabled={completed}
                    format="dd-MM-yyyy HH:mm"
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: form.errors.end !== '',
                        helperText: form.errors.end,
                      },
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={getEndDate()}
                    minDate={getParsedDate(form.values.start)}
                    minTime={getParsedDate(form.values.start)}
                    disableIgnoringDatePartForTimeValidation
                    onChange={date => {
                      form.handleDateChange('end', date)
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {getSelect()}
                </Grid>
              </Grid>
              <Stack
                direction="row"
                sx={campaignInfoFormStyle.stack}
                color="grey.600"
              >
                <IconifyComponent icon="eva:info-fill" />
                <Typography marginLeft="2px">
                  <>{translate('campaigns:create:caption')}</>
                </Typography>
              </Stack>
              <CampaignFormNodeTable form={form} selectedNetwork={select} />
            </FormControl>
          </form>
        </FormSectionCard>
      </Grid>
    </Grid>
  )
}
