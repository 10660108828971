import { LoyaltyCampaignsModel } from '../loyaltyCampaigns/model/LoyaltyCampaignsModel';

export function adaptLoyaltyCampaignsResponse(response: any): LoyaltyCampaignsModel[] {
  return response.map((campaign: any) => {
    return new LoyaltyCampaignsModel(
      campaign.uuid,
      campaign.name,
      campaign.description,
      campaign.nodes,
      campaign.start,
      campaign.end,
      campaign.text,
      campaign.imageUrl,
      campaign.status,
      campaign.paused,
      campaign.messagesCount,
    )
  })
}
