import { Helmet } from 'react-helmet-async'
import { Grid, Typography } from '@mui/material'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import FilterBar from '../../../components/FilterBar/FilterBar'
import { SelectItemType } from '../../../services/common/types/SelectItemType'
import style from './AnalyticsContainerStyle'
import SnackbarAlert from '../../alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../alert/SnackbarAlert/SnackBarAlertTypes'

export default function AnalyticsContainer({
  title,
  children,
  errors,
  onChangePeriod,
  onChangeLocation,
  onCloseErrors,
} : {
  title: string,
  children: React.ReactNode,
  errors: string,
  onChangePeriod: (period: string) => void
  onChangeLocation: (locations: SelectItemType[]) => void
  onCloseErrors: () => void,
}): JSX.Element {
  return (
    <>
      <Helmet>
        <title>{title} | So69</title>
      </Helmet>
      <ViewContainer>
        <Grid container sx={style.container}>
          <Typography variant="h4">
            {title}
          </Typography>
          <FilterBar
            updatePeriod={onChangePeriod}
            updateLocation={onChangeLocation}
          />
          {children}
      </Grid>
      <SnackbarAlert
        isOpen={Boolean(errors)}
        message={errors}
        onClose={onCloseErrors}
        type={SnackBarAlertTypes.ERROR}
      />
    </ViewContainer>
  </>
  )
}
