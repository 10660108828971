import { Box, Card, Grid, Typography } from '@mui/material'
import GroupedBarChart from '../GroupedBarChart/GroupedBarChart'
import Spacer from '../../Spacer/Spacer'
import style from './RegisteredKPIsComparativeChartStyle'
import RegisteredKPIsModel from '../../../services/registered/model/RegisteredKPIsModel'
import AnonymousKPIsModel from '../../../services/anonymous/model/AnonymousKPIsModel'
import { GroupedBarChartSeriesType } from '../../../services/common/types/GroupedBarChartSeriesType'
import { ZONES } from '../../../utils/zones'
import { useLocales } from '../../../locales'
import InfoTooltip from '../../infoTooltip/InfoTooltip'

export default function RegisteredKPIsComparativeChart({
  registeredKPIsData,
  anonymousKPIsData,
} : {
  registeredKPIsData: RegisteredKPIsModel | undefined,
  anonymousKPIsData:  AnonymousKPIsModel | undefined,
}) {
  const { translate } = useLocales()

  const detectionsSeriesLabels = [
    `${translate('analytics:kpis:visitors:short')}`,
    `${translate('analytics:kpis:visits')}`,
  ]
  const avgLabels = [`${translate('analytics:kpis:avg:time')}`]

  const ComparativeCard = ({ children }: { children: JSX.Element }): JSX.Element => {
    return (
      <Card sx={style.card}>
        {children}
      </Card>
    )
  }

  const anonymousLabel =  `${translate('analytics:anonymous')}`
  const registeredLabel =  `${translate('analytics:registered')}`

  const getDetectionsSeries = (zone: number): GroupedBarChartSeriesType[] => {
    const detectionsSeries = [{
      name: anonymousLabel,
      data: [anonymousKPIsData?.unique_detections_sum[zone]?.current ?? 0, anonymousKPIsData?.detections_sum[zone]?.current ?? 0]
    }, {
      name: registeredLabel,
      data: [registeredKPIsData?.unique_detections_sum[zone]?.current ?? 0, registeredKPIsData?.detections_sum[zone]?.current ?? 0]
    }]

    return detectionsSeries
  }

  const getAvgSeries = (zone: number): GroupedBarChartSeriesType[] => {
    const avgSeries = [{
      name: anonymousLabel,
      data: [anonymousKPIsData?.average_time_stay[zone]?.current ?? 0]
    },{
      name: registeredLabel,
      data: [registeredKPIsData?.average_time_stay[zone]?.current ?? 0]
    }]

    return avgSeries
  }

  const getKPIColumn = (zone: number, tooltip?: string): JSX.Element => {
    return (
      <Grid item xs={12} md={4}>
        <ComparativeCard>
          <>
            <Box sx={style.tooltipContainer}>
              <Typography variant="subtitle2">
                {anonymousKPIsData?.detections_sum[zone]?.name}
              </Typography>
              {tooltip && <InfoTooltip info={tooltip} /> }
            </Box>
            <GroupedBarChart series={getDetectionsSeries(zone)} labels={detectionsSeriesLabels} height={180} />
          </>
        </ComparativeCard>
        <Spacer size={1} />
        <ComparativeCard>
          <GroupedBarChart series={getAvgSeries(zone)} labels={avgLabels} height={140} />
        </ComparativeCard>
      </Grid>
    )
  }

  const tooltipLoremIpsum: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque gravida nunc quis ante imperdiet, id interdum tellus suscipit.'

  return (
    <Grid container spacing={3}>
      {getKPIColumn(ZONES.ZONE_3.value, tooltipLoremIpsum)}
      {getKPIColumn(ZONES.ZONE_2.value, tooltipLoremIpsum)}
      {getKPIColumn(ZONES.ZONE_CHECKOUT.value, tooltipLoremIpsum)}
    </Grid>
  )
}
