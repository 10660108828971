class CreateCampaignError extends Error {
  constructor(error: Error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'CreateCampaignError'
  }
}

class UpdateCampaignError extends Error {
  constructor(error: Error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'UpdateCampaignError'
  }
}

class CampaignEndedError extends Error {
  constructor(error: Error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'CampaignEndedError'
  }
}

class InvalidUuidError extends Error {
  constructor(endpoint: string) {
    super(`The organization or customer uuid is invalid at ${endpoint}`)
  }
}

class CustomerNotFoundInOrganization extends Error {
  constructor(endpoint: string) {
    super(`Customer not fount in organization at ${endpoint}`)
  }
}

class CustomerAlreadyUnsubscribedError extends Error {
  constructor(endpoint: string) {
    super(`Error unsubscribe customer at ${endpoint}`)
  }
}

class CampaignAlreadyExistError extends Error {
  constructor(endpoint: string) {
    super(`Error campaign already exist at ${endpoint}`)
  }
}

class InvalidFieldError extends Error {
  constructor(endpoint: string) {
    super(`Invalid field error at ${endpoint}`)
  }
}

class OrganizationNotFoundError extends Error {
  constructor(endpoint: string) {
    super(`Organization not found at ${endpoint}`)
  }
}

class CampaignNotExistError extends Error {
  constructor(endpoint: string) {
    super(`Campaign not exists at ${endpoint}`)
  }
}

class NodeNotFoundError extends Error {
  constructor(endpoint: string) {
    super(`Node not exists at ${endpoint}`)
  }
}

class CampaignNotBelongOrganizationError extends Error {
  constructor(endpoint: string) {
    super(`Campaign not belong with organization at ${endpoint}`)
  }
}

export {
  CreateCampaignError,
  UpdateCampaignError,
  CampaignEndedError,
  InvalidUuidError,
  CustomerNotFoundInOrganization,
  CustomerAlreadyUnsubscribedError,
  CampaignAlreadyExistError,
  InvalidFieldError,
  OrganizationNotFoundError,
  CampaignNotExistError,
  NodeNotFoundError,
  CampaignNotBelongOrganizationError,
}
