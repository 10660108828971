import { useState } from 'react'
import { endOfDay } from 'date-fns'
import { Stack } from '@mui/material'
import { CalendarMonth } from '@mui/icons-material'
import DateRangePicker, { useDateRangePicker } from '../date-range-picker'
import { FileFilterButton } from '../../sections/dashboard/analytics'
import { isToday } from '../../utils/formatTime'
import { useLocales } from '../../locales'
import DateRangeModel from '../../services/common/model/DateRangeModel'
import DatePeriod from '../../services/common/enum/DatePeriod'
import MonthSelector from '../selector/MonthSelector/MonthSelector'

const SHOW_DATE_PICKER = false // Remove when activate datepicker

export default function DatesBarFilter({
  updatePeriod,
  updateDateRange,
}: {
  updatePeriod?: (period: string) => void,
  updateDateRange?: (periodDates: DateRangeModel) => void,
}) {
  const { translate } = useLocales()
  const [period, setPeriod] = useState<string>(DatePeriod.YESTERDAY)
  const [selectedMonth, setSelectedMonth] = useState('')

  const {
    startDate,
    endDate,
    updateDates,
    isOpen: isOpenDatePicker,
    open: openDatePicker,
    close: closeDatePicker,
    isSelected: isSelectedValuePicker,
    hasErrors,
    errors,
    checkAndUpdateErrors,
    shortLabel,
  } = useDateRangePicker(null, null)

  const handleChangePeriod = (newPeriod: string) => {
    setSelectedMonth('')
    updatePeriod && updatePeriod(newPeriod)
    setPeriod(DatePeriod[newPeriod as keyof typeof DatePeriod])
  }

  const handleChangedMonth = (newMonth: string) => {
    setSelectedMonth(newMonth)
    updatePeriod && updatePeriod(newMonth)
    setPeriod('')
  }

  const handleApply = (dates: { start: Date, end: Date }): void => {
    let fullDateEnd
    const endOfPeriod = dates.end ?? dates.start
    if (isToday(endOfPeriod)) {
      fullDateEnd = new Date()
    } else {
      fullDateEnd = endOfDay(endOfPeriod)
    }
    updateDates({ startDate: dates.start, endDate: fullDateEnd })
    updateDateRange && updateDateRange({ startDate: dates.start, endDate: fullDateEnd })
    closeDatePicker()
  }

  const getDatePicker = (): JSX.Element => {
    if (!SHOW_DATE_PICKER) return <></>

    return (
      <>
        <FileFilterButton
          isSelected={!!isSelectedValuePicker}
          startIcon={<CalendarMonth />}
          onClick={openDatePicker}
        >
          {isSelectedValuePicker
            ? shortLabel
            : `${translate('dates-bar:select-date')}`}
        </FileFilterButton>
        <DateRangePicker
          title={`${translate('date-picker:title')}`}
          startDate={startDate}
          endDate={endDate}
          checkAndUpdateErrors={checkAndUpdateErrors}
          open={isOpenDatePicker}
          onClose={closeDatePicker}
          onApply={handleApply}
          errors={errors}
          hasErrors={hasErrors}
        />
      </>
    )
  }

  const getFilterButton = (filterPeriod: string): JSX.Element => {
    return (
      <FileFilterButton
        isSelected={period === filterPeriod && !isSelectedValuePicker}
        onClick={() => handleChangePeriod(filterPeriod)}
      >
        {`${translate(`dates-bar:${filterPeriod}`)}`}
      </FileFilterButton>
    )
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        {getDatePicker()}
        <MonthSelector
          selectedMonth={selectedMonth}
          onChangeMonth={handleChangedMonth}
        />
        {getFilterButton(DatePeriod.YESTERDAY)}
        {getFilterButton(DatePeriod.LAST_7_DAYS)}
        {getFilterButton(DatePeriod.LAST_28_DAYS)}
        {getFilterButton(DatePeriod.CURRENT_YEAR)}
      </Stack>
    </>
  )
}
