import { useEffect, useState, useCallback } from 'react'
import { LoyaltyCampaignsModel } from './model/LoyaltyCampaignsModel'
import { fetchLoyaltyCampaigns } from './loyaltyCampaignsService'

type UseLoyaltyCampaignsStateType = {
  data: LoyaltyCampaignsModel[] | undefined,
  loading: boolean,
  error: Error | null,
}

function useLoyaltyCampaigns(
  organizationUUID: string,
): object {
  const [state, setState] = useState<UseLoyaltyCampaignsStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchLoyaltyCampaigns(organizationUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useLoyaltyCampaigns
