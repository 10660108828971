const AnalyticsKPIsStyle = {
  percentCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 2,
    py: 3,
  },
  gridContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  variantBlock: {
    display: 'flex',
  },
  variantItem: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  emptyVariant: {
    visibility: 'hidden',
    height: 22,
  },
}

export default AnalyticsKPIsStyle
