import Chart from 'react-apexcharts'

export default function LinearAndBarChart({
  id,
  series,
  categories,
  height = 350,
}: {
  id: string,
  series: any,
  categories: string[],
  height?: number,
}) {
  const options = {
    chart: {
      id: id,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      categories: categories || [],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      fontSize: 16,
      itemMargin: {
        horizontal: 12,
      },
    },
    stroke: {
      width: [1, 3],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '10px',
        borderRadius: 2,
        borderRadiusApplication: 'end',
      }
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    markers: {
      size: 0
    },
    colors: ['#3aaa52', '#48badb'],
  }

  return (
    <>
      {/* @ts-ignore */}
      <Chart type="line" options={options} series={series} height={height} />
    </>
  )
}
