import {
  Tab,
  Tabs,
} from '@mui/material'
import { useLocales } from '../../../locales'
import Label from '../../../components/label'
import { CampaignsDataModel } from '../../../services/campaigns/model/CampaignsModel'
import style from './CampaignsStyle'
import campaignsStatusEnum from '../../../services/campaigns/enum/campaignsStatusEnum'

export default function CampaignsTableFilters({
  campaignsData,
  filterStatus,
  handleChange,
} : {
  campaignsData: CampaignsDataModel[],
  filterStatus: string,
  handleChange: (_: any, newValue: string) => void,
}) {
  const { translate } = useLocales()

  const countPausedCampaigns = () =>
    campaignsData?.filter(item => item.paused).length

  const getLengthByStatus = (status: string) =>
    campaignsData?.filter(item => item.status === status && !item.paused).length

  const TABS = [
    {
      value: campaignsStatusEnum.ALL,
      label: `${translate('tabs:all')}`,
      color: 'dark',
      variant: 'filled',
      count: campaignsData?.length,
    },
    {
      value: campaignsStatusEnum.LIVE,
      label: `${translate('campaigns:status:live')}`,
      color: 'success',
      count: getLengthByStatus(campaignsStatusEnum.LIVE),
    },
    {
      value: campaignsStatusEnum.PAUSED,
      label: `${translate('campaigns:status:paused')}`,
      color: 'error',
      count: countPausedCampaigns(),
    },
    {
      value: campaignsStatusEnum.PROGRAMMED,
      label: `${translate('campaigns:status:programmed')}`,
      color: 'warning',
      count: getLengthByStatus(campaignsStatusEnum.PROGRAMMED),
    },
    {
      value: campaignsStatusEnum.COMPLETED,
      label: `${translate('campaigns:status:completed')}`,
      color: 'default',
      count: getLengthByStatus(campaignsStatusEnum.COMPLETED),
    },
  ]

  return (
    <Tabs
      value={filterStatus}
      onChange={handleChange}
      sx={style.tabContainer}
    >
      {TABS.map(tab => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          icon={
            // @ts-ignore
            <Label
              color={tab.color}
              variant={tab.variant}
              sx={style.tabElement}
            >
              {tab.count}
            </Label>
          }
          iconPosition="end"
        />
      ))}
    </Tabs>
  )
}
