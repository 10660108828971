import { useLocales } from '../../../locales'
import {
  CircularProgress,
  Typography,
} from '@mui/material'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import Logo from '../../../components/logo'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { Box } from '@mui/system'

export default function UnsubscribeForm({
  unsubscribeName,
  logo,
  loading,
  error,
  isSuccess,
  isButtonDisabled,
  submit,
  clearError,
}: {
  unsubscribeName: string
  logo?: string | null
  loading: boolean
  error: string | undefined
  isSuccess: boolean
  isButtonDisabled: boolean
  submit: (values: object) => void
  clearError?: () => void
}) {
  const { translate } = useLocales()

  const getLogo = (): JSX.Element => {
    if (logo) {
      return <Box component={'img'} src={logo} />
    }
    return <Logo />
  }

  const getTitle = () => {
    return isSuccess
      ? `${translate('unsubscribe:success:title')}`
      : `${translate('unsubscribe:title')} ${unsubscribeName}`
  }

  const getText = () => {
    return isSuccess
      ? `${translate('unsubscribe:success:text')}`
      : `${translate('unsubscribe:text')}`
  }

  const getButton = () => {
    if (isSuccess) {
      return <></>
    }

    return (
      <PrimaryButton fullWidth isDisabled={isButtonDisabled} onClick={submit}>
        {`${translate('unsubscribe:button')}`}
      </PrimaryButton>
    )
  }

  const getContent = (): JSX.Element => {
    if (loading) {
      return <CircularProgress />
    }

    return (
      <>
        <Typography variant="h3">{getTitle()}</Typography>
        <Typography variant="body2" align="center">
          {getText()}
        </Typography>
        {getButton()}
      </>
    )
  }

  return (
    <>
      {getLogo()}
      {getContent()}
      <SnackbarAlert
        isOpen={!!error}
        message={error || ''}
        onClose={clearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </>
  )
}
