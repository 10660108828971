import { TableRow, TableCell } from '@mui/material'
import { fDateTime } from '../../../../utils/formatTime'
import Label from '../../../../components/label'
import ActionPopover from '../../users/table/ActionsPopover'
import { useLocales } from '../../../../locales'
import calculateCampaignStatus from '../../../../utils/calculateCampaignStatus'
import calculateCampaignStatusColor from '../../../../utils/calculateCampaignStatusColor'
import campaignsStatusEnum from '../../../../services/campaigns/enum/campaignsStatusEnum'

export default function LoyaltyCampaignsTableRow({
  row,
  selected,
  edit,
  goToView,
}: {
  row: {name: string, start: string, end: string, description: string, sendMessagesCount: number},
  selected: boolean,
  edit: () => void,
  goToView: () => void
}) {
  const { translate } = useLocales()
  const { name, start, end, description, sendMessagesCount } = row
  const status = calculateCampaignStatus(row)

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{name}</TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {description}
        </TableCell>
        <TableCell align="left">{fDateTime(start, 'dd/MM/yyyy p')}</TableCell>
        <TableCell align="left">{fDateTime(end, 'dd/MM/yyyy p')}</TableCell>
        <TableCell align="left">{sendMessagesCount}</TableCell>
        <TableCell align="left">
          {/* @ts-ignore */}
          <Label
            variant="soft"
            color={calculateCampaignStatusColor(status)!.name}
          >
            {`${translate(`campaign:status:${status}`)}`}
          </Label>
        </TableCell>
        <TableCell align="right">
          <ActionPopover
            handleEdit={status !== campaignsStatusEnum.COMPLETED ? edit : undefined}
            goToView={status === campaignsStatusEnum.COMPLETED ? goToView : undefined}
          />
        </TableCell>
      </TableRow>
    </>
  )
}
