import axios from '../../utils/axios'
import {
  ClientDoesNotExist,
  ForbiddenOrganization,
  HTTPFailureStatusCodes,
} from '../Errors'
import { adaptLoyaltyCampaignsResponse } from './loyaltyCampaignsAdapter'

export async function fetchLoyaltyCampaigns(organizationId: string) {
  try {
    // TODO - Change this when api works
    const endpoint = `/api/v1/organizations/${organizationId}/campaigns`
    const response = await axios.get(endpoint)

    return adaptLoyaltyCampaignsResponse(response.data)
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}
