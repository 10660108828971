import i18n from '../../../locales/i18n'

const LOYALTY_CAMPAIGNS_TABLE_HEADER = [
  { id: 'name', label: i18n.t('campaigns:table:name'), align: 'left' },
  {
    id: 'description',
    label: i18n.t('campaigns:table:description'),
    align: 'left',
  },
  {
    id: 'start',
    label: i18n.t('campaigns:table:start-date'),
    align: 'left',
  },
  { id: 'end', label: i18n.t('campaigns:table:end-date'), align: 'left' },
  {
    id: 'sendMessagesCount',
    label: i18n.t('campaigns:loyalty:send:messages:count'),
    align: 'left',
  },
  { id: 'status', label: i18n.t('campaigns:table:status'), align: 'left' },
  { id: 'actions', label: '', align: 'right' },
]

export default LOYALTY_CAMPAIGNS_TABLE_HEADER
