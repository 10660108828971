import { Grid, Typography } from '@mui/material'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TagGroupForm from '../../../sections/dashboard/tagGroups/TagGroupForm/TagGroupForm'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import useTagGroupForm from '../../../hooks/useTagGroupForm'
import useTagFormMutation from '../../../services/tagGroups/useTagGroupMutation'
import { InvalidCreateTagGroupPayloadError } from '../../../services/tagGroups/tagGroupError'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'

export default function NewTagGroup() {
  const { translate } = useLocales()
  const mutation = useTagFormMutation() as any
  const { organizationId } = useParams<string>()
  const form = useTagGroupForm({ successCallback: mutation.create(organizationId!) })
  const title = `${translate('create:tag:group:title')}`

  const getErrorMessage = (): string => {
    if (!mutation.error) {
      return ''
    }

    if (mutation.error instanceof InvalidCreateTagGroupPayloadError) {
      return `${translate('create:tag:group:error:already:exist')}`
    }

    return `${translate('create:tag:group:error:generic')}`
  }

  return (
    <>
      <FluxBreadcrumbs currentPath={title} hasPadding />
      <ContainerBase>
        <Typography variant="h4">
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <TagGroupForm organizationUUID={organizationId!} form={form} submitButtonLabel={`${translate('create:tag:group:button')}`} />
          </Grid>
        </Grid>
        <SnackbarAlert
          isOpen={mutation.error !== null}
          message={getErrorMessage()}
          onClose={() => mutation.clearError()}
          type={SnackBarAlertTypes.ERROR}
        />
      </ContainerBase>
    </>
  )
}
