import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PrimaryButton from '../button/PrimaryButton/PrimaryButton'
import style from './RegisterCampaignFormStyle'
import { useLocales } from '../../locales'
import RegisterCampaignModel from '../../services/campaigns/model/RegisterCampaignModel'
import createEvent from '../../services/common/types/CreateEvent'
import useCountries from '../../hooks/useCountries'
import Spacer from '../Spacer/Spacer'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export default function RegisterCampaignForm({
  form,
  campaignInfo,
  changeField,
  validateField,
  submit,
}: {
  form: any
  campaignInfo: RegisterCampaignModel,
  changeField: (event: any) => void
  validateField: (event: any) => void
  submit: (event: any) => void
}) {
  const { translate } = useLocales()
  const { listPhonePrefixes } = useCountries()
  const phonePrefixes = listPhonePrefixes()

  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false)

  const getSelectItems = (): JSX.Element[] => {
    return campaignInfo.form.genderOptions.map((gender, index) => {
      return (
        <MenuItem value={gender} key={index}>
          {`${translate(gender.toLocaleLowerCase())}`}
        </MenuItem>
      )
    })
  }

  const getPreffixField = (): JSX.Element => {
    return (
      <Grid item xs={12} sm={4}>
        <Autocomplete
          options={phonePrefixes}
          value={form.values.contactPhonePreffix}
          onChange={(_event, value) => {
            changeField(createEvent('contactPhonePreffix', value))
          }}
          onBlur={form.validateFieldValue}
          getOptionLabel={phonePrefix => {
            return phonePrefix
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={`${translate('register:campaign:prefix:label')}`}
              name="contactPhonePreffix"
              value={form.values.contactPhonePreffix}
              error={form.errors.contactPhonePreffix !== ''}
              helperText={form.errors.contactPhonePreffix}
            />
          )}
        />
      </Grid>
    )
  }

  const getPhoneField = (): JSX.Element => {
    return (
      <>
        {!campaignInfo?.form.phone ? (
          <></>
        ) : (
          <Grid item xs={12} sm={8}>
            <TextField
              label={`${translate('register:campaign:phone:label')}`}
              name="phone"
              value={form.phone}
              onChange={changeField}
              onBlur={validateField}
              error={!!form.errors.phone}
              helperText={form.errors.phone}
              fullWidth
            />
          </Grid>
        )}
      </>
    )
  }

  const getEmailField = (): JSX.Element => {
    return (
      <>
        {!campaignInfo?.form.email ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <TextField
              label={`${translate('register:campaign:email:label')}`}
              name="email"
              value={form.values.email}
              onChange={changeField}
              onBlur={validateField}
              error={!!form.errors.email}
              helperText={form.errors.email}
              fullWidth
            />
          </Grid>
        )}
      </>
    )
  }
  const getGenderField = (): JSX.Element => {
    return (
      <>
        {!campaignInfo?.form.gender ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <FormControl fullWidth error={!!form.errors.gender}>
              <InputLabel>{`${translate(
                'register:campaign:gender:label',
              )}`}</InputLabel>
              <Select
                label={`${translate('register:campaign:gender:label')}`}
                name="gender"
                fullWidth
                value={form.values.gender}
                onChange={changeField}
                onBlur={validateField}
              >
                {getSelectItems()}
              </Select>
              {form.errors.gender && (
                <FormHelperText>{form.errors.gender}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
      </>
    )
  }

  const getZipCodeField = (): JSX.Element => {
    return (
      <>
        {!campaignInfo?.form.zipcode ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <TextField
              label={`${translate('register:campaign:zipCode:label')}`}
              name="zipCode"
              value={form.values.zipCode}
              onChange={changeField}
              onBlur={validateField}
              error={!!form.errors.zipCode}
              helperText={form.errors.zipCode}
              fullWidth
            />
          </Grid>
        )}
      </>
    )
  }

  const getBirthDateField = (): JSX.Element => {
    return (
      <>
        {!campaignInfo?.form.birthdate ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <DatePicker
              label={`${translate('register:campaign:birthDate:label')}`}
              value={form.values.birthDate}
              onChange={value => {
                changeField(createEvent('birthDate', value))
                validateField(createEvent('birthDate', value))
              }}
              slotProps={{
                textField: {
                  name: 'birthDate',
                  fullWidth: true,
                  error: !!form.errors.birthDate,
                  helperText: form.errors.birthDate,
                },
              }}
            />
          </Grid>
        )}
      </>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={1}>
        {getPreffixField()}
        {getPhoneField()}
      </Grid>
      <Spacer size={2} />
      <Grid container spacing={2} sx={style.formContainer}>
        {getEmailField()}
        {getGenderField()}
        {getZipCodeField()}
        {getBirthDateField()}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isTermsAccepted}
                onChange={event => setIsTermsAccepted(event.target.checked)}
              />
            }
            label={
              <Link to={campaignInfo?.form?.legalUrl}>
                {campaignInfo?.form.legalText}
              </Link>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <PrimaryButton
              isDisabled={!isTermsAccepted}
              onClick={submit}
              fullWidth
            >{`${translate(
              'register:campaign:register:button',
            )}`}</PrimaryButton>
          </Stack>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
