const createEvent = (name: string, value: any) => {
  return {
    target: {
      name: name,
      value: value,
    },
  };
};

export default createEvent;
