import { Grid, Typography } from '@mui/material'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TaxonomyForm from '../../../sections/dashboard/taxonomy/TaxonomyForm/TaxonomyForm'
import useTaxonomyForm from '../../../hooks/useTaxonomyForm'
import { useLocales } from '../../../locales'
import useTaxonomyMutation from '../../../services/taxonomies/useTaxonomyMutation'
import { useParams } from 'react-router'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { InvalidCreateTaxonomyPayloadError } from '../../../services/taxonomies/taxonomyError'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'

export default function NewTaxonomy() {
  const { translate } = useLocales()
  const mutation = useTaxonomyMutation() as any
  const { organizationId } = useParams<string>()
  const form = useTaxonomyForm({ successCallback: mutation.create(organizationId!) })
  const title = `${translate('create:taxonomy:title')}`

  const getErrorMessage = (): string => {
    if (!mutation.error) {
      return ''
    }

    if (mutation.error instanceof InvalidCreateTaxonomyPayloadError) {
      return `${translate('create:taxonomy:error:already:exist')}`
    }

    return `${translate('create:taxonomy:error:generic')}`
  }

  return (
    <>
      <FluxBreadcrumbs currentPath={title} hasPadding />
      <ContainerBase>
        <Typography variant="h4">
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <TaxonomyForm form={form} submitButtonLabel={`${translate('create:taxonomy:button')}`} />
          </Grid>
        </Grid>
        <SnackbarAlert
          isOpen={mutation.error !== null}
          message={getErrorMessage()}
          onClose={() => mutation.clearError()}
          type={SnackBarAlertTypes.ERROR}
        />
      </ContainerBase>
    </>
  )
}
