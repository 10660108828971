import Chart from 'react-apexcharts'
import { useChart } from '../chart'

export default function GaugeGraph({
  series,
  maxValue = Math.max(...series.map(serie => serie.value)),
  totalLabel,
  height = 300,
  showLegend = false,
  showTotal = false,
}: {
  series: {
    value: number
    label: string
    color?: string
  }[]
  maxValue?: number
  totalLabel?: string
  height?: number
  showLegend?: boolean,
  showTotal?: boolean
}) {
  const normalizedSeries = series.map(serie => ({
    ...serie,
    value: (serie.value / maxValue) * 100,
  }))
  const chartOptions = useChart({
    legend: {
      show: showLegend,
    },
    labels: series.map(serie => serie.label),
    colors: series.map(serie => serie.color),
    dataLabels: {
      style: {
        colors: series.map(serie => serie.color),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
      },
      pie: {
        donut: {
          labels: {
            value: {
              fontSize: '22px',
              color: 'black',
              formatter: function (value: string) {
                return parseFloat(value).toFixed(2) + '%'
              },
            },
            total: {
              show: showTotal,
              label: totalLabel,
              fontSize: '12px',
              formatter: function (w: any) {
                const total = w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0)
                return parseFloat(total.toFixed(2)) + '%'
              },
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        gradientToColors: series.map(serie => serie.color || null),
        opacityFrom: 0.8,
        opacityTo: 1,
      },
    },
  })

  return (
      <>
        {/* @ts-ignore */}
        <Chart
          type="radialBar"
          height={height}
          options={chartOptions}
          series={normalizedSeries.map(serie => serie.value)}
        />
      </>
  )
}
