import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import useUserForm from '../../hooks/useUserForm'
import { useLocales } from '../../locales'
import useUserRegistration from '../../services/users/useUserRegistration'
import useTagGroups, { TagGroupStateType } from '../../services/tagGroups/useTagGroups'
import { generateRandomPassword } from '../../utils/passwordGenerator'
import UserForm from '../../sections/dashboard/users/userForm/UserForm'
import FormContainer from '../../components/form/FormContainer/FormContainer'
import { PATH_ACCOUNTS } from '../../routes/paths'
import FormRequestActions from '../../components/form/FormRequestActions/FormRequestActions'
import {
  InvalidRoleWithAssignedOrganizationError,
  UserAlreadyExistsError,
} from '../../services/users/Error'
import defaultUserPermissions from '../../services/permissions/default/defaultUserPermissions'

const calculateInitialState = (organizationId: string) => ({
  values: {
    name: '',
    lastname: '',
    email: '',
    password: generateRandomPassword(),
    role: '',
    organizationId: organizationId ?? '',
    permissions: [],
    timezone: '',
    tagGroups: [],
  },
  errors: {
    name: '',
    lastname: '',
    email: '',
    password: '',
    role: '',
    organizationId: '',
    timezone: '',
    tagGroups: '',
  },
})

export default function NewUserPage({ isOrganizationView = false }) {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId } = useParams()

  const { createUser, loading: loadingUserRegistration } = useUserRegistration()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [groups, setGroups] = useState<{id: string, label: string}[] | undefined | null>(null)

  const displayError = (error: Error) => {
    if (error instanceof UserAlreadyExistsError) {
      setErrorMessage(
        `${translate('create:user:existing:email')}`,
      )

      return
    }

    if (error instanceof InvalidRoleWithAssignedOrganizationError) {
      setErrorMessage(
        `${translate('edit:user:invalid:role:with:assigned:organization:error')}`,
      )

      return
    }

    setErrorMessage(`${translate('create:user:error')}`)
  }

  const clearError = () => {
    setErrorMessage(null)
  }

  const goBack = () => {
    if (isOrganizationView) {
      navigate(
        `${PATH_ACCOUNTS.organizationUsers(organizationId)}?updated=true`,
      )

      return
    }

    navigate(`${PATH_ACCOUNTS.generalUsers}?updated=true`)
  }

  const submitSuccess = async (values: any) => {
    clearError()

    try {
      await createUser(values)

      goBack()
    } catch (error) {
      displayError(error)
    }
  }

  const {
    changeField,
    changeRole,
    changeOrganization,
    form,
    submit,
    validateField,
    updatePermission,
    validatePermissions,
    updateGroupsOptions,
    deleteGroupOption,
    setDefaultPermissions,
    validateTagGroups,
    validateRole,
  } = useUserForm(organizationId ?? '', {
    successCallback: submitSuccess,
    initialState: calculateInitialState(organizationId ?? ''),
  })

  const defaultPermissions = defaultUserPermissions(form)

  useEffect(() => {
    setDefaultPermissions(defaultPermissions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tagGroups = useTagGroups(organizationId || form.values.organizationId) as TagGroupStateType

  useEffect(() => {
    setGroups(
      tagGroups?.data?.map(group => {
        return {
          id: group.uuid,
          label: group.name,
        }
      }),
    )
  }, [tagGroups.data, form.values.organizationId])

  return (
    <>
      <FormContainer
        title={`${translate('users:create')}`}
        showError={false}
        errorMessage={''}
        onCloseError={() => {}}
      >
        <UserForm
          errors={form.errors}
          values={form.values}
          tagGroups={groups}
          submit={submit}
          changeField={changeField}
          changeRole={changeRole}
          changeOrganization={changeOrganization}
          updateGroupsOptions={updateGroupsOptions}
          validateField={validateField}
          validatePermissions={validatePermissions}
          submitLabel={`${translate('new-user:form:submit-label')}`}
          updatePermission={updatePermission}
          validateTagGroups={validateTagGroups}
          deleteGroupOption={deleteGroupOption}
          validateRole={validateRole}
          isOrganizationView={isOrganizationView}
          isEditView={false}
        />
      </FormContainer>
      <FormRequestActions
        isLoading={loadingUserRegistration.loading}
        errorMessage={errorMessage}
        closeRequestError={clearError}
      />
    </>
  )
}
