import { Stack, Typography } from '@mui/material'
import PrimaryButton from '../../button/PrimaryButton/PrimaryButton'
import { useLocales } from '../../../locales'
import { useNavigate, useParams } from 'react-router'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import style from './UnauthorizedGroupStyle'

export default function UnauthorizedGroup() {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId } = useParams()


  const navigateToTagGroups = (): void => {
    navigate(
      PATH_ACCOUNTS.tagGroups(
        organizationId,
      )
    )
  }

  return (
    <Stack sx={style.container}>
      <Typography variant="h4" align="center">
        {`${translate('analytics:user:unauthorized:error:message')}`}
      </Typography>
      <PrimaryButton onClick={navigateToTagGroups}>
        {`${translate('analytics:user:unauthorized:error:button')}`}
      </PrimaryButton>
    </Stack>
  )
}
