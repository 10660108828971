import { SelectItemType } from "../services/common/types/SelectItemType"

const getLocationsUUIDsParams = (locations: SelectItemType[]): string => {
  return locations.map(location => `&locationUUID=${location.uuid}`).join('')
}

export const getFilteredEndpoint = (
  url: string,
  locations: SelectItemType[],
  period: string,
): string => {
  return (
    url +
    `?period=${period}` +
    getLocationsUUIDsParams(locations)
  )
}
