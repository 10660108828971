import axios from '../../utils/axios'
import {
  ClientDoesNotExist,
  ForbiddenOrganization,
  HTTPFailureStatusCodes,
} from '../Errors'
import {
  CustomerAlreadyUnsubscribedError,
  CustomerNotFound,
} from './Error'
import { adaptPublicOrganizationResponse } from './adapters'

import {
  InvalidNewOrganizationPayloadError,
  InvalidUpdateOrganizationPayloadError,
} from './Error'
import { PublicOrganizationApiInterface } from './interfaces/PublicOrganizationApiInterface'
import OrganizationPayloadModel from './model/OrganizationPayloadModel'
import PublicOrganizationModel from './model/PublicOrganizationModel'

const CUSTOMER_ALREADY_UNSUBSCRIBED_ERROR = 'CustomerAlreadyUnsubscribedError'
const CUSTOMER_NOT_FOUND = 'CustomerNotFound'

export async function fetchOrganizations() {
  try {
    const response = await axios.get('/api/v1/organizations')
    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function createOrganization(payload: OrganizationPayloadModel) {
  const endpoint = '/api/v1/organizations'

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.status === 400) {
      throw new InvalidNewOrganizationPayloadError(endpoint)
    }

    throw new Error('Unknown error')
  }
}

export async function updateOrganization(id: string, payload: OrganizationPayloadModel) {
  const endpoint = `/api/v1/organizations/${id}`

  try {
    await axios.put(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.status === 400) {
      throw new InvalidUpdateOrganizationPayloadError(endpoint)
    }

    throw new Error('Unknown error')
  }
}

export async function fetchOrganization(organizationId: string) {
  try {
    const response = await axios.get(`/api/v1/organizations/${organizationId}`)

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function removeOrganization(organizationId: string) {
  try {
    const response = await axios.delete(
      `/api/v1/organizations/${organizationId}`,
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function untrack(organizationUuid: string, customerUuid: string): Promise<void> {
  const url = `/api/v1/organizations/${organizationUuid}/customers/${customerUuid}/untrack`

  try {
    await axios.put(url)
  } catch (error) {
    if (error.response.data.name === CUSTOMER_ALREADY_UNSUBSCRIBED_ERROR) {
      throw new CustomerAlreadyUnsubscribedError(url)
    }

    if (error.response.data.name === CUSTOMER_NOT_FOUND) {
      throw new CustomerNotFound(url)
    }

    throw new Error(error)
  }
}

export async function fetchPublicOrganization(organizationId: string): Promise<PublicOrganizationModel> {
  const url = `/api/v1/organizations/${organizationId}`

  try {
    const response = await axios.get<PublicOrganizationApiInterface>(url)

    return adaptPublicOrganizationResponse(response.data)
  } catch (error) {
    throw new Error(error)
  }
}
