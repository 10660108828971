import { useNavigate, useParams } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { useLocales } from '../../../locales'
import CardGridContainer from '../../../components/grid/CardGridContainer/CardGridContainer'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import ViewHeader from '../../../components/ViewHeader/ViewHeader'
import GridContainer from '../../../components/grid/GridContainer/GridContainer'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import useRemoveStatus from '../../../hooks/useRemoveStatus'
import useSnackbarMessageByQueryParams from '../../../hooks/useSnackbarMessageByQueryParams'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import CardSkeleton from '../../../components/skeleton/CardSkeleton/CardSkeleton'
import DashboardError from '../../../components/dashboard-error/DashboardError'
import ModalViewActions from '../../../components/ViewInfoActions/ViewInfoActions'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import useTagGroups from '../../../services/tagGroups/useTagGroups'
import TagGroupModel from '../../../services/tagGroups/model/TagGroupModel'
import TagCard from '../../../components/card/TagCard/TagCard'
import userDashboardRedirect from '../../../utils/userDashboardRedirect'
import { useAuthContext } from '../../../auth/useAuthContext'
import { ROLES } from '../../../utils/rolesAndPermissions'
import FluxBreadcrumbs from '../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs'

export default function TagGroups() {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const removeStatus = useRemoveStatus()
  const { data, error, loading, remove, refetch } = useTagGroups(
    organizationId || '',
  ) as any
  const { user } = useAuthContext()
  const title = `${translate('view:tag:group:title')}`

  const [snackbarMessage, closeSnackbarMessage] =
    useSnackbarMessageByQueryParams({
      created: 'tag:group:created',
      updated: 'tag:group:updated',
    }) as any

  const navigateToNewTagGroupView = () => {
    navigate(PATH_ACCOUNTS.newTagGroup(organizationId))
  }

  const navigateToEditTagGroupView = (tagGroupId: string) => {
    navigate(PATH_ACCOUNTS.editTagGroup(organizationId, tagGroupId))
  }

  const getRequestMessage = (): string | null => {
    if (loading) {
      return `${translate('loading')}`
    }

    return null
  }

  async function handleDeleteTagGroup() {
    try {
      await remove(organizationId, removeStatus.id)
      await refetch()
      removeStatus.activateFeedback(
        SnackBarAlertTypes.SUCCESS,
        translate('tag:group:remove:success'),
      )
    } catch (error) {
      removeStatus.activateFeedback(
        SnackBarAlertTypes.ERROR,
        translate('tag:group:remove:error'),
      )
    }
  }

  const navigateToAnonymous = (groupUUID: string): void => {
    navigate(userDashboardRedirect(user, organizationId as string, groupUUID))
  }

  const getTagGroups = (): JSX.Element => {
    if (loading) {
      return <CardSkeleton />
    }

    if (!data || data.length === 0) {
      return (
        <NoItemsCard
          ctaLabel={`${translate('view:tag:group:add:button')}`}
          handleClick={navigateToNewTagGroupView}
          title={`${translate('tag:group:empty-content:title')}`}
          description={`${translate('tag:group:empty-content:description')}`}
        />
      )
    }

    return (
      <CardGridContainer>
        {data.map((tagGroup: TagGroupModel, index: number) => (
          <TagCard
            key={`${index}-${tagGroup.name}`}
            name={tagGroup.name}
            tags={tagGroup.taxonomyTags}
            onClickEdit={() => navigateToEditTagGroupView(tagGroup.uuid)}
            onClickDelete={() =>
              removeStatus.activateConfirmation(tagGroup.uuid)
            }
            onClickCard={() => navigateToAnonymous(tagGroup.uuid)}
          />
        ))}
      </CardGridContainer>
    )
  }

  if (error) {
    return <DashboardError title={title} />
  }

  return (
    <>
      <Helmet>
        <title>{title} | So69</title>
      </Helmet>
      <ViewContainer>
        <FluxBreadcrumbs currentPath={title} />
        <GridContainer>
          <ViewHeader
            title={title}
            isButtonShown={
              user?.role === ROLES.SUPER_ADMIN ||
              user?.role === ROLES.ORGANIZATION_ADMIN
            }
            buttonLabel={`${translate('view:tag:group:add:button')}`}
            onButtonClick={navigateToNewTagGroupView}
          />
          {getTagGroups()}
        </GridContainer>
      </ViewContainer>
      <ModalViewActions
        confirmTitle={`${translate('tag:group:remove:confirm:title')}`}
        removeStatus={removeStatus}
        snackbarMessage={snackbarMessage}
        handleConfirm={handleDeleteTagGroup}
        closeSnackbarMessage={closeSnackbarMessage}
        requestMessage={getRequestMessage()}
      />
    </>
  )
}
