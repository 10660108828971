import { useState, useCallback } from 'react';
import { campaignRegister } from './campaignsService';
import RegisterCampaignFields from './enum/registerCampaignFields';

type UseRegisterCampaignStateType = {
  success: boolean;
  loading: boolean;
  error: Error | null;
};

export const useRegisterCampaignMutation = () => {
  const [state, setState] = useState<UseRegisterCampaignStateType>({
    success: false,
    loading: false,
    error: null,
  });

const register = useCallback(async (
    campaignId: string,
    organizationId: string,
    foreignId: string,
    fields: RegisterCampaignFields) => {
    setState({ success: false, loading: true, error: null });

    try {
      await campaignRegister(campaignId, organizationId, foreignId, fields);

      setState({
        success: true,
        loading: false,
        error: null,
      });

    } catch (error: any) {
      setState({
        success: false,
        loading: false,
        error,
      });
    }
  }, []);

  const clearError = (): void => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    register,
    clearError,
  };
};
