import { Box, MenuItem, TextField } from '@mui/material'
import { useLocales } from '../../../locales'
import { getMonthsYearFromCurrentMonth } from '../../../utils/periods'
import style from './MonthSelectorStyle'

export default function MonthSelector({
  selectedMonth,
  onChangeMonth
} : {
  selectedMonth: string,
  onChangeMonth: (month: string) => void,
}) {
  const { translate } = useLocales()
  const months = getMonthsYearFromCurrentMonth()

  const getItems = (): JSX.Element[] => {
    return months.map((month, index) => {
      return (
        <MenuItem
          value={month.label}
          key={index}
          onClick={() => onChangeMonth(month.label)}
        >
          {`${translate(`date:calendar:${month.name}`)} ${month.year}`}
        </MenuItem>
      )
    })
  }

  return (
    <Box sx={style.container}>
      <TextField
        fullWidth
        select
        value={selectedMonth}
        label={`${translate('dates-bar:month')}`}
        name="month-selector"
        size="small"
      >
        {getItems()}
      </TextField>
    </Box>
  )
}
