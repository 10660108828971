import { ZonesInterface, ZonesThreePointsInterface } from "../../common/model/AnalyticsZonesInterface";
import { RegisteredKPIsInterface } from "../interfaces/RegisteredKPIsInterface";

export default class RegisteredKPIsModel implements RegisteredKPIsInterface {
  constructor(
    readonly unique_detections_sum: ZonesInterface,
    readonly detections_sum: ZonesInterface,
    readonly detections_average: ZonesInterface,
    readonly average_time_stay: ZonesInterface,
    readonly new_vs_loyal: ZonesThreePointsInterface,
    readonly average_time_comeback: ZonesInterface,
  ) {}
}
