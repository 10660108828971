import FluxBreadcrumbs from "../../../components/breadcrumb/FluxBreadcrumbs/FluxBreadcrumbs";
import { useLocales } from "../../../locales";
import Users from "./Users";

export default function OrganizationUsers() {
  const { translate } = useLocales()

  return (
    <>
      <FluxBreadcrumbs currentPath={`${translate('users')}`} hasPadding />
      <Users />
    </>
  )
}
